import { Button, Grid, Input, Stack } from "@mui/material";
import { Avatar } from "antd";
import { useState } from "react";

const ChangeClientPic = (props) => {
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  //   const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileInputChange = (event) => {
    const input = event.target.files[0];
    console.log("File uploaded: ", input);
    props.setSelectedFile(input);
    setSelectedFileURL(URL.createObjectURL(input));

    if (input && input[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("previewAva")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(input[0]);
    }
  };

  //   const readURL = (input) => {
  //     setSelectedFile(input[0]);

  //     if (input && input[0]) {
  //       let reader = new FileReader();
  //       reader.onload = function (e) {
  //         document
  //           .getElementById("previewAva")
  //           .setAttribute("src", e.target.result);
  //       };
  //       reader.readAsDataURL(input[0]);
  //     }
  //   };

  return (
    <Grid container spacing={1} marginBottom={"2rem"}>
      <Grid item xs={12}>
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Avatar
            size={145}
            id="previewAva"
            src={
              selectedFileURL === null
                ? props.client.profile_pic.includes("avatar_deafult.png")
                  ? ""
                  : props.client.profile_pic
                : selectedFileURL
            }
            alt="Profile Pic"
          >
            {props.client.profile_pic.includes("avatar_default.png")
              ? props.client.first_name[0]
              : ""}
          </Avatar>
          <Button
            size="small"
            type="button"
            variant="contained"
            color="primary"
            onClick={handleUploadButtonClick}
          >
            Upload
          </Button>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        </Stack>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => props.updateImageOnSubmit(props.selectedFile)}
        >
          Update Profile Picture
        </Button> */}
        {/* <Input
          style={{ display: "none" }}
          id="myfile"
          type="file"
          name="myfile"
          accept="image/gif, image/jpeg, image/jpg, image/png"
          onChange={(e) => readURL(e.target.files)}
        /> */}
      </Grid>
    </Grid>
  );
};

export default ChangeClientPic;
