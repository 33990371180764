import { useUserState } from "../../../Context/UserContext";

import moment from "moment";
import { Link } from "react-router-dom";
import "./chat.css";
import { Avatar, Tooltip, Badge } from "antd";
import { useMediaQuery } from "@mui/material";

const ChatListing = (props) => {
  const { user } = useUserState();
  
  
  /** @type {{conversation_id:number, started_by_id:number, create_date:string, active:1|0, recent_msg_user_id:number, message:string, time:string,is_read:1|0, msg_id:number, 
   * chatter_name:string, chatter_id:number, chatter_profile_pic:string, chatter_type:"Client"|"Vendor", chatter_email:string, unread_msg_count: number}} 
   */
  const chat = props.chat;

  const truncateText = (text) => {
    let shortText = text.substring(0, 20);
    if (text.length > 20) {
      shortText = shortText + ".....";
    }
    return shortText;
  };
  const getTime = (time) => {
    let dateTime = moment(new Date(time));
    let textTime = dateTime.format("h:mma");
    let textDate = dateTime.format("MMM D");
    let textDay = dateTime.format("ddd");
    let textYearWithDate = dateTime.format("MMM D, YYYY");
    let hours = moment().diff(dateTime, "hours");
    if (hours < 24) return textTime;
    let days = moment().diff(dateTime, "days");
    if (days < 7) return textDay;
    else if (days >= 7 && days < 365) return textDate;
    else return textYearWithDate;
  };

  /*
  let recentUserIndex = chat.chattingPeople.findIndex(
    (chatPerson) => {
      return chatPerson.user_id === chat.recent_msg_user_id;
    }
  );
  */

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <li
      className="m-auto"
      onClick={() => {
        props.setChatID(chat.conversation_id);
        mobileView && props.setChatting(true);
      }}
    >
      <Link
        className="threadListing activethread"
        to={`/dashboard/chat/${chat.conversation_id}`}
      >
        <div className="threadLeft">
          <Avatar.Group
            size="small"
            maxCount={1}
            maxStyle={{ backgroundColor: "#4d7cfe", color: "#ffffff" }}
          >
            <Tooltip title={chat.chatter_name} key={chat.chatter_id}>
              <Badge
                offset={["-5%", "85%"]}
                style={{
                  width: "10px",
                  height: "10px",
                  boxShadow: "0 0 0 2px #fff",
                  backgroundColor: "rgb(0, 219, 0)"
                }}
                dot={props.onlineUsers?.some(
                  (user) => user.userId === chat.chatter_id
                )}
              >
                <Avatar
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  key={chat.chat_id}
                  src={chat.chatter_profile_pic}
                />
              </Badge>
            </Tooltip>
            {/*chat.chattingPeople
              .filter((person) => person.user_id !== user.id)
              .map((person) => (
                <Tooltip title={person.name} key={person.user_id}>
                  <Badge
                    offset={["-5%", "85%"]}
                    style={{
                      width: "10px",
                      height: "10px",
                      boxShadow: "0 0 0 2px #fff",
                      backgroundColor: "rgb(0, 219, 0)"
                    }}
                    dot={props.onlineUsers?.some(
                      (user) => user.userId === person.user_id
                    )}
                  >
                    <Avatar
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                      key={person.user_id}
                      src={person.imageurl}
                    />
                  </Badge>
                </Tooltip>
              ))*/}
          </Avatar.Group>
        </div>
        <div className="threadMain">
          <div className="caption threadName">
            {chat.chatter_name}
          </div>
          <div
            className={
              chat.is_read === 0 &&
              chat.recent_msg_user_id !== user.id
                ? "unreadTxt txtMsg b-text-sm"
                : "g-text-sm txtMsg"
            }
          >
            {chat.message
              ? `${
                  chat.chatter_id >= 0
                    ? chat.recent_msg_user_id === user.id
                      ? "You"
                      : chat.chatter_name.split(" ")[0]
                    : "Missing User"
                }: ${truncateText(chat.message)}`
              : "No messages."}
          </div>
        </div>
        <div>
          <Badge
            count={
              chat.unread_msg_count > 0
                ? chat.unread_msg_count
                : ""
            }
            style={{ backgroundColor: "#6384EB" }}
          />
        </div>

        <div className="threadRight g-text-sm">
          {getTime(
            chat.time
              ? chat.time
              : chat.create_date
          )}
        </div>
      </Link>
    </li>
  );
};

export default ChatListing;
