import axios from "axios";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  Select,
  InputLabel,
  OutlinedInput,
  Stack,
  MenuItem,
  Typography,
  FormHelperText,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GetProvinceList from "../../../Authentication/auth-forms/GetProvinceList";
import GetPropertyType from "../../../commonComponents/API/GetPropertyType";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

const AddClientServiceAddress = (props) => {
  const [totalUnits, setTotalUnits] = useState();

  const [notFound, setNotFound] = useState(false);

  const onSubmit = (values) => {
    const { streetAddress, city, postalCode, province, propertyType } = values;
    const formObject = {
      clientID: props.clientId,
      unitNumber: null,
      street: streetAddress,
      city: city,
      postalCode: postalCode,
      totalUnits: totalUnits,
      province: province,
      propertyType: propertyType,
    };

    axios.post("/property/addProperty", formObject).then((res) => {
      if (res.data.ok) {
        //close the window after submit
        props.onClose(false);
        props.fetchData();
        props.updateProperties();
      } else {
        setNotFound(true);
      }
    });

    props.fetchData();
  };

  return (
    <>
      <Formik
        initialValues={{
          streetAddress: "",
          city: "",
          province: "",
          postalCode: "",
          propertyType: "",
        }}
        validationSchema={Yup.object().shape({
          streetAddress: Yup.string()
            .max(255)
            .required("Street Address is missing"),
          city: Yup.string().max(255).required("City is missing"),
          province: Yup.string().max(255).required("Province is missing"),
          //   postalCode: Yup.string().max(255).required("Postal code is required"),
          //   propertyType: Yup.string()
          //     .max(255)
          //     .required("Property type is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values,
          setValues,
        }) => (
          <form
            style={{ display: "flex", flexDirection: "column" }}
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid item xs={12}>
              <GooglePlacesAutocomplete
                className={"css-1hb7zxy-IndicatorsContainer"}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
                autocompletionRequest={{
                  componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                  types: ["address"],
                }}
                selectProps={{
                  name: "streetAddress",
                  value: values.streetAddress,
                  onChange: async (selectedOption) => {
                    console.log(selectedOption);

                    const placeId = selectedOption.value.place_id;
                    const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                    const response = await axios.get(
                      `/jobRequest/place-details`,
                      {
                        params: { placeId, key },
                      }
                    );
                    console.log(response.data.result);

                    const addressComponents =
                      response.data.result.address_components;
                    const streetNumber =
                      addressComponents.find((component) =>
                        component.types.includes("street_number")
                      )?.long_name || "";
                    const route =
                      addressComponents.find((component) =>
                        component.types.includes("route")
                      )?.long_name || "";
                    const city =
                      addressComponents.find((component) =>
                        component.types.includes("locality")
                      )?.long_name || "";
                    const province =
                      addressComponents.find((component) =>
                        component.types.includes("administrative_area_level_1")
                      )?.short_name || "";

                    const postalCode =
                      response.data.result.address_components.find(
                        (component) => component.types.includes("postal_code")
                      )?.long_name || "";

                    // Combine street number and route for the full street address
                    const street = `${streetNumber} ${route}`.trim();

                    // Update city and province fields in Formik values
                    setValues({
                      ...values,
                      streetAddress: street,
                      city: city,
                      province: province,
                      postalCode: postalCode,
                    });
                  },
                  placeholder: "Find address here",
                }}
              />
              <style>
                {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
              </style>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  {console.log(values.streetAddress)}
                  <InputLabel>Street Address</InputLabel>
                  <OutlinedInput
                    id="streetAddress-signup"
                    type="streetAddress"
                    value={values.streetAddress}
                    name="streetAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Street Address"
                  />

                  {touched.streetAddress && errors.streetAddress && (
                    <FormHelperText error id="helper-text-streetAddress-signup">
                      {errors.streetAddress}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel>City</InputLabel>
                  {console.log(values.city)}
                  <OutlinedInput
                    id="city-signup"
                    type="city"
                    value={values.city}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="city"
                  />
                  {touched.city && errors.city && (
                    <FormHelperText error id="helper-text-city-signup">
                      {errors.city}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel>Postal Code</InputLabel>
                  <OutlinedInput
                    id="postalCode-signup"
                    type="postalCode"
                    value={values.postalCode}
                    name="postalCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Postal Code"
                  />
                  {touched.postalCode && errors.postalCode && (
                    <FormHelperText error id="helper-text-postalCode-signup">
                      {errors.postalCode}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel>Province</InputLabel>
                  <Select
                    id="province-signup"
                    value={values.province}
                    name="province"
                    defaultValue={"Select Province"}
                    onChange={handleChange}
                  >
                    {GetProvinceList().map((provinces) => (
                      <MenuItem
                        value={provinces.province_shortform}
                        key={provinces.province_shortform}
                      >
                        {provinces.province_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.province && errors.province && (
                    <FormHelperText error id="helper-text-firstname-signup">
                      {errors.province}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel>Property Type</InputLabel>
                  <Select
                    required
                    id="propertyType"
                    name="propertyType"
                    value={values.propertyType}
                    defaultValue={"Select Property Type"}
                    onChange={handleChange}
                  >
                    {GetPropertyType().map((type) => (
                      <MenuItem value={type.type_name} key={type.type_name}>
                        {type.type_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
              {values.propertyType === "House With Apartment" ||
              values.propertyType === "Multi-unit Residence" ? (
                <Grid item xs={12} md={2}>
                  <Stack spacing={1}>
                    <InputLabel>Total Units</InputLabel>
                    <OutlinedInput
                      required
                      id="totalUnits"
                      type="totalUnits"
                      name="totalUnits"
                      onChange={(e) => setTotalUnits(e.target.value)}
                      placeholder="Total Units"
                    />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                {notFound && (
                  <Typography variant="h6" color="red" fontStyle="italic">
                    property not found please check spelling
                  </Typography>
                )}
                <AnimateButton>
                  <Button
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Submit
                  </Button>
                  <Button
                    size="sm"
                    variant="outlined"
                    color="secondary"
                    onClick={props.onClose}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddClientServiceAddress;
