import Authenticator from "../Authenticator/Authenticator";
import CalendarOrTaskManager from "../Pages/CalendarOrTaskManager/CalendarOrTaskManager";
import UserProfile from "../Pages/DashboardPages/UserProfile";
import FinancePage from "../Pages/FinancePage";
import InboxPage from "../Pages/InboxPage/InboxPage";
import MarketPage from "../Pages/MarketPage/MarketPage";
import TermsViewer from "../Pages/PDF/TermsViewer";
import PrivacyViewer from "../Pages/PDF/PrivacyViewer";
import AgreementViewer from "../Pages/PDF/AgreementViewer";
import ClientJR from "../Pages/commonComponents/JRComponents/ClientPhases/ClientJR";
import NewPaymentMethod from "../Pages/NewPaymentMethod";
import Checkout from "../Pages/Payment/Checkout";

import ChangePassword from "../Pages/EditForms/ChangePassword";
import Intro from "../Pages/MarketPage/MarketComponents/GoogleMapDisplay";

const MainRoutes = {
  path: "/dashboard",
  element: <Authenticator />,
  children: [
    {
      path: "profile",
      element: <UserProfile />,
    },
    {
      path: "googleMapsTesting",
      element: <Intro />,
    },
    {
      path: "employeejobrequest",
      element: (
        <MarketPage
          isMarket={false}
          clientListDisplay={false}
          teamListDisplay={false}
        />
      ),
    },
    {
      path: "vendorjobrequest",
      element: <MarketPage isMarket={true} />,
    },
    {
      path: "clientjobrequest",
      element: <ClientJR />,
    },
    {
      path: "eventcalendar",
      element: <CalendarOrTaskManager />,
    },
    {
      path: "vendorMode/events",
      element: <CalendarOrTaskManager />,
    },
    {
      path: "vendorMode/bidding",
      element: (
        <MarketPage
          isMarket={true}
          clientListDisplay={false}
          teamListDisplay={false}
        />
      ),
    },
    {
      path: "vendorMode/bidding/activeJobs",
      element: (
        <MarketPage
          isMarket={false}
          clientListDisplay={false}
          teamListDisplay={false}
        />
      ),
    },
    {
      path: "vendorMode/bidding/clientList",
      element: (
        <MarketPage
          isMarket={false}
          clientListDisplay={true}
          teamListDisplay={false}
        />
      ),
    },
    {
      path: "vendorMode/bidding/teamList",
      element: (
        <MarketPage
          isMarket={false}
          clientListDisplay={false}
          teamListDisplay={true}
        />
      ),
    },
    {
      path: "finance",
      element: <FinancePage />,
    },
    {
      path: "vendorMode/finance",
      element: <FinancePage />,
    },
    {
      path: "chat",
      element: <InboxPage />,
    },
    // {
    //   path: "vendorMode/chat",

    //   element: <InboxPage />
    // },
    // {
    //   path: "vendorMode/employee/chat",

    //   element: <InboxPage />
    // },
    {
      path: "chat/:id",
      element: <InboxPage />,
    },
    {
      path: "chat/:id/:jobId",
      element: <InboxPage />,
    },
    // {
    //   path: "vendorMode/chat/:id",

    //   element: <InboxPage />
    // },

    { path: "TermsAndCondition2022", element: <TermsViewer /> },

    { path: "PrivacyPolicy2022", element: <PrivacyViewer /> },
    { path: "ServiceAgreement2022", element: <AgreementViewer /> },
    { path: "newCard", element: <NewPaymentMethod /> },
    { path: "payment/checkout", element: <Checkout /> },
    { path: "changePassword", element: <ChangePassword /> },
  ],
};

export default MainRoutes;
