import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Header from "./Header";
import navigation from "../menu-items";
import Breadcrumbs from "../components/@extended/Breadcrumbs";
import { message } from "antd";
import Cookies from "js-cookie";
import JobRequest from "../../Pages/commonComponents/JRComponents/JobRequest/JobRequest";
import { ChatContext, ChatContextProvider } from "../../Context/ChatContext";

const refreshEvents = [
  "touchstart",
  "mousedown",
  "pointermove",
  "pointerdown",
  "pointerup",
  "keydown",
];

let sessionWarnCounter = null;
let sessionExpiryCounter = null;
let sessionExpiryMessage = null;

const MainLayout = () => {
  let navigate = useNavigate();

  const [displayRequestForm, setDisplayRequestForm] = useState(false);

  const refreshSessionCounter = useCallback(() => {
    if (sessionWarnCounter !== null) clearTimeout(sessionWarnCounter);

    if (sessionExpiryCounter !== null) clearTimeout(sessionExpiryCounter);
    sessionWarnCounter = setTimeout(() => {
      sessionExpiryMessage = message.warning({
        content: "Are you still there?",
        key: "expirynotice",
        duration: 5,
        style: {
          marginTop: "10vh",
        },
      });

      sessionExpiryCounter = setTimeout(() => {
        Cookies.remove("session");
        navigate("/login");
      }, 60000);
    }, 1800000);
  }, [navigate]);
  useEffect(() => {
    refreshEvents.forEach((eventType) => {
      document.addEventListener(eventType, refreshSessionCounter);
    });
  }, [refreshSessionCounter]);

  return (
    <ChatContextProvider>
      <Box id="main-body" sx={{ display: "flex", width: "100%" }}>
        <Header open={false} setDisplayRequestForm={setDisplayRequestForm} />

        {displayRequestForm && (
          <JobRequest
            displayRequestForm={displayRequestForm}
            onClose={() => setDisplayRequestForm(false)}
          />
        )}
        <Box
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            p: { xs: 0, sm: 3 },
            // marginLeft: 2,
          }}
        >
          <Toolbar />
          <Breadcrumbs
            navigation={navigation}
            title
            titleBottom
            card={false}
            divider={false}
          />
          <Outlet />
        </Box>
      </Box>
    </ChatContextProvider>
  );
};

export default MainLayout;
