import React, { useEffect, useRef, useState } from "react";
import { useUserState } from "../../../Context/UserContext";
import { Modal, ModalTitle } from "react-bootstrap";
import axios from "axios";
import { Avatar, Tooltip, Badge } from "antd";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "./message.css";
import { useMediaQuery } from "@mui/material";

import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import AttachmentFullDisplay from "./AttachmentFullDisplay";
import AttachmentDisplay from "./AttachmentDisplay";
import MessageField from "./MessageField";
import AttachmentUploadDisplay from "./AttachmentUploadDisplay";
import { useChatContext } from "../../../Context/ChatContext";

const Messenger = (props) => {
  const { user } = useUserState();
  const chatContext = useChatContext()

  const fileUpload = useRef();
  const scroll = useRef();

  const [showAttachment, setShowAttachment] = useState(null);
  const [attachmentViewer, setAttachmentViewer] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [typingStatus, setTypingStatus] = useState("");

  useEffect(() => {
    props.socket?.on("typingResponse", (data) => setTypingStatus(data));
  }, [props.socket]);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever new messages are added
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.messages]);

  const sendMessage = (message) => {
    axios
      .post("/communication/sendMessage", {
        ConversationId: props.chatId,
        JrConversationId: props.jrConversation.jr_conversation_id,
        Message: message,
        host: window.location.host
      })
      .then((response) => {
        if (
          response.data.newMessage.sender_user_id === user.id &&
          response.data.newMessage.conversation_id === parseInt(props.chatId)
        ) {
          props.setMessages((prev) => [...prev, response.data.newMessage]);
          // props.setNewMessage(response.data.newMessage);

          props.getChatList();
        }
      })
      .catch((err) => console.error(err));
      

    chatContext.sendNewMessage(message, props.conversation.chatter_id, props.conversation.conversation_id)
  };

  const sendMessageWithAttach = (message) => {
    let form = new FormData();
    for (let x in uploadFiles) {
      if ("url" in uploadFiles[x]) delete uploadFiles[x].url;
      form.append("file", uploadFiles[x]);
    }
    setUploadFiles([]);

    form.append("Message", message || "");
    form.append("ConversationId", props.chatId);
    form.append("JrConversationId", props.JrConversation.jr_conversation_id);

    axios
      .post("/communication/sendMessageWithAttach", form)
      .then((response) => {
        if (
          response.data.newMessage.sender_user_id === user.id &&
          response.data.newMessage.conversation_id === parseInt(props.chatId)
        ) {
          props.setMessages((prev) => [...prev, response.data.newMessage]);
          // props.setNewMessage(response.data.newMessage);
        }
      })
      .catch((err) => console.error(err));

    chatContext.sendNewMessage(message, props.conversation.chatter_id, props.conversation.conversation_id)
  };

  const setFiles = (files) => {
    if (uploadFiles.length < 10) {
      let fileList = [];
      for (
        let i = 0;
        i < files.length && fileList.length + uploadFiles.length < 10;
        i++
      ) {
        if (!uploadFiles.some((file) => files.item(i).name === file.name))
          fileList.push(files.item(i));
      }
      setUploadFiles([...uploadFiles, ...fileList]);
    }
  };

  const removeFile = (fileIndex) => {
    let newFileList = [...uploadFiles];
    newFileList.splice(fileIndex, 1);
    setUploadFiles(newFileList);
  };

  const viewAttachment = (attachment) => {
    setShowAttachment(attachment);
    setAttachmentViewer(true);
  };

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div
      className="messengerContainer noScroll"
      style={{ paddingTop: mobileView ? "1rem" : "0" }}
    >
      <Modal
        show={attachmentViewer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setAttachmentViewer(false);
        }}
      >
        <AttachmentFullDisplay file={showAttachment} />
      </Modal>
      <Modal.Header
        className="messengerHeader"
        style={{ justifyContent: "space-between" }}
      >
        <Link
          to={`/dashboard/chat`}
          onClick={() => {
            mobileView && props.setChatting(false);
          }}
        >
          <img
            src={require("../../../icons/backArrow.png")}
            className="backbtnComms mr-2"
            style={{ cursor: "pointer" }}
            alt="backIcon.png"
          />
        </Link>

        <ModalTitle className="fs-16 lh28 modalTitleComms">
          {props.conversation
            ? props.conversation.sender_name.split(" ")[0]
            : "Opening ...."}
        </ModalTitle>
        {/* <Link
          className="threadListing activethread"
          //   to={"/dashboard/chat/" + props.chatId}
          onClick={() => {
            mobileView && props.setJobView(true);
          }}
        > */}
        {mobileView && (
          <img
            src={require("../../../icons/forwardArrow.png")}
            className="backbtnComms mr-2"
            style={{ cursor: "pointer" }}
            alt="backIcon.png"
            onClick={() => {
              props.setJobView(true);
            }}
          />
        )}

        {/* </Link> */}
      </Modal.Header>

      <Modal.Body className="messengerBody" style={{ overflowY: "auto" }}>
        <ul className="chatBoxfixedBottom">
          {props.messages?.length > 0 ? (
            props.messages.map((message, messageIndex) => (
              <div key={messageIndex}>
                <DateDisplay message={message} />
                <MessageDisplay
                  key={message.msg_id}
                  message={message}
                  thisUser={user.id === message.sender_user_id}
                  nextUser={
                    messageIndex < props.messages.length - 1
                      ? {
                          id: props.messages[messageIndex + 1].sender_user_id,
                          unread: props.messages[messageIndex + 1].is_read
                        }
                      : {
                          id: null
                        }
                  }
                  conversation={props.conversation}
                  viewAttachment={viewAttachment}
                />
              </div>
            ))
          ) : (
            <li className="text-center g-text">
              Type in the textbox below to send a message{" "}
            </li>
          )}
        </ul>
        <div style={{ float: "left", clear: "both" }} ref={scroll}></div>
      </Modal.Body>

      <div style={{ height: "75px", maxHeight: "75px" }}>
        <div
          style={{
            height: "75px",
            overflowX: "hidden",
            overflowY: "hidden",
            position: "relative",
            zIndex: 2,
            left: "20px",
            bottom: "24px"
          }}
        >
          <div
            style={{
              width: `${
                75 * uploadFiles.length + (uploadFiles.length > 0 ? 75 : 0)
              }px`
            }}
          >
            {uploadFiles.map((file, fileIndex) => (
              <AttachmentUploadDisplay
                key={fileIndex}
                file={file}
                fileIndex={fileIndex}
                removeFile={removeFile}
                viewAttachment={viewAttachment}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <div className='message__status'>
				<p>{typingStatus}</p>
			</div> */}
      <Modal.Footer className="messengerFooter">
        <MessageField
          sendMessage={sendMessage}
          fileUpload={fileUpload}
          setFiles={setFiles}
          uploadFiles={uploadFiles}
          sendMessageWithAttach={sendMessageWithAttach}
          socket={props.socket}
          conversation={props.conversation}
          chatId={props.chatId}
        />
      </Modal.Footer>
    </div>
  );
};

const MessageDisplay = ({ message, thisUser, nextUser, viewAttachment, conversation }) => {
  if (thisUser)
    return (
      <li key={message.message_id} className="msgContainer floatRight">
        <div className="msgTextContainer txtContainerSelf">
          {"attachments" in message ? (
            <div>
              {message.attachments.map((attachment, attachIndex) => {
                return (
                  <AttachmentDisplay
                    side={message.message_text ? "left" : "right"}
                    key={attachIndex}
                    file={attachment}
                    viewAttachment={viewAttachment}
                  />
                );
              })}
            </div>
          ) : null}
          {message.message_text ? (
            <div className="myMessage msgText">{message.message_text}</div>
          ) : null}
          <Moment
            format="HH:mm"
            tz="America/St_Johns"
            className="g-text myMessageTime"
          >
            {message.time}
          </Moment>
        </div>
        <div className=" msgAvatar">
          <Tooltip title={message.name}>
            {message.sender_user_id !== nextUser.id ||
            message.is_read !== nextUser.unread ? (
              <Badge
                // offset={[45, 5]}
                // style={{ position: 'absolute' }}
                count={
                  message.is_read === 0 ? (
                    <CheckCircleOutlined style={{ color: "#01522f" }} />
                  ) : (
                    <CheckCircleFilled style={{ color: "#01522f" }} />
                  )
                }
              />
            ) : null}
          </Tooltip>
        </div>
      </li>
    );
  else
    return (
      <li key={message.msg_id} className="msgContainer">
        <div className="msgAvatar">
          <Tooltip title={message.name}>
            <Avatar src={conversation.imageurl} />
          </Tooltip>
        </div>
        <div className="msgTextContainer txtContainerOTher ">
          {"attachments" in message ? (
            <div>
              {message.attachments.map((attachment, attachIndex) => {
                return (
                  <AttachmentDisplay
                    side={message.message ? "right" : "left"}
                    key={attachIndex}
                    file={attachment}
                    viewAttachment={viewAttachment}
                  />
                );
              })}
            </div>
          ) : null}

          {message.message_text ? (
            <div className="otherMessage msgText">{message.message_text}</div>
          ) : null}

          <Moment format="H:mm" className="g-text otherMessageTime">
            {message.time}
          </Moment>
        </div>
      </li>
    );
};

const DateDisplay = ({ message }) => {
  const date = new Date();
  let displayDate = (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  ).toString();
  if (message.time.toString().slice(0, 10) !== displayDate) {
    return (
      <div className="container">
        <div>
          <Moment
            tz="America/St_Johns"
            format="MMM Do YYYY"
            className="g-text myMessageTime"
          >
            {message.time}
          </Moment>{" "}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div></div>
      </div>
    );
  }
};

export default Messenger;
