import PhoneInput from "../../Components/FormComponents/PhoneInput";
import DatePicker from "../../Components/FormComponents/DatePicker";

import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../Layout/components/@extended/AnimateButton";
import { useUserDispatch, useUserState } from "../../Context/UserContext";
import GetServices from "../commonComponents/API/GetServices";
import { useState } from "react";

const EditUserProfile = (props) => {
  const { user } = useUserState();
  const isVendor = props.user.vendorMode;

  const isEmployee =
    props.user.vendorMode && props.user.empRole === ("Admin" || "Manager");

  console.log(user, "gbsijbfusbfjhve");

  const mainServiceAddress =
    props.propertyList.length > 0 ? props.propertyList[0].property_id : 0;

  const [firstName, setFirstName] = useState(
    props.userType === "Employee"
      ? user.employee_first_name
      : user.first_name || ""
  );
  const [lastName, setLastName] = useState(
    props.userType === "Employee"
      ? user.employee_last_name
      : user.last_name || ""
  );
  const [email, setEmail] = useState(user.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "");
  // const [dob, setDob] = useState(user.dob);
  const [mainAddress, setMainAddress] = useState(mainServiceAddress || "");
  const dispatch = useUserDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(props);
    // const DOB = new Date(dob).toISOString();
    const updateObject = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      // DOB: DOB,
      // Gender: values.gender,
      UserType: "client",
      Phone: phoneNumber,
      mainAddress: mainAddress,
      vendorLegalName: "",
      vendorDbaName: "",
      vendorServices: "",
    };

    const userUpdateObject =
      props.userType === "Employee"
        ? {
            employee_first_name: firstName,
            employee_last_name: lastName,
            email: email,
            phone_number: phoneNumber,
          }
        : {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
          };

    // if (isVendor && !isEmployee) {
    //   updateObject.vendorLegalName = values.vendorLegalName;
    //   updateObject.vendorDbaName = values.vendorDbaName;
    //   updateObject.vendorServices = values.vendorServices;
    //   updateObject.UserType = "vendor";
    // }

    for (let key of Object.keys(updateObject)) {
      if (updateObject[key] == null) {
        console.error("ERROR:", key, "is missing");
      }
    }
    console.log(user);
    axios
      .post(
        props.userType !== "Employee"
          ? "/updateUserDetails"
          : "/updateEmployeeDetails",
        updateObject
      )
      .then((res) => {
        if (res.data.ok) {
          dispatch({
            type: "update",
            state: {
              user: {
                ...user,
                ...userUpdateObject,
              },
            },
          });
          console.log(user);
          props.closeUpdate();
          props.setUserFirstName(firstName);
          props.setUserLastName(lastName);
          props.setUserEmail(email);
          props.setUserPhoneNum(phoneNumber);

          //   props.fetchVendorData();
          props.fetchClientData();
          props.fetchEmployeeData();
          props.fetchProperties();
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhoneNumber("");
          setMainAddress("");
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div id="update-user-form">
      <form action="#update-user-form" onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>First Name*</InputLabel>
              <OutlinedInput
                id="firstName-signup"
                type="firstName"
                value={firstName}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Last Name*</InputLabel>
              <OutlinedInput
                id="lastName-signup"
                type="lastName"
                value={lastName}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Phone</InputLabel>
              <PhoneInput
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Stack>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Date of Birth</InputLabel>
              <DatePicker
                required
                type="date"
                name="dob"
                value={dob}
                onChange={(e, dateString) => setDob(dateString)}
              />
            </Stack>
          </Grid> */}
          {/* {isVendor && !isEmployee && (
            <>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Vendor Legal Name</InputLabel>
                  <OutlinedInput
                    id="vendorLegalName"
                    type="vendorLegalName"
                    value={values.vendorLegalName}
                    name="vendorLegalName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Vendor Legal Name"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Vendor dba Name</InputLabel>
                  <OutlinedInput
                    id="vendorDbaName-signup"
                    type="vendorDbaName"
                    value={values.vendorDbaName}
                    name="vendorDbaName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Vendor dba Name"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Stack>
                    <InputLabel>Provided Service</InputLabel>
                    <Select
                      style={{ marginTop: "0.5rem" }}
                      required
                      id="services"
                      name="services"
                      value={values.vendorServices}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {GetServices().map((service) => (
                        <MenuItem
                          value={service.service_name}
                          key={service.service_id}
                        >
                          {service.service_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Stack>
              </Grid>
            </>
          )} */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                id="postalCode-signup"
                type="email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                disabled
              />
            </Stack>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      width: 85,
                      height: 8,
                      borderRadius: "7px",
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Main Address</InputLabel>
              <Select
                id="address_selector"
                name="mainAddress"
                value={mainAddress}
                onChange={(e) => setMainAddress(e.target.value)}
              >
                {props.propertyList.map((property) => {
                  console.log(property);
                  const unitNumber =
                    property.unit_number !== null
                      ? ` Unit:${property.unit_number}`
                      : "";
                  return (
                    <MenuItem value={property.property_id}>
                      {property.street}
                      {unitNumber}, {property.city}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                onClick={onSubmit}
                size="sm"
                type="submit"
                variant="contained"
                color="primary"
                sx={{ ml: 2, float: "right" }}
              >
                Update
              </Button>
              <Button
                disableElevation
                size="sm"
                variant="outlined"
                color="secondary"
                onClick={props.closeUpdate}
                sx={{ ml: 2, float: "right" }}
              >
                Cancel
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditUserProfile;
