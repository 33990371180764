import PhoneInput from "../commonComponents/FormComponents/PhoneInput";

import axios from "axios";
import { message } from "antd";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@mui/material";

import LoadingSpinner from "../../Layout/components/LoadingSpinner";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../Layout/components/@extended/AnimateButton";
import GetServices from "../commonComponents/API/GetServices";
import { useUserState } from "../../Context/UserContext";
import { useState } from "react";

function convertStringToList(inputString) {
  // Check if the input is a non-empty string
  if (typeof inputString !== "string" || inputString.trim() === "") {
    return [];
  }

  // Use the split() method to convert the string into an array
  // Trim any leading or trailing spaces from each value
  const valuesList = inputString.split(",").map((value) => value.trim());

  return valuesList;
}

const EditVendorProfile = (props) => {
  const [locationError, setLocationError] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const { user } = useUserState();
  const services = GetServices();

  const isAdmin = props.user.is_admin === 1;

  const vendorDetails = props.user.vendorDetails;

  const [phoneNumber, setPhoneNumber] = useState(
    vendorDetails.phone_number || ""
  );
  const [vendorLegalName, setVendorLegalName] = useState(
    vendorDetails.vendor_legal_name || ""
  );
  const [vendorDbaName, setVendorDbaName] = useState(
    vendorDetails.vendor_dba_name || ""
  );
  const [vendorServices, setVendorServices] = useState(
    convertStringToList(vendorDetails.vendor_services) || []
  );
  const [businessNumber, setBusinessNumber] = useState(
    vendorDetails.business_number || ""
  );
  const [hstNumber, setHSTNumber] = useState(
    vendorDetails.hst_registration_number || ""
  );

  console.log(vendorDetails);
  console.log("vendorProfile props", props);
  console.log(user, "USERJBHISHIHIHIHI");
  console.log(props.vendorDetails);

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const updateObject = {
      BusinessNumber: businessNumber,
      HST: hstNumber,
      UserId: props.user.user_id,
      Email: props.vendorDetails.vendorDetails.company_email,
      vendorLegalName: vendorLegalName,
      vendorDbaName: vendorDbaName,
      vendorServices: `${vendorServices}`,
      userType: "vendor",
      EmpRole: user.empRole,
    };
    axios
      .post("/updateCompany", updateObject)
      .then((res) => {
        if (res.data.ok) {
          props.setEditVendorModal(false);
          props.setShowSuccessModal(true); // Show the success modal

          setTimeout(() => {
            props.setShowSuccessModal(false); // Close the modal after 5 seconds
          }, 5000);
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
        props.fetchVendorData();
      });
  };

  const initialValues = {
    phone: vendorDetails.phone_number,
    businessNumber: vendorDetails.business_number,
    hstNumber: vendorDetails.hst_registration_number,
    vendorLegalName: vendorDetails.vendor_legal_name,
    vendorDbaName: vendorDetails.vendor_dba_name,
    vendorServices: vendorServices,
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <div id="update-user-form">
        <form noValidate action="#update-user-form" onSubmit={onSubmit}>
          {/* {console.log(errors, values, handleSubmit)} */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Phone</InputLabel>
                <PhoneInput
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Vendor Legal Name</InputLabel>
                <OutlinedInput
                  id="vendorLegalName"
                  type="vendorLegalName"
                  value={vendorLegalName}
                  name="vendorLegalName"
                  onChange={(e) => setVendorLegalName(e.target.value)}
                  placeholder="Vendor Legal Name"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Vendor dba Name</InputLabel>
                <OutlinedInput
                  id="vendorDbaName-signup"
                  type="vendorDbaName"
                  value={vendorDbaName}
                  name="vendorDbaName"
                  onChange={(e) => setVendorDbaName(e.target.value)}
                  placeholder="Vendor dba Name"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Stack>
                  <InputLabel>Provided Service</InputLabel>
                  <Select
                    style={{ marginTop: "0.5rem" }}
                    required
                    id="vendorServices"
                    name="vendorServices"
                    value={vendorServices}
                    onChange={(e) => setVendorServices(e.target.value)}
                    multiple
                  >
                    {services.map((service) => (
                      <MenuItem
                        value={service.service_name}
                        key={service.service_id}
                      >
                        {service.service_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Business #</InputLabel>
                <OutlinedInput
                  id="city-signup"
                  type="businessNumber"
                  value={businessNumber}
                  name="businessNumber"
                  onChange={(e) => setBusinessNumber(e.target.value)}
                  placeholder="business number"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>HST Registration #</InputLabel>
                <OutlinedInput
                  id="city-signup"
                  type="hst"
                  value={hstNumber}
                  name="hstNumber"
                  onChange={(e) => setHSTNumber(e.target.value)}
                  placeholder="hst"
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  size="sm"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2, float: "right" }}
                >
                  Update
                </Button>
                <Button
                  disableElevation
                  size="sm"
                  variant="outlined"
                  color="secondary"
                  onClick={() => props.setEditVendorModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      </div>
    </LoadingSpinner>
  );
};

export default EditVendorProfile;
