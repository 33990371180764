import { Button, Grid, Stack } from "@mui/material";
import { Image, Modal } from "antd";
import { useState } from "react";

import moment from "moment-timezone";
import { localDate } from "../../util";
moment.tz.setDefault("Canada/Newfoundland");

function ExpandedTilePhase3(props) {
  const [reworkDetailsModal, setReworkDetailsModal] = useState();

  return (
    <>
      <div className="phase" id="phase-2">
        <div
          style={{ paddingBottom: "1rem", borderBottom: "1px solid #dddddd" }}
        >
          <div className="buttonContainer">
            <h5 style={{ fontWeight: "600" }}>Events</h5>
          </div>
          <div>
            {props.events !== "" && props.events.length > 0 && (
              <table className="table">
                <colgroup>
                  <col style={{ width: "15em" }} />
                  <col />
                  <col style={{ width: "15em" }}/>
                  <col style={{ width: "10em" }} />
                </colgroup>
                <thead>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Assigned To</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {props.events?.map(
                    (item) =>
                      item.is_terminated === 0 && (
                        <tr
                          className="tableRowHover"
                          style={{
                            height: "1.7rem",
                          }}
                          key={item.event_id}
                        >
                          <td style={{ textAlign: "center" }} className="td">
                            {moment(
                              localDate(
                                new Date(item.start_date).setTime(
                                  new Date(item.start_time).getTime()
                                )
                              )
                            ).format("ddd MMM DD, h:mm a")}
                          </td>
                          <td style={{ textAlign: "center" }} className="td">
                            {item.title}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {item.assigned_to}
                          </td>
                          {props.jrData.job_status !== "Scheduling" ? (
                            <td style={{ textAlign: "center", color: "green" }}>
                              Event Completed
                            </td>
                          ): <td></td>}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div
          style={{ paddingBottom: "1rem", borderBottom: "1px solid #dddddd" }}
        >
          <div className="buttonContainer">
            <h5 style={{ fontWeight: "600" }}>Troubleshooting</h5>
          </div>
          <div>
            {props.troubleshootingList !== "" &&
              props.troubleshootingList.length > 0 && (
                <table className="table">
                  <colgroup>
                    <col style={{ width: "15em" }} />
                    <col />
                    <col style={{ width: "15em" }}/>
                    <col style={{ width: "10em" }} />
                  </colgroup>
                  <thead>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Images</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {props.troubleshootingList?.map(
                      (item) =>
                        item.is_terminated === 0 && (
                          <tr
                            className="tableRowHover"
                            style={{
                              height: "1.7rem",
                            }}
                            key={item.troubleshoot_id}
                          >
                            <td style={{ textAlign: "center" }} className="td">
                              {moment(new Date(item.start_date)).format(
                                "MMM Do YYYY"
                              )}
                              ,{" "}
                              {moment(new Date(item.start_time)).format(
                                "h:mm a"
                              )}
                            </td>
                            <td style={{ textAlign: "center" }} className="td">
                              {item.troubleshoot_description}
                            </td>
                            <td style={{ textAlign: "center" }} className="td">
                              {props.troubleshootingImages
                                ?.filter(
                                  (image) =>
                                    item.troubleshoot_id ===
                                    image.troubleshoot_id
                                )
                                .map((image) => {
                                  return (
                                    <Image
                                      key={image.troubleshoot_attatchments_id}
                                      width={30}
                                      src={image.image}
                                    />
                                  );
                                })}
                            </td>
                            {props.jrData.job_status !== "Scheduling" ? (
                              <td
                                style={{ textAlign: "center", color: "green" }}
                              >
                                Completed
                              </td>
                            ): <td>{/* empty */}</td>}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              )}
          </div>
        </div>

        <div
          style={{ paddingBottom: "1rem", borderBottom: "1px solid #dddddd" }}
        >
          <div className="buttonContainer">
            <h5 style={{ fontWeight: "600" }}>Actions</h5>
          </div>
          <div>
            {props.actionList !== "" && props.actionList.length > 0 && (
              <table className="table">
                <colgroup>
                  <col style={{ width: "15em" }} />
                  <col />
                  <col style={{ width: "15em" }}/>
                  <col style={{ width: "10em" }} />
                </colgroup>
                <thead>
                  <th>Time</th>
                  <th>Description</th>
                  <th>Images</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {props.actionList?.map(
                    (item) =>
                      item.is_terminated === 0 && (
                        <tr
                          className="tableRowHover"
                          style={{
                            height: "1.7rem",
                          }}
                          key={item.action_id}
                        >
                          <td style={{ textAlign: "center" }} className="td">
                            {moment(new Date(item.start_date)).format(
                              "MMM Do YYYY"
                            )}
                            ,{" "}
                            {moment(new Date(item.start_time)).format("h:mm a")}{" "}
                            - {moment(new Date(item.end_time)).format("h:mm a")}
                          </td>
                          <td style={{ textAlign: "center" }} className="td">
                            {item.action_description}
                          </td>
                          <td style={{ textAlign: "center" }} className="td">
                            {(() => {
                              let arr = [];
                              props.actionImages?.forEach((image, i) => {
                                if (item.action_id === image.action_id) {
                                  arr.push(
                                    <Image
                                      key={item.action_attachments_id}
                                      width={30}
                                      src={image.image}
                                    />
                                  );
                                }
                              });
                              return arr;
                            })()}
                          </td>
                          {props.jrData.job_status !== "Scheduling" ? (
                            <td style={{ textAlign: "center", color: "green" }}>
                              Completed
                            </td>
                          ):<td>{/* empty */}</td>}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="phase">
          <h5 style={{ fontWeight: "600" }}>Activity Log</h5>
          <table>
            {props.activityList?.map((item, i) => (
                <tr
                  className="tableRowHover"
                  style={{ height: "1.7rem" }}
                  key={item.activity_log_id}
                >
                  <td style={{ textAlign: "left" }} className="td">
                    {item.user_name} &nbsp;
                    {item.activity_logs} &nbsp;
                    {moment(new Date(item.activity_time)).format(
                      "MMM Do YYYY, h:mm a"
                    )}
                  </td>
                </tr>
              ))}
          </table>
        </div>
        {typeof props.reworkDetails !== "undefined" &&
          props.reworkDetails.length > 0 &&
          props.jrData.job_status === "Scheduling" && (
            <div
              style={{
                marginTop: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="phase"
            >
              <Button
                style={{ width: "50%" }}
                color="primary"
                variant="contained"
                onClick={() => setReworkDetailsModal(true)}
                sx={{ ml: 2, float: "right" }}
              >
                View Rework Request
              </Button>
            </div>
          )}
      </div>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Rework Request Details"
        open={reworkDetailsModal}
        onCancel={() => setReworkDetailsModal(false)}
        footer={false}
      >
        {reworkDetailsModal && (
          <Grid container spacing={1}>
            {props.reworkDetails.length < 2 && (
              <Grid item xs={12}>
                <Stack spacing={1} direction={"column"}>
                  <h4>
                    {props.jrData.clientName} has requested rework of the Job
                    Request on{" "}
                    {moment(
                      new Date(props.reworkDetails[0].rework_request_date)
                    ).format("MMM Do YYYY, h:mm a")}
                  </h4>
                  <h4>Rework Description:</h4>
                  <p>{props.reworkDetails[0].rework_description}</p>
                </Stack>
              </Grid>
            )}
            {props.reworkDetails.length > 1 &&
              props.reworkDetails?.map((item, i) => (
                <Grid key={item.rework_id} item xs={12}>
                  <Stack spacing={1} direction={"column"}>
                    <h5
                      style={{
                        margin: "0",
                        borderBottom: "1px solid black",
                        fontWeight: "700",
                      }}
                    >
                      Rework #{i + 1}
                    </h5>
                    <h6>
                      {props.jrData.clientName} has requested rework of the Job
                      Request on{" "}
                      {moment(
                        new Date(props.reworkDetails[i].rework_request_date)
                      ).format("MMM Do YYYY, h:mm a")}
                    </h6>
                    <h5>Rework Description:</h5>
                    <p>{props.reworkDetails[i].rework_description}</p>
                  </Stack>
                </Grid>
              ))}
          </Grid>
        )}
      </Modal>
    </>
  );
}

export default ExpandedTilePhase3;
