import { VendorTile } from "../../commonComponents/JRComponents/VendorPhases/VendorTile";
import { ClientTile } from "../../commonComponents/JRComponents/ClientPhases/ClientTile";
import { useUserState } from "../../../Context/UserContext";
import { useCallback, useEffect, useState } from "react";
import useOnce from "../../../hooks/useOnce";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useJRContext } from "../../../Context/JobRequestContext";

/**
 * Displays jobs for inbox
 * @param {{jrList: Array<object>, openJob:number|null, chatId: number|null, setJobView: (state:boolean)=>void, setOpenJob:(id:number)=>void}} props 
 * @returns {JSX.Element}
 */
const JobDisplay = (props={openJob:null}) => {
  const { user } = useUserState();
  const [jrList, setJrList] = useState([]);
  const {openJR, setOpenJR} = useJRContext()

  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0
  });

  const fetchChargeData = useCallback(() => {
    axios.get("/finance/getChargePercentages").then((res) => {
      setChargePercentages(res.data);
    });
  }, []);

  const getJRs = useCallback(() => {
    axios
      .post("/communication/getConversationJR", {
        ConversationId: Number.parseInt(props.chatId)
      })
      .then((response) => {
        setJrList(response.data.jrList);
        if (props.openJob != null && openJR?.job_id === props.openJob){
          console.log("jobID")
        }
        console.log("openJR", props.openJob, openJR)
        console.log(response.data.jrList)
      });
  }, [props.chatId, openJR]);

  useEffect(() => {
    if (props.chatId !== null) getJRs();
    else {
      setJrList([]);
    }
    fetchChargeData();
  }, [fetchChargeData, getJRs, props.chatId]);

  const NoJR = () => {
    return (
      <div className="noSelectedImg text-center">
        <img
          src={require("../../../images/emptyThread.png")}
          alt="emptyThread"
        />
        <div className="g-text-lg">
          There is no Job request linked to the conversation.
        </div>
      </div>
    );
  };

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div className={`listContainer`} style={{ paddingTop: "2rem" }}>
      {mobileView && (
        <div style={{ height: "4rem" }}>
          <Link
            className="threadListing activethread"
            to={`/dashboard/chat/${props.chatId}`}
            onClick={() => {
              props.setJobView(false);
            }}
          >
            <img
              src={require("../../../icons/backArrow.png")}
              className="backbtnComms mr-2"
              style={{ cursor: "pointer" }}
              alt="backIcon.png"
            />
          </Link>
        </div>
      )}

      {jrList.length > 0 ? (
        <div className={`listScrollContainer`}>
          {jrList.map((jr, jrIndex) => {
            // show client tile if user is requester else show vendor tile
            return user.id === jr.requester_id ? (
              <ClientTile
                chargePercentages={chargePercentages}
                fromChat={true}
                jrData={jr}
                fetchJrData={props.fetchJrData}

                // openJR={openJR}
                // setOpenJR={setOpenJR}
                // key={jr.job_id}
              />
            ) : (
              <VendorTile
                chargePercentages={chargePercentages}
                fromChat={true}
                jrData={jr}
                fetchJrData={props.fetchJrData}
                // openJR={openJR}
                // setOpenJR={setOpenJR}
                // key={jr.job_id}
              />
            );
          })}{" "}
        </div>
      ) : (
        <NoJR />
      )}
    </div>
  );
};

export default JobDisplay;
