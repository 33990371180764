import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Link,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import { Modal, Checkbox } from "antd";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GetProvinceList from "../../Authentication/auth-forms/GetProvinceList";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import TermsAndConditions from "../../PDF/TermsViewer";
import PrivacyPolicy from "../../PDF/TermsViewer";

const ClientFormAfterLogin = (props) => {
  const provinces = GetProvinceList();
  // Client Details

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };

  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };
  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      setSubmitting(true);

      const response = await axios.post("/auth/addClientByVendor", {
        UserId: props.userData.user.id,
        Email: props.userData.user.email,
        FirstName: firstName,
        LastName: lastName,
        Street: streetAddress,
        City: city,
        Province: province,
        PostalCode: postalCode,
      });

      console.log("Without Email Response:", response);
      props.onAgree();
    } catch (error) {
      console.error("Error adding client:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <LoadingSpinner isActive={submitting}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="first-name-add-client">
                First Name
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="first-name-add-client"
                type="text"
                value={firstName}
                name="firstName"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                placeholder="John"
                inputProps={{}}
              />
              {!firstName && errorMessage !== "" && errorMessage?.firstName && (
                <Typography color="error">{errorMessage?.firstName}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="last-name-add-client">Last Name</InputLabel>
              <OutlinedInput
                fullWidth
                id="last-name-add-client"
                type="text"
                value={lastName}
                name="lastName"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                placeholder="Doe"
                inputProps={{}}
              />
              {!lastName && errorMessage !== "" && errorMessage?.lastName && (
                <Typography color="error">{errorMessage?.lastName}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              className={"css-1hb7zxy-IndicatorsContainer"}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
              autocompletionRequest={{
                componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                types: ["address"],
              }}
              selectProps={{
                name: "streetAddress",
                value: streetAddress,
                onChange: async (selectedOption) => {
                  console.log(selectedOption);

                  const placeId = selectedOption.value.place_id;
                  const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                  const response = await axios.get(
                    `/jobRequest/place-details`,
                    {
                      params: { placeId, key },
                    }
                  );
                  console.log(response.data.result);

                  const addressComponents =
                    response.data.result.address_components;
                  const streetNumber =
                    addressComponents.find((component) =>
                      component.types.includes("street_number")
                    )?.long_name || "";
                  const route =
                    addressComponents.find((component) =>
                      component.types.includes("route")
                    )?.long_name || "";
                  const city =
                    addressComponents.find((component) =>
                      component.types.includes("locality")
                    )?.long_name || "";
                  const province =
                    addressComponents.find((component) =>
                      component.types.includes("administrative_area_level_1")
                    )?.short_name || "";

                  const postalCode =
                    response.data.result.address_components.find((component) =>
                      component.types.includes("postal_code")
                    )?.long_name || "";

                  // Combine street number and route for the full street address
                  const street = `${streetNumber} ${route}`.trim();

                  // Update city and province fields in Formik values
                  setStreetAddress(street);
                  setCity(city);
                  setProvince(province);
                  setPostalCode(postalCode);
                },
                placeholder: "Find address here",
              }}
            />
            <style>
              {`
            .css-1hb7zxy-IndicatorsContainer {
              display: none; // Hide dropdown icon
            }
          `}
            </style>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="street-address-add-client">
                Street Address
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="street-address-add-client"
                type="text"
                value={streetAddress}
                name="streetAddress"
                onChange={(e) => {
                  setStreetAddress(e.target.value);
                }}
                placeholder="123 Main St"
                inputProps={{}}
              />
              {!streetAddress &&
                errorMessage !== "" &&
                errorMessage?.streetAddress && (
                  <Typography color="error">
                    {errorMessage?.streetAddress}
                  </Typography>
                )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="city-add-client">City</InputLabel>
              <OutlinedInput
                fullWidth
                id="city-add-client"
                type="text"
                value={city}
                name="city"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                placeholder="Toronto"
                inputProps={{}}
              />
              {!city && errorMessage !== "" && errorMessage?.city && (
                <Typography color="error">{errorMessage?.city}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="province-add-client">Province</InputLabel>
              <Select
                id="province-add-client"
                value={province}
                name="province"
                defaultValue={"Select Province"}
                onChange={(e) => {
                  setProvince(e.target.value);
                }}
              >
                {provinces.map((provinces) => (
                  <MenuItem
                    value={provinces.province_shortform}
                    key={provinces.province_shortform}
                  >
                    {provinces.province_name}
                  </MenuItem>
                ))}
              </Select>
              {!province && errorMessage !== "" && errorMessage?.province && (
                <Typography color="error">{errorMessage?.province}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="postal-code-add-client">
                Postal Code
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="postal-code-add-client"
                type="text"
                value={postalCode}
                name="postalCode"
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
                placeholder="A1A 1A1"
                inputProps={{}}
              />
              {!postalCode &&
                errorMessage !== "" &&
                errorMessage?.postalCode && (
                  <Typography color="error">
                    {errorMessage?.postalCode}
                  </Typography>
                )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <Checkbox
                checked={hasAgreed}
                onClick={(e) => {
                  // if (termsIsRead && policyIsRead){
                  setHasAgreed(e.target.checked);
                  setShowCheckboxError(false);
                  // }else{
                  //   setShowCheckboxError(true)
                  // }
                }}
              />
              &nbsp; I have read and agreed to &nbsp;
              <Link
                color={"#188bff"}
                variant="subtitle2"
                onClick={() => openTermsAndConditions()}
              >
                Terms of Service
              </Link>
              &nbsp; and &nbsp;
              <Link
                color={"#188bff"}
                variant="subtitle2"
                onClick={() => openPrivatePolicy()}
              >
                Privacy Policy
              </Link>
            </Typography>
            <Typography color={"#ff0000"}>
              {showCheckboxError &&
                "*You must read the terms and conditions & privacy policy"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{
                  backgroundColor:
                    firstName &&
                    lastName &&
                    streetAddress &&
                    city &&
                    province &&
                    postalCode &&
                    hasAgreed
                      ? "#01552f"
                      : "#9c9c9c",
                }}
                disabled={
                  !firstName ||
                  !lastName ||
                  !streetAddress ||
                  !city ||
                  !province ||
                  !postalCode ||
                  !hasAgreed
                }
              >
                Continue
              </Button>
            </AnimateButton>
          </Grid>
          {/*  Open Terms and Conditions */}
          <Modal
            title="Terms And Conditions"
            width="700px"
            open={isOpenTerms}
            onCancel={closeTermsAndConditions}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={closeTermsAndConditions}
              >
                Ok
              </Button>,
            ]}
            bodyStyle={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 250px)",
            }}
          >
            {isOpenTerms ? (
              <TermsAndConditions
                signIn={true}
                openPolicy={() => {
                  closeTermsAndConditions();
                  openPrivatePolicy();
                }}
              />
            ) : (
              ""
            )}
          </Modal>

          {/*  Open Privacy Policy */}
          <Modal
            title="Privacy Policy"
            width="700px"
            maxHeight="98vh"
            open={isOpenPolicy}
            onCancel={closePrivatePolicy}
            footer={[
              <Button key="submit" type="primary" onClick={closePrivatePolicy}>
                Ok
              </Button>,
            ]}
            bodyStyle={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 250px)",
            }}
          >
            {isOpenPolicy ? <PrivacyPolicy signIn={true} /> : ""}
          </Modal>
        </Grid>
      </LoadingSpinner>
    </form>
  );
};

export default ClientFormAfterLogin;
