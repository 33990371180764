import {
  MenuItem,
  Select,
  Stack,
  Grid,
  OutlinedInput,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../Layout/components/LoadingSpinner";
import SetClientAvailability from "../../../commonComponents/JRComponents/Availability/SetClientAvailability";
import { useUserState } from "../../../../Context/UserContext";

const AddClientJob = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const { user } = useUserState();

  const [properties, setProperties] = useState([]);
  const [property, setProperty] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [error, setError] = useState("");

  const fetchProperties = async () => {
    await axios
      .post("/property/getPropertyDetails", { userId: props.client.id })
      .then((res) => {
        console.log(props.client);
        console.log(res.data.property);
        setProperties(res.data.property);
        res.data.property.forEach((prop, index) => {
          if (
            prop.address?.includes(props.client.street) &&
            prop.address?.includes(props.client.city)
          ) {
            setProperty(index);
          }
        });
      });
  };

  function importFile() {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    console.log(newArray, "NEWARRAYfgjkshgisoh");
    input.click();
  }

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(newArray);
    }
  };

  const createAttachmentDisplay = (attachments, jrImages) => {
    console.log(attachments, "ATTACHMENTS IN DISPLAYngksbgjkhfb");
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }

    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const handleErrors = () => {
    console.log(availabilityArray.length);
    const errors = {};
    if (jobTitle === "") {
      errors.jobTitle = "Job Title is required";
    }
    if (jobDescription === "") {
      errors.jobDescription = "Job Description is required";
    }
    if (property === null) {
      errors.property = "Please select a property";
    }
    if (availabilityArray.length < 1) {
      errors.availability = "Please set availability";
    }
    console.log(errors);
    setError(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmitJob = async () => {
    const isValid = handleErrors();
    if (!isValid) {
      console.log("Form has errors, please fix them.");
      setSubmitting(false);
      return;
    }

    const prompt = {
      prompt: jobDescription,
    };

    const categoryResponse = await axios.post("/api/categorySelection", prompt);
    console.log(categoryResponse);

    if (
      categoryResponse.statusText === "OK" ||
      categoryResponse.status === 200
    ) {
      const formObject = {
        clientId: props.client.id,
        propertyId: properties[property].property_id,
        jobTitle: jobTitle,
        category: categoryResponse.data,
        jobDescription: jobDescription,
        assignedVendorId: user.id,
        directAssigned: 1,
        sendMarket: 0,
        activityDesc: `Created job request "${jobTitle}".`,
        userName: `${user.first_name} ${user.last_name}`,
        taskArray: JSON.stringify(availabilityArray),
      };

      await axios
        .post("/jobRequest/newJobRequest", formObject)
        .then((response) => {
          let jobId = response.data.jobId;
          if (response.data.ok) {
            if (attachments.length > 0) {
              let form = new FormData();
              for (let attachment of attachments) {
                form.append("file", attachment);
              }
              form.append("job_id", jobId);
              axios.post("/jobRequest/addImages", form).then((res) => {
                setSubmitting(false);
                props.setClientsJobModal(false);
              });
            }
            setSubmitting(false);
            props.setClientsJobModal(false);
          }
        });
    }
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack>
              <Select
                id="property"
                name="property"
                value={property}
                onChange={(e) => {
                  setProperty(e.target.value);
                  if (error.property) {
                    setError((prevState) => ({
                      ...prevState,
                      property: !property
                        ? "Please select a property"
                        : undefined,
                    }));
                  }
                }}
              >
                {properties.map((prop, index) => (
                  <MenuItem key={index} value={index}>
                    {prop.address}
                  </MenuItem>
                ))}
              </Select>
              {error && error.property && (
                <Typography color="error">{error.property}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                required
                id="jobTitle"
                name="jobTitle"
                type="text"
                placeholder="What is the issue?"
                value={jobTitle}
                onChange={(e) => {
                  setJobTitle(e.target.value);
                  if (error.jobTitle) {
                    setError((prevState) => ({
                      ...prevState,
                      jobTitle: !jobTitle ? "Job Title is required" : undefined,
                    }));
                  }
                }}
              />
              {error && error.jobTitle && (
                <Typography color="error">{error.jobTitle}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                multiline
                rows={5}
                required
                id="jobDescription"
                name="jobDescription"
                type="text"
                inputProps={{ maxLength: 500 }}
                placeholder="Describe the issue in detail"
                value={jobDescription}
                onChange={(e) => {
                  setJobDescription(e.target.value);
                  if (error.jobDescription) {
                    setError((prevState) => ({
                      ...prevState,
                      jobDescription: !jobDescription
                        ? "Job Description is required"
                        : undefined,
                    }));
                  }
                }}
              />
              {error && error.jobDescription && (
                <Typography color="error">{error.jobDescription}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack>
              {/* <InputLabel>
                  Please upload any pictures or documents or videos
                </InputLabel> */}
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={importFile}
                id="document"
                name="document"
                fullWidth
              >
                Add Pictures and Documents
              </Button>

              {props.jrImages && props.jrImages.length > 0 && (
                <div className="attachments">
                  {props.jrImages.map((image, index) => (
                    <div
                      key={index}
                      className="attachment"
                      // onClick={() =>
                      //   deleteUploadedImage(image.jobrequest_images_id)
                      // }
                    >
                      <img key={index} src={image.image} alt="" />
                    </div>
                  ))}
                </div>
              )}
              {attachments !== undefined && attachments.length !== 0 && (
                <div className="attachments">
                  {createAttachmentDisplay(attachments)}
                </div>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack direction="column" width="100%" alignItems="center">
              {/* <AnimateButton> */}
              <Button
                fullWidth
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setAvailabilityModal(true);
                }}
              >
                Pick a Time
              </Button>
              {error && error.availability && (
                <Typography color="error">{error.availability}</Typography>
              )}
              {/* </AnimateButton> */}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Stack>
              <Button
                disabled={
                  jobTitle === "" ||
                  jobDescription === "" ||
                  property === null ||
                  availabilityArray.length < 1
                }
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setSubmitting(true);
                  handleSubmitJob();
                }}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {availabilityModal && (
        <SetClientAvailability
          jrData={props.jrData}
          onClose={() => setAvailabilityModal(false)}
          setAvailabilityArray={setAvailabilityArray}
          availabilityArray={availabilityArray}
        />
      )}
    </LoadingSpinner>
  );
};

export default AddClientJob;
