import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#01522f" }} spin />
);

const LoadScreen = ({ className = "", small = false }) => {
  if (small) {
    return <Spin indicator={antIcon} className={className} />;
  } else {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          marginTop: 150,
        }}
      >
        <img
          src={require("../icons/loader.gif")}
          className={"loadIcon " + className}
          alt="Loading"
          width={64}
          height={64}
          // style={{
          //   filter:
          //     "brightness(0.9) saturate(1.5) contrast(1.2) hue-rotate(220deg)",
          // }}
        />
      </div>
    );
  }
};

export default LoadScreen;
