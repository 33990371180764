import axios from "axios";
import { useState } from "react";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";

const {
  Grid,
  InputLabel,
  Stack,
  OutlinedInput,
  Select,
  MenuItem,
  Typography,
  Button,
} = require("@mui/material");
const {
  default: GetProvinceList,
} = require("../../Authentication/auth-forms/GetProvinceList");
const {
  default: GooglePlacesAutocomplete,
} = require("react-google-places-autocomplete");

const EditEmployeeForm = (props) => {
  const [firstName, setFirstName] = useState(props.employee.first_name || "");
  const [lastName, setLastName] = useState(props.employee.last_name || "");
  const [role, setRole] = useState(props.employee.role || "");
  const [phoneNumber, setPhoneNumber] = useState(
    props.employee.phone_number || ""
  );
  const [streetAddress, setStreetAddress] = useState(
    props.employee.street || ""
  );
  const [city, setCity] = useState(props.employee.city || "");
  const [province, setProvince] = useState(props.employee.province || "");
  const [postalCode, setPostalCode] = useState(
    props.employee.postal_code || ""
  );
  const [email, setEmail] = useState(props.employee.email || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const roles = ["Manager", "Supervisor", "Worker"];
  const provinces = GetProvinceList();

  const displaySubmitAlert = () => {
    props.setSubmitAlert(true);
    setTimeout(() => props.setSubmitAlert(false), 4000);
  };

  const handleSubmit = async (e) => {
    console.log("SUBMITTITNG");
    e.preventDefault();
    setSubmitting(true);

    const employeeData = {
      FirstName: firstName,
      LastName: lastName,
      phoneNumber: phoneNumber,
      Street: streetAddress,
      City: city,
      Province: province,
      PostalCode: postalCode,
      email: email,
      role: role,
      employeeId: props.employee.employee_id,
      employeeUserId: props.employee.employee_user_id,
    };

    await axios
      .post("/employee/updateEmployeeDetails", employeeData)
      .then((res) => {
        console.log("Employee updated successfully");
        console.log(res.data);
        setSubmitting(false);
        props.fetchData();
        props.onClose();
        displaySubmitAlert();
      });
  };

  return (
    <LoadingSpinner isActive={submitting}>
      {console.log(submitting, "SUBMITIING")}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>First Name</InputLabel>
              <OutlinedInput
                value={firstName}
                type="text"
                name="firstName"
                placeholder="John"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Last Name</InputLabel>
              <OutlinedInput
                value={lastName}
                type="text"
                name="lastName"
                placeholder="Doe"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              {console.log(props.employee.email, "FHUDGBFUHFUOH")}
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                disabled={props.employee.email !== null}
                value={email}
                type="email"
                name="email"
                placeholder="john.doe@example.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                name="role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                {roles.map((role) => (
                  <MenuItem value={role} key={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              className={"css-1hb7zxy-IndicatorsContainer"}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
              autocompletionRequest={{
                componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                types: ["address"],
              }}
              selectProps={{
                name: "streetAddress",
                value: streetAddress,
                onChange: async (selectedOption) => {
                  console.log(selectedOption);

                  const placeId = selectedOption.value.place_id;
                  const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                  const response = await axios.get(
                    `/jobRequest/place-details`,
                    {
                      params: { placeId, key },
                    }
                  );
                  console.log(response.data.result);

                  const addressComponents =
                    response.data.result.address_components;
                  const streetNumber =
                    addressComponents.find((component) =>
                      component.types.includes("street_number")
                    )?.long_name || "";
                  const route =
                    addressComponents.find((component) =>
                      component.types.includes("route")
                    )?.long_name || "";
                  const city =
                    addressComponents.find((component) =>
                      component.types.includes("locality")
                    )?.long_name || "";
                  const province =
                    addressComponents.find((component) =>
                      component.types.includes("administrative_area_level_1")
                    )?.short_name || "";

                  const postalCode =
                    response.data.result.address_components.find((component) =>
                      component.types.includes("postal_code")
                    )?.long_name || "";

                  // Combine street number and route for the full street address
                  const street = `${streetNumber} ${route}`.trim();

                  // Update city and province fields in Formik values
                  setStreetAddress(street);
                  setCity(city);
                  setProvince(province);
                  setPostalCode(postalCode);
                },
                placeholder: "Find address here",
              }}
            />
            <style>
              {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
            </style>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="street-address-add-client">
                Street Address
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="street-address-add-client"
                type="text"
                value={streetAddress}
                name="streetAddress"
                onChange={(e) => {
                  setStreetAddress(e.target.value);
                }}
                placeholder="123 Main St"
                inputProps={{}}
              />
              {!streetAddress &&
                errorMessage !== "" &&
                errorMessage?.streetAddress && (
                  <Typography color="error">
                    {errorMessage?.streetAddress}
                  </Typography>
                )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="city-add-client">City</InputLabel>
              <OutlinedInput
                fullWidth
                id="city-add-client"
                type="text"
                value={city}
                name="city"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                placeholder="Toronto"
                inputProps={{}}
              />
              {!city && errorMessage !== "" && errorMessage?.city && (
                <Typography color="error">{errorMessage?.city}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="province-add-client">Province</InputLabel>
              <Select
                id="province-add-client"
                value={province}
                name="province"
                defaultValue={"Select Province"}
                onChange={(e) => {
                  setProvince(e.target.value);
                }}
              >
                {provinces.map((provinces) => (
                  <MenuItem
                    value={provinces.province_shortform}
                    key={provinces.province_shortform}
                  >
                    {provinces.province_name}
                  </MenuItem>
                ))}
              </Select>
              {!province && errorMessage !== "" && errorMessage?.province && (
                <Typography color="error">{errorMessage?.province}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="postal-code-add-client">
                Postal Code
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="postal-code-add-client"
                type="text"
                value={postalCode}
                name="postalCode"
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
                placeholder="A1A 1A1"
                inputProps={{}}
              />
              {!postalCode &&
                errorMessage !== "" &&
                errorMessage?.postalCode && (
                  <Typography color="error">
                    {errorMessage?.postalCode}
                  </Typography>
                )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                //style={{ backgroundColor: userChecked ? "#01552f" : "#9c9c9c" }}
                disabled={
                  !role ||
                  firstName === "" ||
                  lastName === "" ||
                  streetAddress === "" ||
                  city === "" ||
                  province === "" ||
                  postalCode === ""
                }
              >
                Update Employee
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </LoadingSpinner>
  );
};

export default EditEmployeeForm;
