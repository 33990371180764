import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  RadioGroup,
  Radio,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Link,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator,
} from "../../../Layout/utils/password-strength";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import axios from "axios";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { set } from "lodash";
import GetProvinceList from "./GetProvinceList";
import { Modal } from "antd";
import TermsAndConditions from "../../PDF/TermsViewer";
import PrivacyPolicy from "../../PDF/PrivacyViewer";

const AuthRegister = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [spinnerActive, setSpinnerActive] = useState(false);

  // feild states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [clientSecret, setClientSecret] = useState(false);

  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);

  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);

  const [signupActive, setSignUpActive] = useState(false);

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };
  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };

  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  // Mapping field names to user-friendly labels
  const fieldLabels = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    passwordConfirm: "Confirm Password",
    streetAddress: "Street Address",
    city: "City",
    province: "Province",
    postalCode: "Postal Code",
  };

  // Email validation regex pattern
  const emailRegex = /^\S+@\S+\.\S+$/;
  // Validate the field onBlur
  const validateField = (fieldName, value) => {
    const label = fieldLabels[fieldName] || fieldName; // Use the label mapping
    let error = "";
    if (!value.trim()) {
      error = `${label} is required`;
    } else if (fieldName === "email" && !emailRegex.test(value)) {
      error = "Enter a valid email address (e.g., example@email.com)";
    }
    setError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  // Handle input blur event
  const handleBlur = (e) => {
    validateField(e.target.name, e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const state = location.state;

    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }

    setSubmitting(true);

    axios.post("auth/userExists", { email: email }).then(async (response) => {
      // setSubmitting(true);
      const userExists = response.data.exists;
      console.log({
        email: email,
        password: password,
        Terms: `${firstName}-${lastName}-${"testEsig"}${new Date().getFullYear()}T&C`,
        Policy: `${firstName}-${lastName}-${"testEsig"}${new Date().getFullYear()}PP`,
        host: window.location.host,
        Client: 1,
        Vendor: 0,
        FirstName: firstName,
        LastName: lastName,
        Street: streetAddress,
        City: city,
        Province: province,
        PostalCode: postalCode,

        vendorMode: false,
      });

      try {
        if (userExists) {
          setSubmitting(false);
          setErrorMessage("User with this email already exists");
          return;
        } else {
          console.log(state);
          axios
            .post("/auth/register", {
              email: email,
              password: password,
              Terms: `${firstName}-${lastName}${new Date().getFullYear()}T&C`,
              Policy: `${firstName}-${lastName}${new Date().getFullYear()}PP`,
              host: window.location.host,
              Client: 1,
              Vendor: 0,
              FirstName: firstName,
              LastName: lastName,
              Street: streetAddress,
              City: city,
              Province: province,
              PostalCode: postalCode,

              vendorMode: false,
            })
            .then((res) => {
              console.log(state, "fdsgsdgsdfwvcwe");
              console.log(res.data);
              if (state) {
                const jobData = {
                  userID: res.data.clientID,
                  propertyId: res.data.clientPropertyID,
                  jobTitle: state.jobData.jobTitle,
                  category: state.jobData.category,
                  jobDescription: state.jobData.jobDescription,
                  directAssigned: 0,
                  sendMarket: state.jobData.sendMarket,
                  activityDesc: state.jobData.activityDesc,
                  userName: `${firstName} ${lastName}`,
                  taskArray: state.jobData.taskArray,
                  lat: res.data.lat,
                  lon: res.data.lon,
                };
                console.log(jobData, "JOBDATAkjhjsigfiys");
                axios
                  .post("/jobRequest/newJobRequestSignup", jobData)
                  .then((response) => {
                    navigate("/accountcreated");
                    setSubmitting(false);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                    setPasswordConfirm("");
                    setStreetAddress("");
                    setCity("");
                    setProvince("");
                    setPostalCode("");
                    setErrorMessage("");
                    setClientSecret(res.data.clientSecret);
                  });
              } else {
                navigate("/accountcreated");
                setSubmitting(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setPassword("");
                setPasswordConfirm("");
                setStreetAddress("");
                setCity("");
                setProvince("");
                setPostalCode("");
                setErrorMessage("");
                setClientSecret(res.data.clientSecret);
              }
            });
        }
      } catch (err) {
        setSubmitting(false);
        console.error(err);
      }
    });
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {/* First Name Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="first-name-signup">First Name*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="first-name-signup"
                  type="text"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="John"
                  inputProps={{}}
                />
                {error.firstName && (
                  <Typography color="error">{error.firstName}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Last Name Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="last-name-signup">Last Name*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="last-name-signup"
                  type="text"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Doe"
                  inputProps={{}}
                />
                {error.lastName && (
                  <Typography color="error">{error.lastName}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Email Address Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="email-login"
                  type="Email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="email"
                  inputProps={{}}
                />
                {error.email && (
                  <Typography color="error">{error.email}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Password Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-signup">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password-signup"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    changePassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
                {error.password && (
                  <Typography color="error">{error.password}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Confirm Password Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="passwordConfirm-signup">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="passwordConfirm-signup"
                  type={showPassword ? "text" : "password"}
                  value={passwordConfirm}
                  name="password"
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                  }}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
                {error.passwordConfirm && (
                  <Typography color="error">{error.passwordConfirm}</Typography>
                )}
              </Stack>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      sx={{
                        bgcolor: level?.color,
                        width: 85,
                        height: 8,
                        borderRadius: "7px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            {/* Address Search Input */}
            <Grid item xs={12} md={12}>
              <GooglePlacesAutocomplete
                className={"css-1hb7zxy-IndicatorsContainer"}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
                autocompletionRequest={{
                  componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                  types: ["address"],
                }}
                selectProps={{
                  name: "streetAddress",
                  value: streetAddress,
                  onChange: async (selectedOption) => {
                    console.log(selectedOption);

                    const placeId = selectedOption.value.place_id;
                    const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                    const response = await axios.get(
                      `/jobRequest/place-details`,
                      {
                        params: { placeId, key },
                      }
                    );
                    console.log(response.data.result);

                    const addressComponents =
                      response.data.result.address_components;
                    const streetNumber =
                      addressComponents.find((component) =>
                        component.types.includes("street_number")
                      )?.long_name || "";
                    const route =
                      addressComponents.find((component) =>
                        component.types.includes("route")
                      )?.long_name || "";
                    const city =
                      addressComponents.find((component) =>
                        component.types.includes("locality")
                      )?.long_name || "";
                    const province =
                      addressComponents.find((component) =>
                        component.types.includes("administrative_area_level_1")
                      )?.short_name || "";

                    // const addressComponents = selectedOption.label.split(", ");
                    // console.log(addressComponents);
                    // const street = addressComponents[0];
                    // const city = addressComponents[1];
                    // const province = addressComponents[2];
                    const postalCode =
                      response.data.result.address_components.find(
                        (component) => component.types.includes("postal_code")
                      )?.long_name || "";

                    // Combine street number and route for the full street address
                    const street = `${streetNumber} ${route}`.trim();

                    // Update city and province fields in Formik values
                    setStreetAddress(street);
                    setCity(city);
                    setProvince(province);
                    setPostalCode(postalCode);
                  },
                  placeholder: "Find address here",
                }}
              />
              <style>
                {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
              </style>
            </Grid>

            {/* Street Address Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="street-address-signup">
                  Street Address
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="street-address-signup"
                  type="text"
                  value={streetAddress}
                  name="streetAddress"
                  onChange={(e) => setStreetAddress(e.target.value)}
                  placeholder="123 Main St"
                  inputProps={{}}
                  onBlur={handleBlur}
                />
                {error.streetAddress && (
                  <Typography color="error">{error.streetAddress}</Typography>
                )}
              </Stack>
            </Grid>

            {/* City Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="city-signup">City</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="city-signup"
                  type="text"
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Toronto"
                  onBlur={handleBlur}
                  inputProps={{}}
                />
                {error.city && (
                  <Typography color="error">{error.city}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Province Input */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Province</InputLabel>
                <Select
                  id="province-signup"
                  value={province}
                  name="province"
                  defaultValue={"Select Province"}
                  onChange={(e) => setProvince(e.target.value)}
                  onBlur={handleBlur}
                >
                  {GetProvinceList().map((provinces) => (
                    <MenuItem
                      value={provinces.province_shortform}
                      key={provinces.province_shortform}
                    >
                      {provinces.province_name}
                    </MenuItem>
                  ))}
                </Select>
                {error.province && (
                  <Typography color="error">{error.province}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Postal Code Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="postal-code-signup">
                  Postal Code
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="postal-code-signup"
                  type="text"
                  value={postalCode}
                  name="postalCode"
                  onChange={(e) => setPostalCode(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="A1A 1A1"
                  inputProps={{}}
                />
                {error.postalCode && (
                  <Typography color="error">{error.postalCode}</Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                <Checkbox
                  checked={signupActive}
                  onChange={(e) => {
                    // if (termsIsRead && policyIsRead) {
                    //   setActiveCreateAccount(e.target.checked);
                    //   setTermsReadError(false);
                    // } else {
                    //   setTermsReadError(true);
                    // }
                    setSignUpActive(!signupActive);
                  }}
                />
                &nbsp; I have read and agreed to &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openTermsAndConditions()}
                >
                  Terms of Service
                </Link>
                &nbsp; and &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openPrivatePolicy()}
                >
                  Privacy Policy
                </Link>
              </Typography>
              {/* <Typography color={"#ff0000"}>
                {termsReadError &&
                  "*You must read the terms and conditions & privacy policy"}
              </Typography> */}
            </Grid>

            {/* Error Message */}
            {errorMessage !== "" && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="error">
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  // style={{ backgroundColor: "#01552f" }}
                  color="primary"
                  disabled={
                    !signupActive ||
                    firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    password === "" ||
                    passwordConfirm === "" ||
                    // password !== passwordConfirm ||
                    streetAddress === "" ||
                    city === "" ||
                    province === "" ||
                    postalCode === ""
                  }
                >
                  Sign Up
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
        {/* Open Terms and Conditions */}
        <Modal
          title="Terms And Conditions"
          width="700px"
          open={isOpenTerms}
          onCancel={closeTermsAndConditions}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={closeTermsAndConditions}
            >
              Okay
            </Button>,
          ]}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
        >
          {isOpenTerms && (
            <TermsAndConditions
              signIn={true}
              openPolicy={() => {
                closeTermsAndConditions();
                openPrivatePolicy();
              }}
            />
          )}
        </Modal>

        {/* Open Private Policy */}
        <Modal
          title="Privacy Policy"
          width="700px"
          open={isOpenPolicy}
          onCancel={closePrivatePolicy}
          footer={[
            <Button key="submit" type="primary" onClick={closePrivatePolicy}>
              Okay
            </Button>,
          ]}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
        >
          {isOpenPolicy && <PrivacyPolicy signIn={true} />}
        </Modal>
      </>
    </LoadingSpinner>
  );
};

export default AuthRegister;
