"use client";
import { useCallback, useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  MapCameraChangedEvent,
  MapCameraProps,
} from "@vis.gl/react-google-maps";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useUserState } from "../../../Context/UserContext";

export default function GoogleMapDisplay(props) {
  console.log(props, "GOOGLE MAPS PROPS");
  const { user } = useUserState();
  console.log(user);
  const [showInfo, setShowInfo] = useState(false);

  const [currentClient, setCurrentClient] = useState("");
  const [clientInfo, setClientInfo] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState(false);
  const [currentJob, setCurrentJob] = useState("");
  const [jobInfo, setJobInfo] = useState(false);

  const INITIAL_CAMERA = {
    center: { lat: 48.9514, lng: -57.9418 },
    zoom: 12,
  };

  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const handleCameraChange = useCallback((ev) => setCameraProps(ev.detail));

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}>
      <div style={{ height: "100%", width: "40vw" }}>
        <Map
          {...cameraProps}
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
          onCameraChanged={handleCameraChange}
        >
          {!props.clientListDisplay &&
            !props.teamListDisplay &&
            typeof props.currentJRs[0]?.location !== "undefined" &&
            props.currentJRs.length > 0 &&
            props.currentJRs.map((jr) => (
              // console.log(jr.location[0], "JR DATA"),
              <AdvancedMarker
                position={{ lat: jr?.location[0], lng: jr?.location[1] }}
                onClick={() => {
                  setJobInfo(true);
                  setCurrentJob(jr.job_id);
                }}
              >
                <Pin
                  background={"#017a46"}
                  borderColor={"#01522f"}
                  glyphColor={"#77c7a2"}
                />
                {jobInfo && jr.job_id === currentJob && (
                  <InfoWindow
                    className="infoWindow"
                    style={{ scrollbarWidth: "0px !important" }}
                    position={{ lat: jr.location[0], lng: jr.location[1] }}
                    onCloseClick={() => {
                      setJobInfo(false);
                      setCurrentJob("");
                    }}
                    maxWidth={300}
                    contentStyle={{ height: "fit-content" }}
                    headerContent={`JR ID: ${jr.job_id}`}
                  >
                    <Stack padding={"0 1rem 1rem 1rem"}>
                      <Typography variant="p">{jr.job_description}</Typography>
                      <br />
                      <Typography variant="p">{jr.clientName}</Typography>
                      <br />
                      <Typography variant="p">{jr.address}</Typography>
                    </Stack>
                  </InfoWindow>
                )}
              </AdvancedMarker>
            ))}

          {props.teamListDisplay &&
            props.mapEmployees.length > 0 &&
            props.mapEmployees.map(
              (employee) =>
                employee.lat !== null &&
                employee.lon !== null && (
                  <AdvancedMarker
                    key={`${employee.employee_id}emp`}
                    position={{ lat: employee.lat, lng: employee.lon }}
                    onClick={() => {
                      setEmployeeInfo(true);
                      setCurrentEmployee(employee.employee_user_id);
                    }}
                  >
                    <Stack
                      alignItems="center"
                      spacing={0}
                      sx={{ position: "relative" }}
                    >
                      {/* Pin Head (Avatar) */}
                      {employee.profile_pic === "avatar_default.png" ? (
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            zIndex: 10,
                            border: "2px solid #01522f",
                          }}
                        >
                          {employee.first_name[0]}
                          {employee.last_name[0]}
                        </Avatar>
                      ) : (
                        // <img
                        //   src={employee.profile_pic}
                        //   width={32}
                        //   height={32}
                        // />
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            zIndex: 10,
                            border: "2px solid #01522f",
                          }}
                          src={employee.profile_pic}
                        />
                      )}

                      {/* Pin Tail (Triangle) */}
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          overflow: "hidden",
                          marginTop: "-5px",
                          zIndex: 5,
                        }}
                      >
                        <svg width="24" height="13" viewBox="0 0 24 13">
                          <path
                            d="M 0 0 C 0 0 12 5 12 13 C 12 5 24 0 24 0 C 16 0 8 0 0 0"
                            fill="#01522f"
                          />
                        </svg>
                      </Box>
                    </Stack>
                    {employeeInfo &&
                      employee.employee_user_id === currentEmployee && (
                        <InfoWindow
                          position={{ lat: employee.lat, lng: employee.lon }}
                          onCloseClick={() => {
                            setClientInfo(false);
                            setCurrentEmployee("");
                          }}
                          // headerContent={`${employee.first_name} ${employee.last_name}`}
                          maxWidth={300}
                          contentStyle={{ height: "fit-content" }}
                        >
                          {/* <Typography></Typography> */}
                          <Stack
                            padding={"0 1rem 1rem 1rem"}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            {employee.profile_pic === "avatar_default.png" ? (
                              <Avatar
                                sx={{
                                  width: 40,
                                  height: 40,
                                  zIndex: 10,
                                  border: "2px solid #01522f",
                                }}
                              >
                                {employee.first_name[0]}
                                {employee.last_name[0]}
                              </Avatar>
                            ) : (
                              // <img
                              //   src={employee.profile_pic}
                              //   width={32}
                              //   height={32}
                              // />
                              <Avatar
                                sx={{
                                  width: 40,
                                  height: 40,
                                  zIndex: 10,
                                  border: "2px solid #01522f",
                                }}
                                src={employee.profile_pic}
                              />
                            )}
                            <Typography variant="h4">
                              {employee.first_name} {employee.last_name}
                            </Typography>
                          </Stack>
                        </InfoWindow>
                      )}
                  </AdvancedMarker>
                )
            )}

          {props.clientListDisplay &&
            props.clients.length > 0 &&
            props.clients.map(
              (client) => (
                console.log(client, "client DATA"),
                client.lat !== null && client.lon !== null && (
                  <AdvancedMarker
                    key={`${client.id}client`}
                    position={{ lat: client.lat, lng: client.lon }}
                    onClick={() => {
                      setClientInfo(true);
                      setCurrentClient(client.id);
                    }}
                  >
                    <Stack
                      alignItems="center"
                      spacing={0}
                      sx={{ position: "relative" }}
                    >
                      {/* Pin Head (Avatar) */}
                      {client.profile_pic === "avatar_default.png" ||
                      client.profile_pic.includes("avatar_default.png") ? (
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            zIndex: 10,
                            border: "2px solid #01522f",
                          }}
                        >
                          {client.first_name !== null
                            ? client.first_name[0]
                            : ""}
                          {client.last_name !== null ? client.last_name[0] : ""}
                        </Avatar>
                      ) : (
                        // <img
                        //   src={employee.profile_pic}
                        //   width={32}
                        //   height={32}
                        // />
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            zIndex: 10,
                            border: "2px solid #01522f",
                          }}
                          src={client.profile_pic}
                        />
                      )}

                      {/* Pin Tail (Triangle) */}
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          overflow: "hidden",
                          marginTop: "-5px",
                          zIndex: 5,
                        }}
                      >
                        <svg width="24" height="13" viewBox="0 0 24 13">
                          <path
                            d="M 0 0 C 0 0 12 5 12 13 C 12 5 24 0 24 0 C 16 0 8 0 0 0"
                            fill="#01522f"
                          />
                        </svg>
                      </Box>
                    </Stack>

                    {clientInfo && client.id === currentClient && (
                      <InfoWindow
                        position={{ lat: client.lat, lng: client.lon }}
                        onCloseClick={() => {
                          setClientInfo(false);
                          setCurrentClient("");
                        }}
                        // headerContent={`${client.first_name} ${client.last_name}`}
                        maxWidth={300}
                        contentStyle={{ height: "fit-content" }}
                      >
                        <Stack
                          padding={"0 1rem 1rem 1rem"}
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          {client.profile_pic === "avatar_default.png" ||
                          client.profile_pic.includes("avatar_default.png") ? (
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                                zIndex: 10,
                                border: "2px solid #01522f",
                              }}
                            >
                              {client.first_name[0]}
                              {client.last_name[0]}
                            </Avatar>
                          ) : (
                            // <img
                            //   src={employee.profile_pic}
                            //   width={32}
                            //   height={32}
                            // />
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                                zIndex: 10,
                                border: "2px solid #01522f",
                              }}
                              src={client.profile_pic}
                            />
                          )}
                          <Typography variant="h4">
                            {client.first_name} {client.last_name}
                          </Typography>
                        </Stack>
                        {/* <Typography></Typography> */}
                      </InfoWindow>
                    )}
                  </AdvancedMarker>
                )
              )
            )}
        </Map>
      </div>
    </APIProvider>
  );
}
