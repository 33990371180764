import React, { useState, useEffect } from "react";
import { useUserState } from "../../../../Context/UserContext";
import axios from "axios";
import "./request.css";

import {
  Button,
  Grid,
  Select,
  OutlinedInput,
  Stack,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import { Modal } from "antd";

import SetClientAvailability from "../Availability/SetClientAvailability";
import LoadScreen from "../../../../Components/LoadScreen";

function JobRequest(
  props = {
    paymentMethod: false,
    displayRequestForm: false,
    onClose: () => {},
    displaySubmitAlert: false,
    editForm: true,
    jrData: null,
    fetchJrData: () => {},
    jrImages: [],
    fetchData: () => {},
    setJrImages: [],
  }
) {
  const { user } = useUserState();
  const [marketStatus, setMarketStatus] = useState(1);
  const [assignStatus, setAssignStatus] = useState(0);
  const [category, setCategory] = useState(
    props.jrData && typeof props.jrData !== "undefined"
      ? props.jrData.job_category
      : null
  );
  const [shortDesc, setShortDesc] = useState(
    props.jrData && typeof props.jrData !== "undefined"
      ? props.jrData.job_title
      : ""
  );

  const [longDesc, setLongDesc] = useState(
    props.jrData && typeof props.jrData !== "undefined"
      ? props.jrData.job_description
      : ""
  );
  const [previousVendor, setPreviousVendor] = useState("");
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [property, setProperty] = useState(0);
  const [availabilityArray, setAvailabilityArray] = useState(false);

  const [fetchingVendors, setFetchingVendors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);

  const [imageDeleteList, setImageDeleteList] = useState([]);

  const [properties, setProperties] = useState([]);
  useEffect(() => {
    axios.post("/property/getPropertyDetails").then((res) => {
      setProperties(res.data.property);
      res.data.property.forEach((prop, index) => {
        if (user.street === prop.street && user.city === prop.city) {
          setProperty(index);
        }
      });
    });
  }, [user.city, user.street]);

  const getFilteredImages = () => {
    return props.jrImages.filter((image) => {
      let notFound = true;
      for (let dImage of imageDeleteList) {
        if (dImage.jobrequest_images_id === image.jobrequest_images_id) {
          notFound = false;
        }
      }
      return notFound;
    });
  };

  const checkSubmit = () => {
    if (!props.editForm) {
      if (
        property === "selectProperty" ||
        !shortDesc ||
        !longDesc ||
        marketStatus + assignStatus === 0 ||
        !availabilityArray
      ) {
        setFormError(true);
        return;
      }
      setSubmitting(true);
      onSubmit();
    } else {
      setSubmitting(true);
      onUpdate();
    }
  };

  const onSubmit = async () => {
    const finishSubmit = () => {
      props.displaySubmitAlert();
      props.onClose();
    };

    let generatedCategory = "";

    if (category === null) {
      const prompt = {
        prompt: longDesc,
      };

      const categoryResponse = await axios.post(
        "/api/categorySelection",
        prompt
      );
      generatedCategory = categoryResponse.data;
      setCategory(categoryResponse.data);
    }

    const formObject = {
      propertyId: properties[property].property_id,
      jobTitle: shortDesc,
      category: generatedCategory !== "" ? generatedCategory : category,
      jobDescription: longDesc,
      directAssigned: previousVendor ? 1 : 0,
      sendMarket: marketStatus,
      activityDesc: `Created job request "${shortDesc}.`,
      userName: `${user.first_name} ${user.last_name}`,
      taskArray: JSON.stringify(availabilityArray),
      vendorId: previousVendor || null,
    };

    axios.post("/jobRequest/newJobRequest", formObject).then((response) => {
      let jobId = response.data.jobId;
      if (response.data.ok) {
        if (attachments.length > 0) {
          let form = new FormData();

          for (let attachment of attachments) {
            form.append("file", attachment);
          }
          form.append("job_id", jobId);

          axios.post("/jobRequest/addImages", form).then((res) => {
            finishSubmit();
          });
        } else {
          finishSubmit();
        }
      }
    });
  };

  const onUpdate = () => {
    const finishSubmit = () => {
      props.displaySubmitAlert();
      props.onClose();
    };
    const formObject = {
      jobTitle: shortDesc,
      category: category,
      jobDescription: longDesc,
      directAssigned: previousVendor,
      sendMarket: marketStatus,
      jobID: props.jrData.job_id,
      imageDeleteList: imageDeleteList,
    };

    axios.post("/jobRequest/updateJob", formObject).then((response) => {
      let jobId = props.jrData.job_id;
      if (response.data.ok) {
        if (attachments.length > 0) {
          let form = new FormData();

          for (let attachment of attachments) {
            form.append("file", attachment);
          }
          form.append("job_id", jobId);

          axios.post("/jobRequest/addImages", form).then((res) => {
            finishSubmit();
            props.fetchJrData();
            props.fetchData();
          });
        } else {
          finishSubmit();
          props.fetchJrData();
          props.fetchData();
        }
      }
    });
  };

  function importFile() {
    const newArray = [...attachments];
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(newArray);
    };
    input.click();
  }

  // removes Attachment from new attachment array
  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(newArray);
    }
  };

  const createAttachmentDisplay = (attachments, jrImages) => {
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }

    return (
      <>
        <div>
          <p>{props.editForm ? "New " : ""}Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };
  const [vendor, setVendor] = useState(null);
  const [directAssignList, setDirectAssignList] = useState(null);

  const fetchDirectAssignList = async () => {
    const prompt = {
      prompt: longDesc,
    };

    const categoryResponse = await axios.post("/api/categorySelection", prompt);

    if (
      categoryResponse.statusText === "OK" ||
      categoryResponse.status === 200
    ) {
      await axios
        .post("/getAssignedVendorDetails", { category: categoryResponse.data })
        .then((res) => {
          console.log(res.data.vendorDetails);
          if (res.data.vendorDetails.length > 0) {
            if (res.data.vendorDetails.length === 1) {
              setPreviousVendor(res.data.vendorDetails[0].user_id);
            }
            setCategory(categoryResponse.data);
            setDirectAssignList(res.data.vendorDetails);
            setMarketStatus(0);
            setAssignStatus(1);
            setFetchingVendors(false);
          } else {
            setCategory(categoryResponse.data);
            setDirectAssignList(res.data.vendorDetails);
            setMarketStatus(1);
            setAssignStatus(0);
            setFetchingVendors(false);
          }
        });
    }
  };
  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <Modal
      destroyOnClose={true}
      width="700px"
      title="I need help with..."
      open={props.displayRequestForm}
      onCancel={props.onClose}
      footer={false}
    >
      <>
        <form style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={1}>
            {!props.editForm && (
              <Grid item xs={12} md={12}>
                <Stack>
                  <Select
                    id="property"
                    name="property"
                    value={property}
                    onChange={(e) => {
                      setProperty(e.target.value);
                    }}
                  >
                    {properties.map((property, index) => (
                      <MenuItem key={property.property_id} value={index}>
                        {property.address}
                      </MenuItem>
                    ))}
                  </Select>
                  {formError && property === "selectProperty" && (
                    <Typography color="#FF0000">
                      * Please select Property.
                    </Typography>
                  )}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Stack>
                <OutlinedInput
                  required
                  id="shortDesc"
                  name="shortDesc"
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                  placeholder="What is the issue?"
                />
              </Stack>
              {formError && !shortDesc && (
                <Typography color="#FF0000">* Required Field.</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack>
                <OutlinedInput
                  multiline
                  rows={5}
                  required
                  id="longDesc"
                  name="longDesc"
                  value={longDesc}
                  inputProps={{ maxLength: 500 }}
                  onChange={(e) => {
                    setLongDesc(e.target.value);
                    setDirectAssignList(null);
                  }}
                  placeholder={"Provide detailed information about the issue"}
                />
                <Typography
                  variant="p"
                  color={longDesc.length === 500 ? "red" : "black"}
                >
                  {longDesc.length}/500
                </Typography>
              </Stack>

              {formError && !longDesc && (
                <Typography color="#FF0000">* Required Field.</Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {formError && (
                <Typography color="#FF0000">
                  * Choose how to assign your job request.
                </Typography>
              )}

              {!props.editForm &&
                (directAssignList !== null ? (
                  directAssignList?.length > 0 ? (
                    directAssignList.length > 1 ? (
                      <Stack width={"100%"} justifyContent={"center"}>
                        <Typography textAlign={"center"}>
                          Please select a vendor to send your job to
                        </Typography>
                        <Select
                          id="vendor"
                          name="vendor"
                          value={previousVendor}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setPreviousVendor(e.target.value);
                          }}
                        >
                          {directAssignList.map((vendor) => (
                            <MenuItem
                              key={vendor.user_id}
                              value={vendor.user_id}
                            >
                              {vendor.vendor_legal_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    ) : (
                      <Stack width={"100%"} justifyContent={"center"}>
                        {/* {setPreviousVendor(directAssignList[0].user_id)} */}
                        <Typography textAlign={"center"}>
                          Your Job will be sent directly to{" "}
                          <strong>
                            <i>{directAssignList[0].vendor_legal_name}</i>
                          </strong>{" "}
                          Vendor.
                        </Typography>
                      </Stack>
                    )
                  ) : (
                    <Stack width={"100%"} justifyContent={"center"}>
                      <Typography textAlign={"center"}>
                        No Vendors Available, your job will be sent to the
                        market!
                      </Typography>
                    </Stack>
                  )
                ) : (
                  <Stack>
                    <Button
                      fullWidth
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        fetchDirectAssignList();
                        console.log("Assign vendor click");
                        setFetchingVendors(true);
                      }}
                      disabled={longDesc === "" ? true : false}
                    >
                      Assign your vendor now!
                    </Button>
                  </Stack>
                ))}
            </Grid>
            {fetchingVendors && (
              <Grid item xs={12}>
                <Stack
                  width="100%"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <LoadScreen small={true} />
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Stack>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={importFile}
                  id="document"
                  name="document"
                  fullWidth
                >
                  Add Pictures and Documents
                </Button>

                {props.jrImages && props.jrImages.length > 0 && (
                  <div className="attachments">
                    {console.log(props.jrData)}
                    {getFilteredImages().map((image, index) => (
                      <div
                        key={index}
                        className="attachment"
                        onClick={() => {
                          setImageDeleteList([...imageDeleteList, image]);
                          console.log(image);
                        }}
                      >
                        <img key={index} src={image.image} alt="" />
                      </div>
                    ))}
                  </div>
                )}
                {attachments !== undefined && attachments.length !== 0 && (
                  <div className="attachments">
                    {createAttachmentDisplay(attachments)}
                  </div>
                )}
              </Stack>
            </Grid>

            {!props.editForm && (
              <Grid item xs={12} md={6}>
                <Stack direction="column" width="100%" alignItems="center">
                  {formError && !availabilityArray && (
                    <Typography width="100%" color="#FF0000">
                      * Please provide the times that you are available.
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAvailabilityModal(true);
                    }}
                  >
                    Pick a Time
                  </Button>
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              <Stack
                direction="row"
                width="100%"
                justifyContent={mobileView ? "center" : "flex-end"}
              >
                {submitting && (
                  <Typography width="100%" color="#767676">
                    {!props.editForm ? "Submitting..." : "Updating..."}
                  </Typography>
                )}
                <AnimateButton>
                  <Button
                    disabled={submitting}
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.onClose();
                    }}
                    sx={{ ml: 2, float: mobileView ? "none" : "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button
                    disabled={
                      submitting ||
                      longDesc === "" ||
                      shortDesc === "" ||
                      category === "" ||
                      availabilityArray === null
                    }
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      checkSubmit();
                    }}
                    sx={{ ml: 2, float: mobileView ? "none" : "right" }}
                  >
                    {!props.editForm ? "Submit" : "Update"}
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
        {availabilityModal && (
          <SetClientAvailability
            jrData={props.jrData}
            onClose={() => setAvailabilityModal(false)}
            setAvailabilityArray={setAvailabilityArray}
            availabilityArray={availabilityArray}
          />
        )}
      </>
    </Modal>
  );
}

JobRequest.defaultProps = {
  property: "selectProperty",
  category: "selectCategory",
  shortDesc: "",
  longDesc: "",
  previousVendor: "",

  paymentMethod: false,
  displayRequestForm: false,
  onClose: () => {},
  displaySubmitAlert: false,
  editForm: true,
  jrData: null,
  fetchJrData: () => {},
  jrImages: [],
  fetchData: () => {},
  setJrImages: [],
};

export default JobRequest;
