import { Button, Grid, Stack, useMediaQuery } from "@mui/material";
import { Image, Modal, Typography } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import BidDetails from "../Bids/BidDetails";

import ScopeDetails from "../Scope/ScopeDetails";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import EditScopeForm from "../Scope/EditScopeForm";
import ScopeTable from "../Scope/ScopeTable";

import { useUserState } from "../../../../Context/UserContext";
import LoadScreen from "../../../../Components/LoadScreen";
import { FilePdfTwoTone } from "@ant-design/icons";
import Video from "../../API/Video";
import AddImageModal from "../ClientPhases/AddImageModal";

const ExpandedTilePhase1 = (props) => {
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [imageSubmitAlert, setImageSubmitAlert] = useState(false);

  useEffect(() => {
    if (imageSubmitAlert) {
      const timeoutId = setTimeout(() => {
        setImageSubmitAlert(false);
      }, 5000); // Adjust the timeout duration (in milliseconds) as needed

      return () => clearTimeout(timeoutId); // Clear timeout when component unmounts or alert is hidden
    }
  }, [imageSubmitAlert]);

  const { user } = useUserState();

  const [showBidDetailsModal, setShowBidDetailsModal] = useState(false);
  const [showAcceptBidModal, setShowAcceptBidModal] = useState(false);
  const [cancelBidAlert, setCancelBidAlert] = useState(false);
  const [openScopeModal, setOpenScopeModal] = useState(false);
  const [editScopeModal, setEditScopeModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });
  const [currentScope, setCurrentScope] = useState(props.scopeDetails[index]);

  const setBidAsViewed = useCallback(() => {
    axios.post("/notifications/bidViewed", { jobID: props.jrData.job_id });
  }, [props.jrData.job_id]);

  const fetchBidDetails = useCallback(() => {
    setBidAsViewed();
  }, [setBidAsViewed]);
  useEffect(() => {
    setCurrentScope(props.scopeDetails[index]);
  }, [
    chargePercentages.clientPercentage,
    chargePercentages.vendorPercentage,
    index,
    props.scopeDetails,
    user.vendorMode,
  ]);
  useEffect(() => {
    axios.get("/finance/getChargePercentages").then((response) => {
      setChargePercentages(
        props.jrData.is_send_to_market === 1
          ? response.data.marketPercentages
          : response.data.crmPercentages
      );
    });
  }, []);

  // const newChat = () => {
  //   axios
  //     .post("/communication/createConversation", {
  //       UserId: props.jrData.client_id,
  //       JobId: props.jrData.job_id,
  //       ClientName: props.jrData.clientName
  //     })
  //     .then((res) => {
  //       navigate(`/dashboard/chat/${res.data.chatId}`);
  //     });
  // };
  const cancelBidConfirm = () => {
    axios
      .post("/bidding/cancelBids", {
        bidId: props.bidDetails[0].bid_id,
        requestedJobId: props.bidDetails[0].requested_job_id,
      })
      .then((res) => {
        window.location.reload();
      });
  };

  useEffect(() => {
    fetchBidDetails();
  }, [fetchBidDetails]);

  const mobileView = useMediaQuery("(max-width: 600px)");

  // console.log(props.fromChat);

  const acceptBid = () => {
    const bid = props.bidDetails[0];
    let bidObject = {
      bidId: bid.bid_id,
      bidVendorId: bid.bid_vendor_id,
      requestedJobId: bid.requested_job_id,
      workToBeDone: bid.work_to_be_done,
      labourCharge: bid.labour_charge,
      estimatedHours: bid.estimated_hours,
      estimatedMaterials: bid.estimated_materials,
      bidAmount: bid.bid_amount,
      bidDate: bid.bid_date,
      notes: bid.notes,
      host: window.location.host,
    };

    axios
      .post("/bidding/acceptBid", {
        bidId: bidObject.bidId,
        jobId: bidObject.requestedJobId,
        activityDesc: `Accepted bid for $${bidObject.bidAmount.toFixed(2)}`,
        userName: `${user.first_name} ${user.last_name}`,
        host: window.location.host,
      })
      .then((res) => {
        if (res.data.ok) {
          axios
            .post("/bidding/closeBids", {
              bidId: bidObject.bidId,
              requestedJobId: bidObject.requestedJobId,
              AssignedVendorId: user.id,
            })
            .then((res) => {
              props.fetchData();
              props.fetchJrData();
              setShowAcceptBidModal(false);
            });
        } else {
          console.error("Error: accept bid failed");
        }
      });
  };
  return (
    <>
      {props.jrImages !== "" && (
        <Grid container spacing={1} width={"100%"} margin={"1rem 0 0 0"}>
          <Stack spacing={1} direction={"column"} width={"100%"}>
            <Grid item sm={12} md={8}>
              <Stack spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Problem Description</Typography>
                </Stack>
                <Typography
                  style={{
                    width: "350px",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  {props.jrData.job_description}
                </Typography>
                <Typography className="title">
                  Images / Documents
                  {props.jrData.created_vendor_id !== null?(
                    <Button
                      variant="contained"
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => setShowImageUploadModal(true)}
                    >
                      Add
                    </Button>
                  ):null}
                </Typography>
                <Stack spacing={1} direction={"row"}>
                  {props.jrImages.map((media, i) => {
                    // Extract file extension from the source URL

                    const fileExtension = media.imageurl
                      .split(".")
                      .pop()
                      .toLowerCase();

                    // Check if it's an image based on the file extension
                    const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                      fileExtension
                    );

                    if (isImage) {
                      return (
                        <Image
                          key={media.jobrequest_images_id}
                          width={80}
                          src={media.image}
                        />
                      );
                    } else {
                      return (
                        <Video
                          key={media.jobrequest_images_id}
                          src={media.image}
                          width={80}
                        />
                      );
                    }
                  })}
                </Stack>

                {showImageUploadModal && (
                  <AddImageModal
                    onClose={() => setShowImageUploadModal(false)}
                    showImageUploadModal={showImageUploadModal}
                    setImageSubmitAlert={setImageSubmitAlert}
                    jrData={props.jrData}
                    fetchData={props.fetchData}
                    fetchJrData={props.fetchJrData}
                  />
                )}
              </Stack>
            </Grid>
            {!props.bidDetails ? (
              <LoadScreen />
            ) : (props.bidDetails === false ||
                props.bidDetails?.length === 0) &&
              user.empRole === ("Admin" || "Manager") ? (
              <div className={"btnContainer"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    props.setShowSubmitForm(true);
                    props.fetchData();
                  }}
                >
                  Submit Bid
                </Button>
              </div>
            ) : (
              props.bidDetails?.length > 0 &&
              (props.bidDetails[0].is_accepted === 0 &&
              props.bidDetails[0].is_closed === 0 ? (
                <div>
                  {props.bidDetails[0].is_cancelled === 0 ? (
                    <>
                      <h1 style={{ marginTop: "1rem" }}>
                        <em>Waiting for client to respond to your bid.</em>
                      </h1>
                      <Stack direction={"row"} gap={"1rem"}>
                        {props.jrData.created_vendor_id !== null?(
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowAcceptBidModal(true);
                            }}
                          >
                            Accept Bid
                          </Button>
                        ):null}
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setShowBidDetailsModal(true);
                          }}
                        >
                          View Bid
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <h1 style={{ marginTop: "1rem" }}>
                      <em>You cancelled your bid.</em>
                    </h1>
                  )}
                </div>
              ) : !props.bidDetails?.is_accepted ? (
                !mobileView ? (
                  <>
                    {/* <Stack direction={"row"} justifyContent={"space-between"}>
                      {!props.fromChat ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            newChat();
                          }}
                        >
                          Chat
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpenScopeModal(true);
                        }}
                      >
                        Scope
                      </Button>
                      {props.bidDetails[0]?.is_accepted === 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            props.setAgreementOpen(true);
                          }}
                        >
                          View Agreement
                        </Button>
                      )}
                    </Stack> */}
                    {props.scopeDetails !== "" &&
                      props.scopeDetails.length > 0 && (
                        <>
                          <Grid container spacing={1}>
                            {(user.empRole === "Admin" ||
                              user.empRole === "Manager") && (
                              <Grid item xs={12}>
                                <Stack spacing={1} direction={"row"}>
                                  <Typography className="title">
                                    Service Agreement :{" "}
                                    <FilePdfTwoTone
                                      className="icon-size"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.setAgreementOpen(true);
                                      }}
                                    />
                                  </Typography>
                                </Stack>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              {(user.empRole === "Admin" ||
                                user.empRole === "Manager") &&
                                props.scopeDetails?.map((item, i) => (
                                  <Stack spacing={1} direction={"row"}>
                                    <Grid item sm={12} md={8}>
                                      <Stack>
                                        <Typography className="title">
                                          Work to be done:
                                        </Typography>

                                        <Typography
                                          style={{
                                            width: "350px",
                                            overflowWrap: "break-word",
                                            wordWrap: "break-word",
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.work_to_be_done}
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <Typography className="title">
                                          Notes:{" "}
                                        </Typography>
                                        <Typography
                                          style={{
                                            width: "350px",
                                            overflowWrap: "break-word",
                                            wordWrap: "break-word",
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.notes}
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <Typography className="title">
                                          Charges:
                                        </Typography>
                                        <Grid item xs={12} marginTop="1em">
                                          <ScopeTable
                                            lines={currentScope?.scopeLines}
                                            amount={currentScope?.scope_amount}
                                            chargePercentages={
                                              props.jrData.is_send_to_market === 1
                                                ? props.chargePercentages
                                                    .marketPercentages
                                                : props.chargePercentages
                                                    .crmPercentages
                                            }
                                          />
                                          <hr style={{ marginTop: "1em" }} />
                                        </Grid>
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                ))}
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {props.scopeDetails !== "" &&
                      props.scopeDetails.length > 0 && (
                        <>
                          <Grid container spacing={1}>
                            {(user.empRole === "Admin" ||
                              user.empRole === "Manager") && (
                              <Grid item xs={12}>
                                <Stack spacing={1} direction={"row"}>
                                  <Typography className="title">
                                    Service Agreement :{" "}
                                    <FilePdfTwoTone
                                      className="icon-size"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.setAgreementOpen(true);
                                      }}
                                    />
                                  </Typography>
                                </Stack>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              {(user.empRole === "Admin" ||
                                user.empRole === "Manager") &&
                                props.scopeDetails?.map((item, i) => (
                                  <Stack spacing={1} direction={"row"}>
                                    <Grid item sm={12} md={8}>
                                      <Stack>
                                        <Typography className="title">
                                          Work to be done:
                                        </Typography>

                                        <Typography
                                          style={{
                                            width: "350px",
                                            overflowWrap: "break-word",
                                            wordWrap: "break-word",
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.work_to_be_done}
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <Typography className="title">
                                          Notes:{" "}
                                        </Typography>
                                        <Typography
                                          style={{
                                            width: "350px",
                                            overflowWrap: "break-word",
                                            wordWrap: "break-word",
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.notes}
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <Typography className="title">
                                          Charges:
                                        </Typography>
                                        <Grid item xs={12} marginTop="1em">
                                          <ScopeTable
                                            lines={currentScope.scopeLines}
                                            amount={currentScope.scope_amount}
                                            chargePercentages={
                                              props.jrData.is_send_to_market === 1
                                                ? props.chargePercentages
                                                    .marketPercentages
                                                : props.chargePercentages
                                                    .crmPercentages
                                            }
                                          />
                                          <hr style={{ marginTop: "1em" }} />
                                        </Grid>
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                ))}
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </>
                )
              ) : (
                <div>
                  <h3 style={{ marginTop: "1rem" }}>
                    <em>
                      Your bid has been{" "}
                      <b style={{ color: "red" }}>rejected.</b>
                    </em>
                  </h3>
                </div>
              ))
            )}
          </Stack>
        </Grid>
      )}

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Edit Scope"
        open={editScopeModal}
        onCancel={() => {
          setEditScopeModal(false);
        }}
        footer={false}
      >
        {editScopeModal && (
          <EditScopeForm
            fetchData={props.fetchData}
            openJR={props.jrData}
            setEditScopeModal={setEditScopeModal}
          />
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Scope of Work"
        open={openScopeModal}
        onCancel={() => {
          setOpenScopeModal(false);
        }}
        footer={false}
      >
        <Grid container spacing={1}>
          <ScopeDetails
            jrData={props.jrData}
            scopeDetails={props.scopeDetails}
          />
          
          <Grid item xs={12}>
            {props.jrData.job_status !== "Reviewing" &&
              props.jrData.job_status !== "Finalizing" && (
                <AnimateButton>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    sx={{ ml: 2, float: "right" }}
                    onClick={() => setEditScopeModal(true)}
                  >
                    Change Scope
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ ml: 2, float: "right" }}
                    onClick={() => setOpenScopeModal(false)}
                    footer={false}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
              )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Accept Bid"
        open={showAcceptBidModal}
        onCancel={() => setShowAcceptBidModal(false)}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <Typography>
                Are you sure you want to accept this bid on behalf of{" "}
                <stront>{props.jrData.clientName}</stront>?
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    acceptBid();
                  }}
                  sx={{ ml: 2, float: "right" }}
                >
                  Accept
                </Button>
                <Button
                  size="sm"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowAcceptBidModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Bid Details"
        open={showBidDetailsModal}
        onCancel={() => setShowBidDetailsModal(false)}
        footer={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setCancelBidAlert(true);
            }}
          >
            Cancel Bid
          </Button>
        }
      >
        <BidDetails
          chargePercentages={
            props.jrData.is_send_to_market === 1
              ? props.chargePercentages.marketPercentages
              : props.chargePercentages.crmPercentages
          }
          bidDetails={props.bidDetails[0]}
          setShowBidDetailsModal={setShowBidDetailsModal}
          showBidDetailsModal={showBidDetailsModal}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        open={cancelBidAlert}
        onCancel={() => setCancelBidAlert(false)}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={cancelBidConfirm}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 4 }}
              onClick={() => {
                setCancelBidAlert(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      >
        Are you sure to cancel bid?
      </Modal>
    </>
  );
};

export default ExpandedTilePhase1;
