import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { Modal, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import addButton from "../../../../images/plus.png";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import moment from "moment-timezone";

import ClientEmployeeAvailability from "../Availability/ClientEmployeeAvailability";
import AddTroubleshooting from "../TroubleShoot/AddTroubleshooting";
import AddAction from "../Actions/AddAction";
import { localDate } from "../../util";
moment.tz.setDefault("Canada/Newfoundland");

function AddEvent(props) {
  const { user } = useUserState();
  const [showTextbox, setShowTextbox] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [text, setText] = useState("");
  const [employee, setEmployee] = useState("");
  const [employees, setEmployees] = useState([]);
  const [newEvent, setNewEvent] = useState();
  const [currentId, setCurrentId] = useState();
  const [currentEmployeeId, setCurrentEmployeeId] = useState();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [events, setEvents] = useState(props.events);

  console.log(props.jr, "IUBVUYSDBFYUIBUHVCB");
  const handleSubmit = (body) => {
    let employeeName = employee.first_name + " " + employee.last_name;

    const formObject = {
      JobId: props.jr.job_id,
      Title: text,
      StartDate: new Date(events.StartDate).toISOString(),
      StartTime: new Date(events.StartTime).toISOString(),
      EndTime: new Date(events.EndTime).toISOString(),
      AssignedTo: employeeName,
      eventAddress: props.jr.address,
      firstName: employee.first_name,
      creationDate: new Date().toISOString(),
      activityDesc: `Created event "${text}"`,
      userName: `${user.first_name} ${user.last_name}`,
      clientName: props.jr.clientName,
      vendorName: props.jr.vendor_legal_name,
      jobTitle: props.jr.job_title,
      requesterId: props.jr.requester_id,
      employeeId: employee.employee_id,
    };

    console.log(employee, "EVENT EMPOLOYEEEbjvdsbhfbjs");
    axios.post("/events/addEvents", formObject).then((res) => {
      setShowTextbox(false);
      props.fetchData();
    });
  };

  const handleUpdate = () => {
    const formObject = {
      newStartDate: new Date(events.StartDate).toISOString(),
      newStartTime: new Date(events.StartTime).toISOString(),
      assignedTo:
        employee === "" ? props.events[currentId].assigned_to : employee,
      eventId: props.events[currentId].event_id,
      JobId: props.jr.job_id,
      activityDesc: `Edited event ${props.events[currentId].title}`,
      userName: `${user.first_name} ${user.last_name}`,
    };
    axios.post("/events/updatedEvent", formObject).then((res) => {
      setShowTextbox(false);
      props.fetchData();
    });
  };

  const handleEditEvent = () => {
    setShowTextbox(true);
  };

  const handleDeleteEvent = () => {
    setShowDeleteModal(true);
  };

  const handleCompleteEvent = () => {
    setShowCompleteModal(true);
  };

  const handleAcceptEvent = () => {
    setShowAcceptModal(true);
  };

  const fetchEmployeeData = useCallback(() => {
    axios
      .post("/employee/getEmployees", { VendorId: user.vendor_id })
      .then(({ data }) => {
        const employees = data.employees;
        setEmployees(employees);
      });
  }, [user.vendor_id]);

  useEffect(() => {
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  const confirmRemoval = () => {
    const formObject = {
      eventId: props.events[currentId].event_id,
      JobId: props.jr.job_id,
      activityDesc: `Removed event "${props.events[currentId].title}"`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios.post("/events/terminateEvent", formObject).then(() => {
      setShowDeleteModal(false);
      props.fetchData();
    });
  };

  const confirmCompletion = () => {
    const formObject = {
      eventId: props.events[currentId].event_id,
      JobId: props.jr.job_id,
      activityDesc: `Completed event "${props.events[currentId].title}"`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios.post("/events/completeEvent", formObject).then(() => {
      props.fetchData();
      setShowCompleteModal(false);
    });
  };

  const confirmAcception = () => {
    const formObject = {
      eventId: props.events[currentId].event_id,
      JobId: props.jr.job_id,
      activityDesc: `Accepted event "${props.events[currentId].title}"`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios.post("/events/acceptEvent", formObject).then(() => {
      props.fetchData();
      setShowAcceptModal(false);
    });
  };

  const fetchEmployeeID = () => {
    axios.post("/employee/getCurrentID").then((res) => {
      setCurrentEmployeeId(res.data.employeeID);
    });
  };

  useEffect(() => {
    fetchEmployeeID();
  }, []);

  const [troubleShootingModal, setTroubleShootingModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [addTroubleshootEntryModal, setAddTroubleshootEntryModal] = useState();

  return (
    <>
      <div>
        <br></br>
        <div className="buttonContainer">
          <label className="categoryLabel" htmlFor="addButton">
            Events
          </label>

          {props.jr.job_status === "Scheduling" &&
            user.empRole === ("Admin" || "Manager") && (
              // <button
              //   className="addButtonContainer"
              //   onClick={() => {
              //     setShowTextbox(true);
              //     setEmployee("");
              //     setNewEvent(true);
              //   }}
              // >
              <Button
                type="primary"
                variant="contained"
                onClick={() => {
                  setShowTextbox(true);
                  setEmployee("");
                  setNewEvent(true);
                }}
              >
                Add Event
              </Button>
              // {/* <img
              //   id="addButton"
              //   src={addButton}
              //   alt="addButton"
              //   className="addButton"
              // /> */}
              // </button>
            )}
        </div>
        <div>
          <Modal
            destroyOnClose={true}
            width="700px"
            title={newEvent ? "Add Event" : "Update Event"}
            open={showTextbox}
            onCancel={() => setShowTextbox(false)}
            footer={false}
          >
            {showTextbox && (
              <form>
                <Grid container spacing={1}>
                  {newEvent && (
                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <InputLabel>Event Description</InputLabel>
                        <OutlinedInput
                          required
                          id="eventDescription"
                          name="eventDescription"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          placeholder="Event Description"
                        />
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel>
                        {newEvent ? "Assign Employee" : "Reassign Employee"}
                      </InputLabel>
                      <Select
                        id="assignedEmployee"
                        name="assignedEmployee"
                        onChange={(e) => {
                          setEmployee(e.target.value);
                        }}
                      >
                        {employees
                          .filter((employee) => employee.is_active === 1)
                          .map((employee) => {
                            return (
                              <MenuItem
                                key={employee.employee_id}
                                value={employee}
                              >
                                <Stack
                                  direction={"row"}
                                  spacing={3}
                                  alignItems={"center"}
                                >
                                  {employee.profile_pic ===
                                  "avatar_default.png" ? (
                                    <Avatar
                                      size={25}
                                      style={{ marginRight: "0.5rem" }}
                                    >
                                      {employee.first_name[0]}
                                    </Avatar>
                                  ) : (
                                    <Avatar
                                      style={{ marginRight: "0.5rem" }}
                                      size={25}
                                      src={employee.profile_pic}
                                    />
                                  )}
                                  {employee.first_name} {employee.last_name}
                                </Stack>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Stack>
                  </Grid>
                  {employee !== "" && (
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <ClientEmployeeAvailability
                          employee={employee}
                          jr={props.jr}
                          onSubmit={(event) => {
                            setEvents(event);
                          }}
                        />
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <Stack spacing={1}>
                      <AnimateButton>
                        {newEvent ? (
                          <Button
                            size="sm"
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleSubmit();
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleUpdate();
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Update
                          </Button>
                        )}

                        <Button
                          size="sm"
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowTextbox(false);
                          }}
                          sx={{ ml: 2, float: "right" }}
                        >
                          Cancel
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            )}
          </Modal>

          {props.events?.length > 0 ? (
            <table className="table">
              <colgroup>
                <col style={{ width: "15em" }} />
                <col />
                <col style={{ width: "15em" }} />
                <col style={{ width: "10em" }} />
              </colgroup>
              <thead>
                <th>Date</th>
                <th>Title</th>
                <th>Assigned To</th>
                <th>Status</th>
              </thead>
              <tbody>
                {props.events?.map((item, i) =>
                  item.is_terminated !== 1 ? (
                    <tr
                      className="tableRowHover"
                      style={{
                        height: "1.7rem",
                      }}
                      key={item.event_id}
                    >
                      {console.log(item)}
                      <td style={{ textAlign: "center" }} className="td">
                        {moment(
                          // localDate(
                          new Date(item.start_date).setTime(
                            new Date(item.start_time).getTime()
                            // )
                          )
                        ).format("ddd MMM DD, h:mm a")}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {item.title}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.assigned_to}
                      </td>
                      {console.log(
                        item.assigned_employee_id,
                        currentEmployeeId,
                        "idhifhsifhih"
                      )}
                      {console.log(item.employee_accepted, "ehehehe")}
                      {console.log(item.employee_accepted !== 1, "ehehehe")}
                      {item.assigned_employee_id !== currentEmployeeId &&
                        (item.completed !== 1 ? (
                          item.employee_accepted !== 1 ? (
                            <td style={{ textAlign: "center" }}>
                              <Button
                                style={{
                                  maxWidth: "0.1rem",
                                  minWidth: "0.1rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleEditEvent();
                                  setNewEvent(false);
                                }}
                              >
                                <EditOutlined />
                              </Button>

                              <Button
                                style={{
                                  maxWidth: "0.1rem",
                                  minWidth: "0.1rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleDeleteEvent();
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                            </td>
                          ) : (
                            <td style={{ textAlign: "center", color: "green" }}>
                              Employee Accepted
                            </td>
                          )
                        ) : (
                          <td style={{ textAlign: "center", color: "green" }}>
                            Event Completed
                          </td>
                        ))}
                      {item.completed !== 1 ? (
                        item.assigned_employee_id === currentEmployeeId ? (
                          item.employee_accepted !== 1 ? (
                            <td style={{ textAlign: "center" }}>
                              <Button
                                style={{
                                  maxWidth: "4rem",
                                  minWidth: "4rem",
                                  maxHeight: "1.5rem",
                                  minHeight: "1.5rem",
                                  fontSize: "0.8rem",
                                }}
                                size="sm"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleAcceptEvent();
                                }}
                              >
                                Accept
                              </Button>
                            </td>
                          ) : !item.troubleshoot_done ? (
                            <td style={{ textAlign: "center" }}>
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setTroubleShootingModal(true);
                                  setCurrentId(i);
                                }}
                              >
                                Troubleshoot
                              </Button>
                              <Button
                                style={{
                                  maxWidth: "0.1rem",
                                  minWidth: "0.1rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleEditEvent();
                                  setNewEvent(false);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          ) : !item.action_done ? (
                            <td style={{ textAlign: "center" }}>
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setActionModal(true);
                                  setCurrentId(i);
                                }}
                              >
                                Action
                              </Button>
                              <Button
                                style={{
                                  maxWidth: "0.1rem",
                                  minWidth: "0.1rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleEditEvent();
                                  setNewEvent(false);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          ) : (
                            <td style={{ textAlign: "center" }}>
                              <Button
                                style={{
                                  maxWidth: "4rem",
                                  minWidth: "4rem",
                                  maxHeight: "1.5rem",
                                  minHeight: "1.5rem",
                                  fontSize: "0.8rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleCompleteEvent();
                                }}
                              >
                                Complete
                              </Button>
                              <Button
                                style={{
                                  maxWidth: "0.1rem",
                                  minWidth: "0.1rem",
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setEmployee("");
                                  setCurrentId(i);
                                  handleEditEvent();
                                  setNewEvent(false);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          )
                        ) : (
                          <td style={{ textAlign: "center" }}>
                            <Button
                              style={{
                                maxWidth: "4rem",
                                minWidth: "4rem",
                                maxHeight: "1.5rem",
                                minHeight: "1.5rem",
                                fontSize: "0.8rem",
                              }}
                              size="sm"
                              color="error"
                              variant="contained"
                              disabled
                            >
                              N/A
                            </Button>
                          </td>
                        )
                      ) : (
                        <td style={{ textAlign: "center", color: "green" }}>
                          Event Completed
                        </td>
                      )}
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
      {troubleShootingModal && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AddTroubleshooting
                troubleshootDisplay={false}
                eventId={props.events[currentId].event_id}
                troubleshootingModal={troubleShootingModal}
                setTroubleShootingModal={setTroubleShootingModal}
                troubleshootingImages={props.troubleshootingImages}
                troubleshootingList={props.troubleshootingList}
                jr={props.jr}
                fetchData={props.fetchData}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Modal
        destroyOnClose={true}
        width="250px"
        title="Add TroubleShooting"
        open={addTroubleshootEntryModal}
        onCancel={() => setAddTroubleshootEntryModal(false)}
        footer={false}
      >
        {addTroubleshootEntryModal && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Typography variant="h5">
                  "{props.events[currentId].event_description}"
                </Typography>
                <Typography variant="h5">
                  Is troubleshooting necessary for the event listed above?
                </Typography>
                <Button
                  variant={"contained"}
                  color="primary"
                  size="small"
                  onClick={() => setAddTroubleshootEntryModal(true)}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => console.log("no button")}
                >
                  No
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Modal>
      {actionModal && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AddAction
                actionDisplay={false}
                eventId={props.events[currentId].event_id}
                actionModal={actionModal}
                setActionModal={setActionModal}
                actionImages={props.actionImages}
                actionList={props.actionList}
                jr={props.jr}
                fetchData={props.fetchData}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Accept Event"
        open={showAcceptModal}
        onCancel={() => setShowAcceptModal(false)}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <h5>Are you sure you want to accept this event?</h5>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  style={{
                    backgroundColor: "green",
                  }}
                  variant="contained"
                  onClick={confirmAcception}
                  sx={{ ml: 2, float: "right" }}
                >
                  Accept
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowAcceptModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Complete Event"
        open={showCompleteModal}
        onCancel={() => setShowCompleteModal(false)}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <h5>Has this event been completed?</h5>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  style={{
                    backgroundColor: "green",
                  }}
                  variant="contained"
                  onClick={confirmCompletion}
                  sx={{ ml: 2, float: "right" }}
                >
                  Complete
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowCompleteModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Remove Event"
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <h5>Are you sure you want to remove this event?</h5>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  color="error"
                  variant="contained"
                  onClick={confirmRemoval}
                  sx={{ ml: 2, float: "right" }}
                >
                  Remove
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowDeleteModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default AddEvent;
