import { useCallback, useEffect, useState } from "react";
import LoadScreen from "../../../Components/LoadScreen";
import {
  Alert,
  Button,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Avatar, Modal } from "antd";
import EmployeeForm from "../../DashboardPages/VendorProfile/EmployeeForm";
import axios from "axios";
import { useUserState } from "../../../Context/UserContext";
import DeactivateEmployeeModal from "../../DashboardPages/VendorProfile/DeactivateEmployeeModal";
import PropTypes from "prop-types";
import EditEmployeeForm from "../../DashboardPages/VendorProfile/EditEmployeeForm";

function TableRow({
  employee,
  index,
  handleEmployeeClick,
  openDeactivateModal,
  openEditModal,
  handleResendConsent,
}) {
  console.log(employee);
  const [hover, setHover] = useState(false);

  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if (isCounting && count > 0) {
      const timer = setTimeout(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (count === 0) {
      setIsCounting(false);
    }
  }, [count, isCounting]);

  return (
    <tr
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleEmployeeClick(employee, index)}
      style={
        hover
          ? {
              backgroundColor: "rgb(240,240,240)",
              cursor: "pointer",
              height: "6rem",
            }
          : {
              // backgroundColor: employee.is_active === 1 ? "yellow" : "pink",
              height: "6rem",
            }
      }
    >
      <td style={{ paddingLeft: "0.25em" }}>
        {employee.emp_profile_pic === null ||
        employee.emp_profile_pic.includes("avatar_default.png") ? (
          <Avatar size={45}>{employee.first_name[0]}</Avatar>
        ) : (
          <Avatar size={45} src={employee.emp_profile_pic_url} />
        )}
      </td>
      <td style={{ padding: "0.5em" }}>
        {employee.first_name} {employee.last_name}
      </td>
      <td style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
        {employee.email}
      </td>
      <td
        style={{
          minWidth: "16.666%",
          wordWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {employee.phone_number}
      </td>
      <td>
        {employee.street}, {employee.city}, {employee.province}
      </td>
      <td>{employee.role}</td>
      <td style={{ padding: "0.75em", textAlign: "center" }}>
        {employee.is_active === 1 && employee.consent_confirm === 1 ? (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                padding: "0.25rem 0.5rem",
                width: "100%",
                marginBottom: "0.5em",
              }}
              onClick={(e) => {
                e.stopPropagation();
                openDeactivateModal(employee, index);
              }}
            >
              Deactivate
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ padding: "0.25rem 0.5rem", width: "100%" }}
              onClick={(e) => {
                e.stopPropagation();
                openEditModal(employee, index);
              }}
            >
              Edit
            </Button>
          </>
        ) : employee.consent_confirm === 0 && employee.is_active === 1 ? (
          <>
            <Typography variant="p" textAlign={"center"} color="#debd02">
              Pending
            </Typography>
            <Button
              disabled={count > 0}
              variant="contained"
              color="primary"
              sx={{
                padding: "0.25rem 0.5rem",
                width: "100%",
                "&.Mui-disabled": { color: "#01522f" },
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleResendConsent(employee);
                setCount(20); // Reset countdown
                setIsCounting(true);
              }}
            >
              {isCounting ? `Wait ${count}s` : "Resend Link"}
            </Button>
          </>
        ) : (
          <Typography variant="p" color="error">
            Deactivated
          </Typography>
        )}
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  employee: PropTypes.object,
  index: PropTypes.number,
  handleEmployeeClick: PropTypes.func,
  openDeactivateModal: PropTypes.func,
  openEditModal: PropTypes.func,
};

const EmployeeListDisplay = (props) => {
  const { user } = useUserState();
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);

  const [employee, setEmployee] = useState({});
  const [employeeFirstName, setEmployeeFirstName] = useState("");
  const [employeeLastName, setEmployeeLastName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [employeeRole, setEmployeeRole] = useState("");
  const [employeeStreetAddress, setEmployeeStreetAddress] = useState("");
  const [employeeCity, setEmployeeCity] = useState("");
  const [employeeProvince, setEmployeeProvince] = useState("");
  const [employeePostalCode, setEmployeePostalCode] = useState("");

  const [employeeDetailModal, setEmployeeDetailModal] = useState(false);
  const [deleteEmployeeModal, setDeleteEmployeeModal] = useState(false);

  const [lastDay, setLastDay] = useState();
  const [deactivationReason, setDeactivationReason] = useState("");
  const [employeeList, setEmployeeList] = useState(props.employees);
  const [currentEmployee, setCurrentEmployee] = useState();
  const [isNew, setIsNew] = useState(true);
  const [deactivationErrors, setDeactivationErrors] = useState("");

  const handleEmployeeClick = (employee, index) => {
    setCurrentEmployee(index);
    setEmployee(employee);
    setEmployeeFirstName(employee.first_name);
    setEmployeeLastName(employee.last_name);
    setEmployeeEmail(employee.email);
    setEmployeePhone(employee.phone_number);
    setEmployeeStreetAddress(employee.street);
    setEmployeeCity(employee.city);
    setEmployeeProvince(employee.province);
    setEmployeePostalCode(employee.postal_code);
    setEmployeeDetailModal(true);
  };

  const openEditModal = (employee, index) => {
    console.log("employee: ", employee);
    setCurrentEmployee(index);
    setAddEmployeeModal(true);
    setEmployee(employee);
    setEmployeeFirstName(employee.first_name);
    setEmployeeLastName(employee.last_name);
    setEmployeeEmail(employee.email);
    setEmployeePhone(employee.phone_number);
    setEmployeeStreetAddress(employee.street);
    setEmployeeCity(employee.city);
    setEmployeeProvince(employee.province);
    setEmployeePostalCode(employee.postal_code);
    setEmployeeRole(employee.role);
    setIsNew(false);
  };

  const openDeactivateModal = (employee, index) => {
    setCurrentEmployee(index);
    setDeleteEmployeeModal(true);
    setEmployee(employee);
    setEmployeeFirstName(employee.first_name);
    setEmployeeLastName(employee.last_name);
    setEmployeeEmail(employee.email);
    setEmployeePhone(employee.phone_number);
    setEmployeeStreetAddress(employee.street);
    setEmployeeCity(employee.city);
    setEmployeeProvince(employee.province);
    setEmployeePostalCode(employee.postal_code);
    setEmployeeRole(employee.role);
  };

  const fetchEmployeeData = useCallback(() => {
    axios
      .post("/employee/getEmployees", {
        VendorId: user.company_id,
      })
      .then(({ data }) => {
        console.log(data);
        const employees = data.employees;
        props.setEmployeeList(employees);
        // const employeeDetails = data.employees;
        // props.setEmployees(employeeDetails);
      });
  }, [user.company_id]);

  const handleErrors = () => {
    console.log(lastDay, deactivationReason);
    const errors = [];
    if (!deactivationReason) {
      errors.deactivationReason = "Deactivation Reason is required";
    }
    if (!lastDay) {
      errors.lastDay = "Last Day is required";
    }

    setDeactivationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const confirmDeactivation = () => {
    const isValid = handleErrors();

    if (!isValid) {
      console.log("Form has errors, please fix them.");
      return;
    }

    axios.post("/employee/deactivateEmployee", {
      userId: employee.employee_user_id,
      EmployeeId: employee.employee_id,
      deactivationReason: deactivationReason,
      lastDay: lastDay,
      currentDate: new Date().toUTCString(),
    });
    props.fetchEmployeeData();
    setDeleteEmployeeModal(false);
  };

  const mobileView = useMediaQuery("(max-width: 623px)");
  console.log(props.employees, "EMPLOYEELISTDISPLAY");

  const options = ["Active Employees", "Inactive Employees"];

  const handleResendConsent = async (employee) => {
    console.log(employee);
    await axios.post("/resendConsentToEmployee", {
      host: window.location.host,
      employeeName: `${employee.first_name} ${employee.last_name}`,
      role: employee.role,
      email: employee.email,
      userId: employee.employee_user_id,
    });
  };

  const [submitAlert, setSubmitAlert] = useState(false);
  const [submitUpdateAlert, setSubmitUpdateAlert] = useState(false);

  return props.employees ? (
    <>
      <Stack spacing={2} width={mobileView ? "100%" : "50vw"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            justifyContent={"start"}
            style={{ width: "50%", padding: "0.25rem", marginBottom: "1em" }}
          >
            {/* <InputLabel>Filter</InputLabel> */}
            <Select
              sx={{
                width: "50%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              multiple
              value={props.selectedFilters}
              onChange={(e) => props.handleChange(e.target.value)}
              renderValue={(selected) => "Status"}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={props.selectedFilters.includes(option)} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"end"}
            style={{ width: "50%", padding: "0.25rem", marginBottom: "1em" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAddEmployeeModal(true);
              }}
              style={{ width: "50%", padding: "0.25rem" }}
            >
              Add Team Member
            </Button>
          </Stack>
        </Stack>

        {props.employees.length > 0 ? (
          <Stack
            style={{
              overflowY: "scroll",
              width: "100%",
              height: "calc(100% - 5.75em)",
              margin: 0,
            }}
          >
            <table style={{}}>
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "11em" }} />
              </colgroup>
              <thead>
                <th></th>
                <th style={{ paddingLeft: "0.5em" }}>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Role</th>
                <th style={{ paddingLeft: "0.5em" }}>Actions</th>
              </thead>
              <tbody>
                {props.employees?.map(
                  (employee, index) => (
                    console.log(employee, "HUYTRFVBNMJYU&^%$"),
                    (
                      <TableRow
                        employee={employee}
                        openEditModal={openEditModal}
                        openDeactivateModal={openDeactivateModal}
                        handleEmployeeClick={handleEmployeeClick}
                        handleResendConsent={handleResendConsent}
                        key={`Tablerow_${employee.employee_id}`}
                      />
                    )
                  )
                )}
              </tbody>
            </table>
          </Stack>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Typography variant="h4">
              <i>No team members found.</i>
            </Typography>
          </Stack>
        )}
      </Stack>

      <Modal
        destroyOnClose={true}
        width="700px"
        centered={true}
        title={isNew ? "Add Team Member" : "Update Team Member"}
        open={addEmployeeModal}
        onCancel={() => {
          setAddEmployeeModal(false);
          setEmployeeFirstName("");
          setEmployeeLastName("");
          setEmployeeEmail("");
          setEmployeePhone("");
          setEmployeeStreetAddress("");
          setEmployeeCity("");
          setEmployeeProvince("");
          setEmployeePostalCode("");
          setEmployeeRole("");
          setIsNew(true);
        }}
        footer={false}
      >
        {isNew ? (
          <EmployeeForm
            fetchData={props.fetchEmployeeData}
            // fetchData={fetchEmployeeData}
            onClose={() => {
              setAddEmployeeModal(false);
              setEmployeeFirstName("");
              setEmployeeLastName("");
              setEmployeeEmail("");
              setEmployeePhone("");
              setEmployeeStreetAddress("");
              setEmployeeCity("");
              setEmployeeProvince("");
              setEmployeePostalCode("");
              setEmployeeRole("");
            }}
            setSubmitAlert={setSubmitAlert}
            isNew={isNew}
            employeeUserId={employee.employee_user_id}
            employeeId={employee.employee_id}
            profilePic={employee.profile_pic}
            startingFormData={{
              firstName: employeeFirstName,
              lastName: employeeLastName,
              email: employeeEmail,
              phone: employeePhone,
              streetAddress: employeeStreetAddress,
              city: employeeCity,
              province: employeeProvince,
              postalCode: employeePostalCode,
              role: employeeRole,
            }}
          />
        ) : (
          <EditEmployeeForm
            setSubmitAlert={setSubmitUpdateAlert}
            fetchData={props.fetchEmployeeData}
            // fetchData={fetchEmployeeData}
            onClose={() => {
              setAddEmployeeModal(false);
              setEmployeeFirstName("");
              setEmployeeLastName("");
              setEmployeeEmail("");
              setEmployeePhone("");
              setEmployeeStreetAddress("");
              setEmployeeCity("");
              setEmployeeProvince("");
              setEmployeePostalCode("");
              setEmployeeRole("");
            }}
            employee={employee}
          />
        )}
      </Modal>

      {submitAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Team member has been added successfully.
        </Alert>
      )}
      {submitUpdateAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Team member has been updated successfully.
        </Alert>
      )}

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Deactivate Employee"
        open={deleteEmployeeModal}
        onCancel={() => {
          setDeleteEmployeeModal(false);
          setEmployeeFirstName("");
          setEmployeeLastName("");
          setEmployeeEmail("");
          setEmployeePhone("");
          setEmployeeStreetAddress("");
          setEmployeeCity("");
          setEmployeeProvince("");
          setEmployeePostalCode("");
          setEmployeeRole("");
        }}
        footer={false}
      >
        <DeactivateEmployeeModal
          setLastDay={setLastDay}
          lastDay={lastDay}
          deactivationReason={deactivationReason}
          setDeactivationReason={setDeactivationReason}
          employeeList={employeeList}
          confirmDeactivation={confirmDeactivation}
          setDeactivateEmployeeModal={setDeleteEmployeeModal}
          currentEmployee={currentEmployee}
          errorMessage={deactivationErrors}
          setErrorMessage={setDeactivationErrors}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Client Details"
        open={employeeDetailModal}
        onCancel={() => setEmployeeDetailModal(false)}
        footer={false}
      >
        <Stack spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <Stack width={"25%"}>
              {employee.profile_pic?.includes("avatar_default.png") ? (
                <Avatar size={100}>{employee.first_name[0]}</Avatar>
              ) : (
                <Avatar size={100} src={employee.profile_pic} />
              )}
            </Stack>

            <Stack width={"75%"}>
              <Typography>
                {employee.first_name} {employee.last_name}
              </Typography>
              <Typography>{employee.email}</Typography>
              <Typography>{employee.street}</Typography>
              <Typography>
                {employee.city}, {employee.province}
              </Typography>
              <Typography>{employee.postal_code}</Typography>
            </Stack>
          </Stack>

          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteEmployeeModal(true);
                setEmployee(employee);
                setEmployeeFirstName(employee.first_name);
                setEmployeeLastName(employee.last_name);
                setEmployeeEmail(employee.email);
                setEmployeePhone(employee.phone_number);
                setEmployeeStreetAddress(employee.street);
                setEmployeeCity(employee.city);
                setEmployeeProvince(employee.province);
                setEmployeePostalCode(employee.postal_code);
                setEmployeeRole(employee.role);
              }}
            >
              Deactivate
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                setAddEmployeeModal(true);
                setEmployee(employee);
                setEmployeeFirstName(employee.first_name);
                setEmployeeLastName(employee.last_name);
                setEmployeeEmail(employee.email);
                setEmployeePhone(employee.phone_number);
                setEmployeeStreetAddress(employee.street);
                setEmployeeCity(employee.city);
                setEmployeeProvince(employee.province);
                setEmployeePostalCode(employee.postal_code);
                setEmployeeRole(employee.role);
                setIsNew(false);
              }}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  ) : (
    <LoadScreen />
  );
};

EmployeeListDisplay.propTypes = {
  employees: PropTypes.array,
  setEmployeeList: PropTypes.func,
};

export default EmployeeListDisplay;
