import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Result } from "antd";
import axios from "axios";

import { Button, FormHelperText } from "@mui/material";

import AuthWrapper from "./AuthWrapper";

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  let id = window.location.href.split("/")[4];
  let token = window.location.href.split("/")[5];

  const onSubmit = () => {
    axios
      .post(`/auth/verifyAccount/${id}/${token}`)
      .then((response) => {
        if (response.data.ok) {
          navigate("/verified");
        } else {
          setErrorMessage(response.data.error || "Verification failed");
        }
      })
      .catch((error) => {
        console.error("Verification error:", error);
        setErrorMessage(
          error.response?.data?.message ||
            "An error occurred during verification. Please try again."
        );
      });
  };

  return (
    <AuthWrapper>
      <Result
        className="flex-60 padding0"
        status="success"
        title={<h4>Please click verify button to verify your account.</h4>}
        extra={[
          <Button variant="contained" type="primary" onClick={onSubmit}>
            Verify
          </Button>,
        ]}
      />
      <FormHelperText error id="helper-text-errorMessage-signup">
        {errorMessage}
      </FormHelperText>
    </AuthWrapper>
  );
};

export default VerifyAccount;
