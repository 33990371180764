import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { Result } from "antd";
import { Button } from "@mui/material";
import AuthWrapper from "./AuthWrapper";

const ConsentPage = (props) => {
  const { token } = useParams(); // Capture the token from the URL

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  console.log(props.employee);

  useEffect(() => {
    const confirmConsent = async () => {
      try {
        const response = await axios.get(
          props.client && !props.employee
            ? `/auth/confirmClientConsent/${token}`
            : `/auth/confirmEmployeeConsent/${token}`
        );
        console.log(response);
        setMessage("Your consent has been successfully recorded!");
      } catch (error) {
        if (error.response?.status === 404) {
          setMessage("The consent link is invalid or has expired.");
        } else {
          setMessage("An error occurred while processing the consent request.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    confirmConsent();
  }, [token, props.client, props.employee]);

  if (isLoading) {
    return (
      <AuthWrapper>
        <div>Verifying consent...</div>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <Result
        className="flex-60 padding0"
        status={message.includes("successfully") ? "success" : "error"}
        title={<h4>Consent Confirmation</h4>}
        subTitle={message}
        extra={[
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
          >
            Login
          </Button>,
        ]}
      />
    </AuthWrapper>
  );
};

export default ConsentPage;
