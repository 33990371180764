import HomePage from "../Pages/LandingPage/HomePage";
import Login from "../Pages/Authentication/auth-pages/Login";
import Register from "../Pages/Authentication/auth-pages/Register";
import VerifyAccount from "../Pages/Authentication/auth-pages/VerifyAccount";

import ClientIntakeDetails from "../Pages/Authentication/auth-forms/ClientIntakeDetails";
import CompanyIntakeDetails from "../Pages/Authentication/auth-forms/CompanyIntakeDetails";
import PasswordResetSuccess from "../Pages/Authentication/auth-pages/PasswordResetSuccess.js";
import ForgotPassword from "../Pages/Authentication/auth-pages/ForgotPassword";
import ResetPassword from "../Pages/Authentication/auth-pages/ResetPassword";
import ResetLinkSent from "../Pages/Authentication/auth-pages/ResetLinkSent";
import VerificationPage from "../Pages/Authentication/auth-pages/VerificationPage";

import MinimalLayout from "../Layout/MinimalLayout";
import AdminIntakeDetails from "../Pages/Authentication/auth-forms/AdminIntakeDetails";
import SignUpSuccess from "../Pages/Authentication/auth-forms/SignUpSuccess";
import VendorDecision from "../Pages/Authentication/auth-forms/VendorDescision.js";
import TermsAgreement from "../Pages/Authentication/auth-pages/TermsAgreement.jsx";
import DelegationAccepted from "../Pages/PropertiesPage/PropertyComponents/DelegationAccepted.js";
import AcceptDelegation from "../Pages/PropertiesPage/PropertyComponents/AcceptDelegation.js";
import TradesPage from "../Pages/LandingPage/TradesPage.js";
import NeedHelp from "../Pages/Authentication/auth-pages/NeedHelp.js";
import ConsentPage from "../Pages/Authentication/auth-pages/ConsentPage.js";

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/trades",
      element: <TradesPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Register />,
    },
    {
      path: "/needHelp",
      element: <NeedHelp />,
    },
    {
      path: "/accountcreated",
      element: <SignUpSuccess />,
    },
    {
      path: "/verifyAccount/:id/:code",
      element: <VerifyAccount />,
    },
    {
      path: "/acceptDelegation/:id/:code",
      element: <AcceptDelegation />,
    },
    {
      path: "/recoverPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/confirmConsent/client/:token",
      element: <ConsentPage employee={false} client={true} />,
    },
    {
      path: "/confirmConsent/employee/:token",
      element: <ConsentPage employee={true} client={false} />,
    },
    {
      path: "/verified",
      element: <VerificationPage />,
    },
    {
      path: "/delegationAccepted",
      element: <DelegationAccepted />,
    },
    {
      path: "/resetPassword/:id/:code",
      element: <ResetPassword />,
    },
    {
      path: "/resetLinkSent",
      element: <ResetLinkSent />,
    },
    {
      path: "/resetSuccessful",
      element: <PasswordResetSuccess />,
    },

    {
      path: "/signup/clientDetails",
      element: <ClientIntakeDetails />,
    },
    {
      path: "/signup/vendorDecision",
      element: <VendorDecision />,
    },

    {
      path: "/signup/companyDetails",
      element: <CompanyIntakeDetails />,
    },
    {
      path: "/signup/adminDetails",
      element: <AdminIntakeDetails />,
    },
    {
      path: "/termsAndConditions",
      element: <TermsAgreement />,
    },
  ],
};

export default LoginRoutes;
