const {
  Grid,
  Stack,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
} = require("@mui/material");
const {
  default: DatePicker,
} = require("../../../../Components/FormComponents/DatePicker");
const {
  default: AnimateButton,
} = require("../../../../Layout/components/@extended/AnimateButton");

const DeactivateClientModal = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="h5">
            Are you sure you want to deactivate{" "}
            <strong>
              <i>
                {props.client.first_name} {props.client.last_name}
              </i>
            </strong>
            ?
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel>Reason for deactivation:</InputLabel>
          <OutlinedInput
            fullWidth
            type="text"
            onChange={(e) => props.setDeactivationReason(e.target.value)}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel>Last Day Worked:</InputLabel>
          <DatePicker
            style={{ width: "100%" }}
            required
            type="date"
            value={props.lastDay}
            onChange={(e, dateString) => props.setLastDay(e.target.valueAsDate)}
          />
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack
          spacing={3}
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <AnimateButton>
            <Button
              size="sm"
              variant="outlined"
              color="secondary"
              onClick={() => props.setDeactivateEmployeeModal(false)}
            >
              Cancel
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              color="error"
              variant="contained"
              onClick={props.confirmDeactivation}
            >
              Deactivate
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DeactivateClientModal;
