import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// assets
import Navigation from "./HeaderContent/Navigation";
import Logo from "../../components/Logo/Logo";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, setDisplayRequestForm }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesXs = useMediaQuery("(max-width: 900px)");

  // common header
  const mainHeader = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        padding: "0 5px"
      }}
    >
      <Logo />

      {matchesXs? <div style={{width: "100%"}}></div> :<Navigation />}
      <HeaderContent setDisplayRequestForm={setDisplayRequestForm} />
    </div>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      backgroundColor: "#01522f",
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.customShadows.z1,
    },
  };

  return (
    <>
      {/* {!matchDownMD ? ( */}
      <AppBarStyled open={open} {...appBar}>
        {mainHeader}
      </AppBarStyled>
      {/* ) : ( */}
      {/* <AppBar {...appBar}>{mainHeader}</AppBar>
      )} */}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
};

export default Header;
