import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "../style.module.css";

// xxxx-xx-xx
const dateRegex = /^\d{4}(-\d{2}){2}$/;
// xxxx-xx-xxT....
const isoRegex = /^\d{4}(-\d{2}){2}T/;
/**
 * @param {string | Date} value
 * @returns {string} yyyy-mm-dd
 */
const getDateFromValue = (value) => {
  if (value instanceof Date) {
    return value.toISOString().split("T")[0];
  } else if (dateRegex.test(value)) {
    return value;
  } else if (isoRegex.test(value)) {
    return value.split("T")[0];
  } else {
    // default date just in case
    return "1970-01-01";
  }
};

const DatePicker = memo(
  ({ required, value, name, id, onChange, onBlur, style }) => {
    const ref = useRef();
    const date = getDateFromValue(value);

    const firstRender = useRef(true);
    useEffect(() => {
      if (
        firstRender.current &&
        typeof ref.current.previousValue === "undefined"
      ) {
        ref.current.previousValue = date;
        firstRender.current = false;
      }
    });

    return (
      <input
        style={style}
        ref={ref}
        required={required}
        type="date"
        className={styles.inputDefaults}
        name={name}
        id={id}
        value={date}
        onChange={(e) => {
          if (e.target.value.length !== 0) {
            e.target.previousValue = e.target.value;
          } else {
            e.target.value = e.target.previousValue;
          }
          onChange(e);
        }}
        onBlur={onBlur}
      />
    );
  }
);

DatePicker.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

DatePicker.defaultProps = {
  required: false,
  value: "2000-00-00",
  name: "",
  id: null,
  onChange: () => {},
  onBlur: () => {},
};
export default DatePicker;
