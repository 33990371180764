import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import GetProvinceList from "../../../Authentication/auth-forms/GetProvinceList";
import ChangeClientPic from "./ChangeClientProfilePic";
import { useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../Layout/components/LoadingSpinner";
import { Typography } from "antd";
import PhoneInput from "../../../../Components/FormComponents/PhoneInput";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const EditClient = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    province: null,
    postalCode: null,
    ...props.formData,
  });

  const [googleAddress, setGoogleAddress] = useState("");

  /**
   * @param {import("react").ChangeEvent<HTMLInputElement>} e
   */
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const formIsChanged = () => {
    for (const [key, value] of Object.entries(formData)) {
      if (props.formData[key] !== value) {
        return true;
      }
    }
    return false;
  };
  const displaySubmitAlert = () => {
    props.setSubmitAlert(true);
    setTimeout(() => props.setSubmitAlert(false), 4000);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();

    let error = null;
    if (formData.firstName === "") {
      error = "First name is required";
    } else if (formData.lastName === "") {
      error = "Last name is required";
    } else if (formData.address === "") {
      error = "Street address is required";
    } else if (formData.city === "") {
      error = "City is required";
    } else if (formData.province === "") {
      error = "Province is required";
    } else if (formData.postalCode === "") {
      error = "Postal code is required";
    }
    setError(error);
    if (error !== null) {
      setSubmitting(false);
      return;
    }

    let form = new FormData();
    console.log("selectedFile", selectedFile);
    form.append("file", selectedFile);
    form.append("userId", props.client.id);

    if (selectedFile !== null) {
      await axios.post("/updateClientProfileImg", form);
    }
    await axios.post("/updateClient", {
      userId: props.client.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNum: formData.phone,
      street: formData.address,
      city: formData.city,
      province: formData.province,
      postalCode: formData.postalCode,
    });

    props.fetchClientList();
    props.setEditClientModal(false);
    displaySubmitAlert();
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ChangeClientPic
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              client={props.client}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>First Name</InputLabel>
            <OutlinedInput
              fullWidth
              id="firstname"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleFormChange}
              placeholder="John"
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel>Last Name</InputLabel>
            <OutlinedInput
              fullWidth
              id="lastname"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleFormChange}
              placeholder="Doe"
            />
          </Grid>

          {/* <Grid item xs={6}>
            <InputLabel>Email</InputLabel>
            <OutlinedInput
              fullWidth
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleFormChange}
              placeholder="john.doe@example.com"
              disabled
            />
          </Grid> */}

          <Grid item xs={6}>
            <InputLabel>Phone Number</InputLabel>
            <PhoneInput
              style={{ width: "100%" }}
              name={"phone"}
              value={formData.phone}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              className={"css-1hb7zxy-IndicatorsContainer"}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
              autocompletionRequest={{
                componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                types: ["address"],
              }}
              selectProps={{
                name: "streetAddress",
                value: googleAddress,
                onChange: async (selectedOption) => {
                  const placeId = selectedOption.value.place_id;
                  const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                  const response = await axios.get(
                    `/jobRequest/place-details`,
                    {
                      params: { placeId, key },
                    }
                  );
                  const addressComponents =
                    response.data.result.address_components;
                  const streetNumber =
                    addressComponents.find((component) =>
                      component.types.includes("street_number")
                    )?.long_name || "";
                  const route =
                    addressComponents.find((component) =>
                      component.types.includes("route")
                    )?.long_name || "";
                  const city =
                    addressComponents.find((component) =>
                      component.types.includes("locality")
                    )?.long_name || "";
                  const province =
                    addressComponents.find((component) =>
                      component.types.includes("administrative_area_level_1")
                    )?.short_name || "";

                  const postalCode =
                    response.data.result.address_components.find((component) =>
                      component.types.includes("postal_code")
                    )?.long_name || "";

                  // Combine street number and route for the full street address
                  const street = `${streetNumber} ${route}`.trim();

                  // Update city and province fields in Formik values
                  setFormData((oldData) => ({
                    ...oldData,
                    province,
                    postalCode,
                    city,
                    address: street,
                  }));
                  setGoogleAddress("");
                },
                placeholder: "Find address here",
              }}
            />
            <style>
              {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
            </style>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Street Address</InputLabel>
            <OutlinedInput
              fullWidth
              id="address"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleFormChange}
              placeholder="123 Main St"
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel>City</InputLabel>
            <OutlinedInput
              fullWidth
              id="city"
              type="text"
              name="city"
              value={formData.city}
              onChange={handleFormChange}
              placeholder="New York"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>Province</InputLabel>
            <Select
              fullWidth
              id="province-signup"
              value={formData.province}
              name="province"
              defaultValue={"Select Province"}
              onChange={handleFormChange}
            >
              {GetProvinceList().map((provinces) => (
                <MenuItem
                  value={provinces.province_shortform}
                  key={provinces.province_shortform}
                >
                  {provinces.province_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>Postal Code</InputLabel>
            <OutlinedInput
              fullWidth
              id="postal-code"
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleFormChange}
              placeholder="12345"
            />
          </Grid>

          <Grid item xs={12} display={"flex"} justifyContent="space-between">
            <Typography style={{ color: "red" }}>
              {error != null ? `*${error}` : ""}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formIsChanged()}
            >
              Update Client
            </Button>
          </Grid>
        </Grid>
      </form>
    </LoadingSpinner>
  );
};

export default EditClient;
