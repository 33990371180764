import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useChatContext } from "../../../../../Context/ChatContext"

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import { activeItem } from "../../../../store/reducers/menu";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, setOpen }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen, openItem } = menu;
  const navigate = useNavigate();

  const itemHandler = (id) => {
    navigate(item.url);
    dispatch(activeItem({ openItem: [id] }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, []);

  const textColor = "#fff";
  const iconSelectedColor = "#77c7a2";

  const mobileView = useMediaQuery("(max-width: 768px)");

  return (
        <ListItemButton
          disabled={item.disabled}
          onClick={() => {
            itemHandler(item.id);
            mobileView && setOpen(false);
          }}
          selected={isSelected}
        >
          {item.id === "chat"?<IconDisplayWithNotifications>{itemIcon}</IconDisplayWithNotifications>:<ListItemIcon style={{ color: "#fff" }}>{itemIcon}</ListItemIcon>}

          <ListItemText
            primary={
              <Typography
                variant="h6"
                noWrap
                sx={{ color: isSelected ? iconSelectedColor : textColor }}
              >
                {item.title}
              </Typography>
            }
          />
        </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

function IconDisplayWithNotifications({children}){
  const {notifications} = useChatContext();
  return(<>
    {(notifications.length === 0 && children) ? (
      <ListItemIcon style={{ color: "#fff" }}>{children}</ListItemIcon>
    ):(
      <Stack
        style={{
          backgroundColor: "#1890ff",
          color: "white",
          borderRadius: "15px",
          padding: "0.15rem",
          width: "1rem",
          height: "1rem",
          fontSize: "0.5rem",
          marginBottom: "0.5rem",
          textAlign: "center",
        }}
      >
        <Typography variant="p">{notifications.length}</Typography>
      </Stack>
    )}
  </>)
}

export default NavItem;
