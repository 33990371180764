import { Button, Grid, Stack, Typography } from '@mui/material';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import AnimateButton from '../../../../Layout/components/@extended/AnimateButton';

import moment from 'moment-timezone';
moment.tz.setDefault('Canada/Newfoundland');

function ExpandedTilePhase5(props) {
	const [showInvoice, setShowInvoice] = useState(false);

	return (
		<div className='phase' id='phase-4'>
			{props.jrData.job_status === 'Finalizing' ? (
				<Grid container spacing={1}>
					{props.invoice?.length > 0 && (
						<Grid item xs={12} md={6}>
							<Button
								size='sm'
								color='primary'
								onClick={() => setShowInvoice(true)}
								variant='contained'
								type='button'
							>
								View Invoice
							</Button>
						</Grid>
					)}
				</Grid>
			) : (
				<Grid container spacing={1}>
					<Grid item xs={12} md={6}>
						<Button
							size='sm'
							color='primary'
							onClick={() => setShowInvoice(true)}
							variant='contained'
							type='button'
						>
							View Invoice
						</Button>
					</Grid>
					<Grid item xs={12} md={12}>
						<p>Payment has been processed</p>
					</Grid>
				</Grid>
			)}
			<Modal
				destroyOnClose={true}
				width='700px'
				title='View Invoice'
				open={showInvoice}
				onCancel={() => setShowInvoice(false)}
				footer={false}
			>
				{showInvoice && typeof props.clientInvoiceDetails !== 'undefined' && (
					<div>
						<Typography>
							<b>INVOICE</b>
						</Typography>
						<div className='invoiceDiv'>
							<Typography>
								{props.vendorInvoiceDetails[0].vendor_legal_name}
							</Typography>
							<Typography>
								{props.vendorInvoiceDetails[0].street}{' '}
								{props.vendorInvoiceDetails[0].postal_code}
							</Typography>
							<Typography>
								{props.vendorInvoiceDetails[0].city},{' '}
								{props.vendorInvoiceDetails[0].province} Canada
							</Typography>
							<Typography>
								{props.vendorInvoiceDetails[0].phone_number}
							</Typography>
						</div>
						<Typography style={{ marginTop: '1rem' }}>
							<b>Bill To:</b>
						</Typography>
						<div className='invoiceDiv'>
							<Typography>
								{props.clientInvoiceDetails[0].first_name}{' '}
								{props.clientInvoiceDetails[0].last_name}
							</Typography>
							<Typography>
								{props.clientInvoiceDetails[0].street}{' '}
								{props.clientInvoiceDetails[0].postal_code}
							</Typography>
							<Typography>
								{props.clientInvoiceDetails[0].city},{' '}
								{props.clientInvoiceDetails[0].province} Canada
							</Typography>
							<Typography>
								{props.clientInvoiceDetails[0].phone_number}
							</Typography>
						</div>
						<div className='invoiceDiv'>
							<Typography style={{ marginTop: '1rem' }}>
								<b>Invoice Date:</b>{' '}
								{moment(props.invoice[0].invoice_date).format('MMM Do YYYY')}
							</Typography>
						</div>
						<div className='invoiceDiv'>
							<div>
								<br></br>
								<div className='buttonContainer'>
									<label className='categoryLabel' htmlFor='addButton'>
										Invoice Data
									</label>
								</div>
								<div>
									<table className='table'>
										<thead>
											<tr>
												<th>Description</th>
												<th>Quantity</th>
												<th>Rate</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{props.invoiceDetails.length > 0 &&
												props.invoiceDetails?.map((item, i) => (
													<tr key={item.invoice_details_id}>
														<td>{item.invoice_description}</td>
														<td>{item.invoice_quantity}</td>
														<td>${item.invoice_rate}</td>
														<td>${item.invoice_amount}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className='invoiceDiv'>
							<p>Sub Total: ${parseFloat(props.invoice[0].subtotal)}</p>
							<p>
								Sales Tax(15%): ${(props.invoice[0].subtotal * 0.15).toFixed(2)}
							</p>
							<p>TOTAL: ${props.invoice[0].total_amount}</p>
						</div>
						<Grid item xs={12}>
							<Stack spacing={1}>
								<AnimateButton>
									<Button
										onClick={() => {
											setShowInvoice(false);
										}}
										size='sm'
										type='button'
										variant='outlined'
										color='secondary'
										sx={{ ml: 2, float: 'right' }}
									>
										Cancel
									</Button>
								</AnimateButton>
							</Stack>
						</Grid>
					</div>
				)}
			</Modal>
		</div>
	);
}

export default ExpandedTilePhase5;
