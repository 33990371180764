import PropTypes from "prop-types";
import { useRef, useState, useContext } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

import styles from "./profileItems.module.css";

// project import
import MainCard from "../../../../components/MainCard";
import Transitions from "../../../../components/@extended/Transitions";

// assets
import {
  LogoutOutlined,
  UserOutlined,
  FilePdfOutlined,
  PropertySafetyFilled,
  KeyOutlined,
} from "@ant-design/icons";
import {
  UserStateContext,
  useUserDispatch,
  useUserState,
} from "../../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = (props) => {
  const theme = useTheme();
  let navigate = useNavigate();
  let dispatch = useUserDispatch();
  let { user } = useContext(UserStateContext);
  const handleLogout = async () => {
    dispatch({ state: undefined, type: "logout" });
    window.sessionStorage.clear();
    return navigate("/");
  };

  const handleProfile = () => {
    navigate("/dashboard/profile");
  };
  const handleChangePassword = () => {
    navigate("/dashboard/changePassword");
  };

  const handleTerms = () => {
    navigate("/dashboard/TermsAndCondition2022");
  };

  const handlePrivacy = () => {
    navigate("/dashboard/PrivacyPolicy2022");
  };

  const handleFeedback = () => {
    navigate("/dashboard/FeedbackForm");
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const { user } = useUserState();

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }} onClick={() => console.log(user)}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? "primary.dark" : "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "primary.dark" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack
          direction="row"
          // spacing={2}
          alignItems="center"
          sx={{ p: 0.5 }}
        >
          {console.log(user, "nfivwbifbwiubfiwbiefbiu")}
          {!user.vendorMode ? (
            user.profile_pic === "avatar_default.png" ||
            user.profile_pic === null ? (
              <Avatar sx={{ width: 32, height: 32 }}>
                {user.first_name[0]}
              </Avatar>
            ) : (
              <Avatar
                src={user.profile_pic_url}
                sx={{ width: 32, height: 32 }}
              />
            )
          ) : user.vendor_profile_pic === "avatar_default.png" ||
            user.vendor_profile_pic === null ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              {user.company_name[0]}
            </Avatar>
          ) : (
            <Avatar
              src={
                user.vendorMode &&
                user.empRole !== "Admin" &&
                user.empRole !== "Manager"
                  ? user.employee_profile_pic_url
                  : user.vendor_profile_pic_url
              }
              sx={{ width: 32, height: 32 }}
            />
          )}
          {!props.matchesXs && (
            <Typography
              color="#fff"
              variant="subtitle1"
              style={{
                width: "5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {user.vendorMode && user.is_employee
                ? user.employee_first_name
                : user.vendorMode
                ? user.company_name
                : user.first_name}
            </Typography>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  mt: -3,
                  width: 165,
                  minWidth: 150,
                  maxWidth: 200,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 120,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    elevation={0}
                    border={false}
                    content={false}
                    style={{ backgroundColor: "#017a46" }}
                  >
                    <CardContent sx={{ pl: 1, pt: 0.5 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        marginbottom={"0.25rem"}
                      >
                        <Stack
                          width={"100%"}
                          onClick={() => {
                            handleProfile();
                            setOpen(!open);
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="cream">
                              <UserOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction={"row"}
                              spacing={1.25}
                              alignItems={"center"}
                            >
                              <Typography color={"#fff"} variant="h6">
                                Profile
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>
                        <Stack
                          width={"100%"}
                          onClick={() => {
                            handleChangePassword();
                            setOpen(!open);
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="cream">
                              <KeyOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction={"row"}
                              spacing={1.25}
                              alignItems={"center"}
                            >
                              <Typography color={"#fff"} variant="h6">
                                Change Password
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>

                        <Stack
                          width={"100%"}
                          onClick={() => {
                            handleTerms();
                            setOpen(!open);
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="cream">
                              <FilePdfOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction={"row"}
                              spacing={1.25}
                              alignItems={"center"}
                            >
                              <Typography color={"#fff"} variant="h6">
                                Terms and Conditions
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>

                        <Stack
                          width={"100%"}
                          onClick={() => {
                            handlePrivacy();
                            setOpen(!open);
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="cream">
                              <FilePdfOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction={"row"}
                              spacing={1.25}
                              alignItems={"center"}
                            >
                              <Typography color={"#fff"} variant="h6">
                                Privacy Policy
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>
                        {/* <Stack
                          width={"100%"}
                          onClick={() => {
                            axios.post("/vendorMode/changeVendorMode", {
                              vendorMode: !user.vendorMode,
                              user: user,
                            });
                            dispatch({ state: user, type: "swapMode" });
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="secondary">
                              <FilePdfOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction={"row"}
                              spacing={1.25}
                              alignItems={"center"}
                            >
                              <Typography variant="h6">VENDORMODE</Typography>
                            </Stack>
                          </Grid>
                        </Stack> */}

                        <Stack
                          width={"100%"}
                          onClick={() => {
                            handleLogout();
                            setOpen(!open);
                          }}
                          className={styles.profileItem}
                          direction={"row"}
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item>
                            <IconButton size="large" color="cream">
                              <LogoutOutlined />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Stack
                              direction="row"
                              spacing={1.25}
                              alignItems="center"
                            >
                              <Typography color={"#fff"} variant="h6">
                                Logout
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>
                      </Grid>
                    </CardContent>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
