import { VendorTile } from "./VendorTile";
import styles from "../../../MarketPage/MarketComponents/JobDisplay.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Card, InputLabel, Stack, Switch, useMediaQuery } from "@mui/material";
import { useJRContext } from "../../../../Context/JobRequestContext";

const VendorJR = (props) => {
  const { openJR, setOpenJR } = useJRContext();
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    axios.get("/notifications/getJobs").then(({ data }) => {
      setNotifications(data.notifications);
    });
  };

  useEffect(() => {
    getNotifications();
    const notificationTimer = setInterval(getNotifications, 60000);
    return clearInterval(notificationTimer);
  }, []);

  return (
    <div className={styles.jobDisplay}>
      <div
        className={styles.jobDisplayList}
        style={{ height: openJR ? "100%" : "100%" }}
      >
        <div>
          <div className={styles.employeeList}>
            {props.currentJRs.length > 0 ? (
              props.currentJRs?.map((jrData, i) => {
                props.setJRsLoaded(true);
                return (
                  <span key={`${jrData.job_id}`}>
                    <VendorTile
                      chargePercentages={props.chargePercentages}
                      setMapFocus={props.setMapFocus}
                      fetchJrData={props.fetchJrData}
                      jrDetails={props.acceptedJrs}
                      jrData={jrData}
                      showSubmitForm={props.showSubmitForm}
                      setShowSubmitForm={props.setShowSubmitForm}
                      openJR={props.openJR}
                      setOpenJR={props.setOpenJR}
                      notification={notifications.includes(jrData.job_id)}
                      setAgreementOpen={props.setAgreementOpen}
                    />
                  </span>
                );
              })
            ) : (
              <h2>
                <em>You have no active job requests.</em>
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorJR;
