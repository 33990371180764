import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useUserState } from "./UserContext";
import PropTypes from "prop-types";
import axios from "axios";

const ENDPOINT = (process.env.NODE_ENV === "development")
      ? process.env.REACT_APP_DEVELOPMENT_ENDPOINT
      : process.env.REACT_APP_PRODUCTION_ENDPOINT;

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const userState = useUserState()
  const [socket, setSocket] = useState(null)
  /** @type {[onlineUsers[], function(onlineUsers[]):void]} */
  const [onlineUsers, setOnlineUsers] = useState([])
  const [notifications, setNotifications] = useState([])

  const getUnreadMessages = useCallback(()=>{
    axios
      .post("/communication/getUnreadMessage")
      .then((res) => {
        setNotifications(res.data.unreadMsgList);
      });
  }, [setNotifications])

  const updateChatToRead = useCallback((ChatId)=>{
    axios.post("communication/updateChatToRead",{ChatId}).then(()=>{
      getUnreadMessages()
    })
  }, [getUnreadMessages])

  const onGetNotification = useCallback((data)=>{
    if (data == null) return;
    if (data.is_read === 0){
      setNotifications(prev=>[data, ...prev])
    }
  },[setNotifications])
  const onGetOnlineUsers = useCallback((users)=>{
    setOnlineUsers(users)
  }, [setOnlineUsers])

  //get web socket connection
  useEffect(() => {
    if (userState.user == null) return;

    const newSocket = io(ENDPOINT);

    newSocket.emit("addNewUser", userState.user.id)
    newSocket.on("getOnlineUsers", onGetOnlineUsers)
    newSocket.on("getNotification", onGetNotification)

    setSocket(newSocket);

    return () => {
      newSocket.off("getOnlineUsers")
      newSocket.off("getNotification")
      newSocket.disconnect();
    };
  }, [userState, onGetNotification, onGetOnlineUsers]);

  useEffect(()=>{
    getUnreadMessages()
  },[getUnreadMessages])

  const sendNewMessage = (message, receipientId, conversationId)=>{
    if (socket === null){
      console.error("can't send message socket is null")
      return;
    }

    console.log("sendNewMessage Start")
    socket.emit("sendMessage", {
      message,
      receipientId,
      conversationId
    });

    getUnreadMessages();
  }

  return (
    <ChatContext.Provider value={{ onlineUsers, socket, sendNewMessage, notifications, updateChatToRead }}>
      {children}
    </ChatContext.Provider>
  );
};

ChatContextProvider.propTypes = {
  children: PropTypes.element,
  user: PropTypes.object
}

/**
 * @returns {{
 * onlineUsers:Array<OnlineUser>, 
 * socket:Socket, 
 * sendNewMessage:function(Object, OnlineUser):void, 
 * notifications:Array<Object>,
 * updateChatToRead:function(number):void}}
 */
export function useChatContext() {
  const context = useContext(ChatContext);
  if (context === null) {
    throw new Error("useChatContext must be used within a ChatContextProvider");
  }
  return context;
}

/**
 * @typedef {Object} OnlineUser
 * @property {string} socketId
 * @property {number} userId
 */
