import {
  Alert,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Avatar, Modal } from "antd";
import LoadScreen from "../../../Components/LoadScreen";
import { useEffect, useState } from "react";
import AddClientDetails from "../../DashboardPages/VendorProfile/AddClientModal";
import ChangeProfilePicture from "../../EditForms/ChangeProfilePicture";
import EditClient from "./ClientListComponents/EditClient";
import AddClientJob from "./ClientListComponents/AddClientJob";
import GetProvinceList from "../../Authentication/auth-forms/GetProvinceList";
import axios from "axios";
import DeactivateClientModal from "./ClientListComponents/DeactivateClientModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddClientServiceAddress from "./ClientListComponents/AddClientServiceAddress";

function TableRow(props) {
  const [hover, setHover] = useState(false);
  const { client, handleResendConsent } = props;

  const [property, setProperty] = useState(0);
  const [properties, setProperties] = useState([]);

  const fetchProperties = async () => {
    axios
      .post("/property/getPropertyDetailsForClients", { userId: client.id })
      .then((res) => {
        setProperties(res.data.property);
        res.data.property.forEach((prop, index) => {
          if (client.street === prop.street && client.city === prop.city) {
            setProperty(index);
          }
        });
      });
  };

  useEffect(() => {
    fetchProperties();
  }, [props.updateProperties]);

  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if (isCounting && count > 0) {
      const timer = setTimeout(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (count === 0) {
      setIsCounting(false);
    }
  }, [count, isCounting]);

  return (
    <Grid
      marginTop={"2rem"}
      container
      spacing={1}
      padding={"0.75rem"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => props.handleClientClick(props.client)}
      style={
        hover ? { backgroundColor: "rgb(240,240,240)", cursor: "pointer" } : {}
      }
    >
      <Grid item md={4} overflow={"hidden"}>
        <Stack justifyContent={"center"}>
          <Stack justifyContent={"center"} paddingLeft={"2rem"}>
            {client.profile_pic.includes("avatar_default.png") ? (
              <Avatar size={45}>
                {client.first_name !== null ? client.first_name[0] : ""}
              </Avatar>
            ) : (
              <Avatar size={45} src={client?.profile_pic} />
            )}
          </Stack>
          <Stack>
            <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
              {client.first_name} {client.last_name}
            </Typography>
            <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
              {client.email}
            </Typography>
            <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
              {client.phone_number}
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item md={5}>
        <Stack>
          {client.created_vendor_id !== null && (
            <PlusCircleOutlined
              style={{
                fontSize: 16,
                color: "#01522f",
                alignSelf: "flex-end",
              }}
              onClick={(e) => {
                e.stopPropagation();
                console.log("PLUS CLICKED");
                props.setAddServiceAddressModal(client);
              }}
            />
          )}
          {properties.map((property, index) => (
            <Typography
              key={property.property_id}
              value={index}
              sx={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {property.address}
            </Typography>
          ))}
        </Stack>
      </Grid>

      <Grid item md={3}>
        <Stack style={{ padding: "0.75em", textAlign: "center" }}>
          {/* {client.verified ? ( */}
          {client.is_active === 1 && client.consent_confirm === 1 ? (
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "0.25rem 0.5rem",
                  width: "100%",
                  margin: "0 0 0.5em",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.openJobModal(props.client);
                }}
              >
                Create Job
              </Button>
              {client.created_vendor_id !== null && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: "0.25rem 0.5rem", width: "100%" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.openEditModal(props.client);
                  }}
                >
                  Edit Client
                </Button>
              )}
              {client.created_vendor_id !== null && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: "0.25rem 0.5rem",
                    width: "100%",
                    marginBottom: "0.5em",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.openDeactivateModal(client);
                  }}
                >
                  Deactivate
                </Button>
              )}
            </Stack>
          ) : (client.consent_confirm === 0 ||
              client.consent_confirm === null) &&
            (client.is_active === 1 || client.is_active === null) ? (
            <>
              <Typography variant="p" textAlign={"center"} color="#debd02">
                Pending
              </Typography>
              <Button
                disabled={count > 0}
                variant="contained"
                color="primary"
                sx={{
                  padding: "0.25rem 0.5rem",
                  width: "100%",
                  "&.Mui-disabled": { color: "#01522f" },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleResendConsent(client);
                  setCount(20); // Reset countdown
                  setIsCounting(true);
                }}
              >
                {isCounting ? `Wait ${count}s` : "Resend Link"}
              </Button>
            </>
          ) : (
            <Typography variant="p" color="error">
              Deactivated
            </Typography>
          )}

          {/* ) : (
        <Button
          variant="contained"
          color="primary"
          sx={{ padding: "0.25rem 0.5rem", width: "100%" }}
        >
          Verify Client
        </Button>
      )} */}
        </Stack>
      </Grid>
    </Grid>
  );
}

const ClientListDisplay = (props) => {
  const [addClientModal, setAddClientModal] = useState(false);
  const [updateProperties, setUpdateProperties] = useState(false);

  const [client, setClient] = useState({});
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [clientStreetAddress, setClientStreetAddress] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientProvince, setClientProvince] = useState("");
  const [clientPostalCode, setClientPostalCode] = useState("");
  const [clientProfilePic, setClientProfilePic] = useState(null);

  const [clientDetailsModal, setClientDetailsModal] = useState(false);
  const [changeClientProfilePicModal, setChangeClientProfilePicModal] =
    useState(false);
  const [clientsJobModal, setClientsJobModal] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);

  const [deactivateClientModal, setDeactivateClientModal] = useState(false);
  const [clientIndex, setClientIndex] = useState(null);
  const [lastDay, setLastDay] = useState();
  const [deactivationReason, setDeactivationReason] = useState("");
  const [addServiceAddressModal, setAddServiceAddressModal] = useState(false);

  const openDeactivateModal = (client) => {
    setClient(client);
    setDeactivateClientModal(true);
  };

  const confirmDeactivation = async () => {
    // const isValid = handleErrors();

    // if (!isValid) {
    //   console.log("Form has errors, please fix them.");
    //   return;
    // }

    await axios
      .post("/deactivateClient", {
        UserId: client.id,
      })
      .then((response) => {
        props.fetchClientList();
        setDeactivateClientModal(false);
        setLastDay("");
        setDeactivationReason("");
      });
  };

  const openEditModal = (client) => {
    setEditClientModal(true);
    setClient(client);
    setClientFirstName(client.first_name);
    setClientLastName(client.last_name);
    setClientEmail(client.email);
    setClientPhoneNumber(client.phone_number);
    setClientStreetAddress(client.street);
    setClientCity(client.city);
    setClientProvince(client.province);
    setClientPostalCode(client.postal_code);
    setClientProfilePic(client.profile_pic);
  };

  const openAddServiceAddressModal = (client) => {
    setAddServiceAddressModal(true);
    setClient(client);
    setClientFirstName(client.first_name);
    setClientLastName(client.last_name);
    setClientEmail(client.email);
    setClientPhoneNumber(client.phone_number);
    setClientStreetAddress(client.street);
    setClientCity(client.city);
    setClientProvince(client.province);
    setClientPostalCode(client.postal_code);
    setClientProfilePic(client.profile_pic);
  };

  const openJobModal = (client) => {
    setClientsJobModal(true);
    setClient(client);
    setClientFirstName(client.first_name);
    setClientLastName(client.last_name);
    setClientEmail(client.email);
    setClientPhoneNumber(client.phone_number);
    setClientStreetAddress(client.street);
    setClientCity(client.city);
    setClientProvince(client.province);
    setClientPostalCode(client.postal_code);
    setClientProfilePic(client.profile_pic);
  };

  const handleClientClick = (client) => {
    setClient(client);
    setClientFirstName(client.first_name);
    setClientLastName(client.last_name);
    setClientEmail(client.email);
    setClientPhoneNumber(client.phone_number);
    setClientStreetAddress(client.street);
    setClientCity(client.city);
    setClientProvince(client.province);
    setClientPostalCode(client.postal_code);
    setClientProfilePic(client.profile_pic);
    setClientDetailsModal(true);
  };

  const mobileView = useMediaQuery("(max-width: 623px)");

  // useEffect(() => {
  const provinceList = GetProvinceList();
  console.log(GetProvinceList(), "GetProvinceList()dafff");
  // }, []);

  const handleResendConsent = async (client) => {
    console.log("handleResendConsent", client);
    await axios.post("/resendConsentToClient", {
      host: window.location.host,
      userId: client.id,
      email: client.email,
    });
  };

  const options = ["Active Clients", "Inactive Clients"];

  const [submitAlert, setSubmitAlert] = useState(false);
  const [submitUpdateAlert, setSubmitUpdateAlert] = useState(false);

  return props.clients ? (
    <>
      <Stack spacing={2} width={mobileView ? "100%" : "60vw"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            justifyContent={"start"}
            style={{ width: "50%", padding: "0.25rem", marginBottom: "1em" }}
          >
            <Select
              sx={{
                width: "50%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              multiple
              value={props.selectedFilters}
              onChange={(e) => props.handleChange(e.target.value)}
              renderValue={(selected) => "Status"}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={props.selectedFilters.includes(option)} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"end"}
            style={{ width: "50%", padding: "0.25rem", marginBottom: "1em" }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setAddClientModal(true);
              }}
              style={{ width: "50%", padding: "0.25rem" }}
            >
              Add Client
            </Button>
          </Stack>
        </Stack>

        {props.clients.length > 0 ? (
          <Stack
            style={{
              overflowY: "scroll",
              width: "100%",
              height: "calc(100% - 9.5em)",
              margin: 0,
            }}
          >
            <Grid
              container
              spacing={2}
              position={"fixed"}
              width="inherit"
              maxWidth={"60vw"}
              left={0}
              zIndex={1000}
              backgroundColor={"rgba(255,255,255,0.75)"}
              boxShadow="0px 10px 20px rgba(255, 255, 255, 0.75)"
              overflow={"hidden"}
            >
              <Grid item md={4}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography variant="h5">Client Info</Typography>
                </Stack>
              </Grid>

              <Grid item md={5}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography variant="h5">Service Address</Typography>
                </Stack>
              </Grid>

              <Grid item md={3}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography variant="h5">Actions</Typography>
                </Stack>
              </Grid>
            </Grid>
            {props.clients?.map((client) => (
              <TableRow
                client={client}
                openEditModal={openEditModal}
                openJobModal={openJobModal}
                handleClientClick={handleClientClick}
                handleResendConsent={handleResendConsent}
                openDeactivateModal={openDeactivateModal}
                addServiceAddressModal={addServiceAddressModal}
                setAddServiceAddressModal={openAddServiceAddressModal}
                updateProperties={updateProperties}
              />
            ))}
          </Stack>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Typography variant="h4">
              <i>No clients found.</i>
            </Typography>
          </Stack>
        )}
      </Stack>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Add Service Address"
        open={addServiceAddressModal}
        onCancel={() => {
          setAddServiceAddressModal(false);
        }}
        footer={false}
      >
        <AddClientServiceAddress
          clientId={client.id}
          onClose={() => {
            setAddServiceAddressModal(false);
            setClient({});
          }}
          fetchData={props.fetchClientList}
          updateProperties={() => setUpdateProperties(!updateProperties)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Deactivate Client"
        open={deactivateClientModal}
        onCancel={() => {
          setDeactivateClientModal(false);
          setClientFirstName("");
          setClientLastName("");
          setClientEmail("");
          setClientPhoneNumber("");
          setClientStreetAddress("");
          setClientCity("");
          setClientProvince("");
          setClientPostalCode("");
          setClientProfilePic(null);
          setClient({});
        }}
        footer={false}
      >
        <DeactivateClientModal
          fetchClientList={props.fetchClientList}
          setLastDay={setLastDay}
          lastDay={lastDay}
          deactivationReason={deactivationReason}
          setDeactivationReason={setDeactivationReason}
          client={client}
          confirmDeactivation={confirmDeactivation}
          setDeactivateClientModal={setDeactivateClientModal}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="500px"
        centered={true}
        title="Client Details"
        open={addClientModal}
        onCancel={() => {
          setAddClientModal(false);
          setClientCity("");
          setClientFirstName("");
          setClientLastName("");
          setClientEmail("");
          setClientPhoneNumber("");
          setClientStreetAddress("");
          setClientCity("");
          setClientProvince("");
          setClientPostalCode("");
          setClientProfilePic(null);
        }}
        footer={false}
      >
        {/* <Typography>TESTING</Typography> */}
        <AddClientDetails
          provinces={GetProvinceList()}
          onClose={() => setAddClientModal(false)}
          fetchClientList={props.fetchClientList}
          setSubmitAlert={setSubmitAlert}
        />
      </Modal>

      {submitAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Client has been added successfully.
        </Alert>
      )}
      {submitUpdateAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Client has been updated successfully.
        </Alert>
      )}

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Client Details"
        open={clientDetailsModal}
        onCancel={() => setClientDetailsModal(false)}
        footer={false}
      >
        <Stack spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <Stack width={"25%"}>
              {clientProfilePic?.includes("avatar_default.png") ? (
                <Avatar size={100}>{clientFirstName[0]}</Avatar>
              ) : (
                <Avatar size={100} src={clientProfilePic} />
              )}
            </Stack>

            <Stack width={"75%"}>
              <Typography>
                {clientFirstName} {clientLastName}
              </Typography>
              <Typography>{clientEmail}</Typography>
              <Typography>{clientStreetAddress}</Typography>
              <Typography>
                {clientCity}, {clientProvince}
              </Typography>
              <Typography>{clientPostalCode}</Typography>
            </Stack>
          </Stack>

          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setClientsJobModal(true)}
            >
              Create Job
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditClientModal(true)}
            >
              Edit Client
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        destroyOnClose
        width="700px"
        title="Client needs help with..."
        open={clientsJobModal}
        onCancel={() => setClientsJobModal(false)}
        footer={false}
      >
        <AddClientJob client={client} setClientsJobModal={setClientsJobModal} />
        {/* <Typography>Clients Job Modal</Typography> */}
      </Modal>

      <Modal
        destroyOnClose
        width="700px"
        title="Edit Client Details"
        open={editClientModal}
        onCancel={() => setEditClientModal(false)}
        footer={false}
      >
        {console.log(client)}
        {/* <Typography>Edit Client Modal</Typography> */}
        <EditClient
          setSubmitAlert={setSubmitUpdateAlert}
          fetchClientList={props.fetchClientList}
          client={client}
          formData={{
            firstName: clientFirstName || "",
            lastName: clientLastName || "",
            email: clientEmail || "",
            phone: clientPhoneNumber || "",
            address: clientStreetAddress || "",
            city: clientCity || "",
            province: clientProvince || "",
            postalCode: clientPostalCode || "",
          }}
          setEditClientModal={setEditClientModal}
        />
      </Modal>

      <Modal
        destroyOnClose
        width="500px"
        title="Change Profile Picture"
        open={changeClientProfilePicModal}
        onCancel={() => setChangeClientProfilePicModal(false)}
        footer={false}
      >
        <ChangeProfilePicture
          user={client}
          closeModal={() => setChangeClientProfilePicModal(false)}
        />
      </Modal>
    </>
  ) : (
    <LoadScreen />
  );
};

export default ClientListDisplay;
