import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import addButton from "../../../../images/plus.png";
import { Modal } from "antd";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

import moment from "moment-timezone";
moment.tz.setDefault("Canada/Newfoundland");

function CreateInvoice(props) {
  const invoiceDate = new Date();
  const { user } = useUserState();
  const [desc, setDesc] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [invoiceRow, setInvoiceRow] = useState([]);
  const [notes, setNotes] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date();
    const newInvoiceItem = [
      ...invoiceRow,
      {
        userID: user.id,
        date: `${date.getFullYear()}-${
          (date.getMonth() < 10 ? "0" : "") + (parseInt(date.getMonth()) + 1)
        }-${(date.getDate() < 10 ? "0" : "") + date.getDate()}`,
        desc: desc,
        quantity: quantity,
        rate: rate,
        amount: rate * quantity,
        ID: "ROW",
      },
    ];
    setInvoiceRow(newInvoiceItem);
    calculateSubtotal();
    setShowTextbox(false);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    for (const element of invoiceRow) {
      subtotal += element.amount;
    }
    return subtotal;
  };

  let subtotal = calculateSubtotal();

  const updateJobStatus = () => {
    const formObject = {
      JobId: props.jrData.job_id,
      activityDesc: `Created invoice for $${parseFloat(subtotal * 1.15).toFixed(
        2
      )}`,
      userName: `${props.vendorInvoiceDetails[0].vendor_legal_name}`,
      vendorId: props.jrData.assigned_vendor_id,
    };

    axios.post("/jobRequest/updateToFinalizing", formObject).then((res) => {
      props.fetchData();
    });
  };

  const handleInvoiceSubmit = () => {
    const body = {
      jobId: props.jrData.job_id,
    };

    axios.post("/invoice/newInvoiceLine", body).then((response) => {
      if (response.data.ok) {
        updateJobStatus();
      }
    });
  };

  return (
    <div>
      <Typography>INVOICE</Typography>
      <div className="invoiceDiv">
        <Typography>
          <Typography>TEST</Typography>
          {props.vendorInvoiceDetails[0].vendor_legal_name}
        </Typography>
        <Typography>
          {props.vendorInvoiceDetails[0].street}{" "}
          {props.vendorInvoiceDetails[0].postal_code}
        </Typography>
        <Typography>
          {props.vendorInvoiceDetails[0].city},{" "}
          {props.vendorInvoiceDetails[0].province} Canada
        </Typography>
        <Typography>{props.vendorInvoiceDetails[0].phone_number}</Typography>
      </div>
      <Typography style={{ marginTop: "1rem" }}>
        <b>Bill To:</b>
      </Typography>
      <div className="invoiceDiv">
        <Typography>
          {props.clientInvoiceDetails[0].first_name}{" "}
          {props.clientInvoiceDetails[0].last_name}
        </Typography>
        <Typography>
          {props.clientInvoiceDetails[0].street}{" "}
          {props.clientInvoiceDetails[0].postal_code}
        </Typography>
        <Typography>
          {props.clientInvoiceDetails[0].city},{" "}
          {props.clientInvoiceDetails[0].province} Canada
        </Typography>
        <Typography>{props.clientInvoiceDetails[0].phone_number}</Typography>
      </div>
      <div className="invoiceDiv">
        <Typography style={{ marginTop: "1rem" }}>
          <b>Invoice Date:</b> {moment(invoiceDate).format("MMM Do YYYY")}
        </Typography>
      </div>
      <div className="invoiceDiv">
        <div>
          <br></br>
          <div className="buttonContainer">
            <label className="categoryLabel" htmlFor="addButton">
              Invoice Data
            </label>
            <button
              className="addButtonContainer"
              onClick={() => setShowTextbox(true)}
            >
              <img
                id="addButton"
                src={addButton}
                alt="addButton"
                className="addButton"
              />
            </button>
          </div>
          <div>
            <Modal
              destroyOnClose={true}
              width="700px"
              title="Add Invoice Line"
              open={showTextbox}
              onCancel={() => setShowTextbox(false)}
              footer={false}
            >
              {showTextbox && (
                <form className="phase">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Description</InputLabel>
                        <OutlinedInput
                          required
                          id="desc"
                          type="desc"
                          onChange={(e) => setDesc(e.target.value)}
                          placeholder="Description"
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Quantity</InputLabel>
                        <OutlinedInput
                          required
                          id="quantity"
                          type="number"
                          onChange={(e) => setQuantity(e.target.value)}
                          placeholder="Quantity"
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Rate</InputLabel>
                        <OutlinedInput
                          required
                          id="rate"
                          type="number"
                          onChange={(e) => setRate(e.target.value)}
                          placeholder="Rate"
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <AnimateButton>
                        <Button
                          size="sm"
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          sx={{ ml: 2, float: "right" }}
                        >
                          Submit
                        </Button>
                        <Button
                          size="sm"
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() => setShowTextbox(false)}
                          sx={{ ml: 2, float: "right" }}
                        >
                          Cancel
                        </Button>
                      </AnimateButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Modal>

            <table className="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoiceRow.map((item, i) => (
                  <tr key={item.desc + item.ID}>
                    <td>{item.desc}</td>
                    <td>{item.quantity}</td>
                    <td>${item.rate}</td>
                    <td>${item.amount.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="invoiceDiv">
        <p>Sub Total: ${parseFloat(subtotal).toFixed(2)}</p>
        <p>Sales Tax(15%): ${parseFloat(subtotal * 0.15).toFixed(2)}</p>
        <p>TOTAL: ${parseFloat(subtotal * 1.15).toFixed(2)}</p>
      </div>
      <Stack marginbottom={"1rem"} spacing={1}>
        <InputLabel>Extra Notes</InputLabel>
        <OutlinedInput
          required
          id="notes"
          name="notes"
          onChange={(e) => setNotes(e.target.value)}
          placeholder={"Extra notes"}
        />
      </Stack>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <AnimateButton>
            <Button
              onClick={() => {
                props.onClose(false);
                handleInvoiceSubmit();
              }}
              size="sm"
              type="button"
              variant="contained"
              color="primary"
              sx={{ ml: 2, float: "right" }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                props.onClose(false);
              }}
              size="sm"
              type="button"
              variant="outlined"
              color="primary"
              sx={{ ml: 2, float: "right" }}
            >
              Cancel
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </div>
  );
}

export default CreateInvoice;
