import PropTypes from "prop-types";

// material-ui
import {
  Alert,
  InputLabel,
  Link,
  List,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import NavItem from "./NavItem";
import { useUserState } from "../../../../../Context/UserContext";
import { useCallback, useState } from "react";
import { Modal } from "antd";
import ReferralModal from "../../../../../Pages/commonComponents/Referrral/ReferralModal";
import axios from "axios";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item, setOpen, handleVendorMode }) => {
  const { user } = useUserState();
  const matches = useMediaQuery("(min-width: 560px)");
  const matchesXs = useMediaQuery("(max-width: 900px)");

  const [referralModal, setReferrralmodal] = useState(false);
  const [JRs, setJRs] = useState(false);
  const [referAlert, setReferAlert] = useState(false);

  const getJRData = useCallback(() => {
    axios.get("/jobRequest/getClientJRs").then((res) => {
      console.log(res.data);
      setJRs(res.data);
      window.JRList = res.data;
    });
  }, []);

  const displayReferSuccess = () => {
    getJRData();
    setReferAlert(true);
    setTimeout(() => setReferAlert(false), 4000);
  };

  const navCollapse = item.children
    ?.filter((menuItem) => {
      return !(menuItem.id === "bidding" && !user.vendorMode);
    })
    .map((menuItem) => {
      switch (menuItem.type) {
        case "collapse":
          return (
            <Typography
              key={menuItem.id}
              variant="caption"
              color="error"
              sx={{ p: 2.5 }}
            >
              collapse - only available in paid version
            </Typography>
          );
        case "item":
          return (
            <NavItem
              setOpen={setOpen}
              key={menuItem.id}
              item={menuItem}
              level={1}
            />
          );
        default:
          return (
            <Typography
              key={menuItem.id}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Group Collapse or Items
            </Typography>
          );
      }
    });

  return (
    <>
      <List sx={{ py: 0, zIndex: 0 }} style={{ width: "100%" }}>
        <Stack direction={matches ? "row" : "column"}>
          {navCollapse}
          {matchesXs && (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Link
                // component="button" // Makes it behave like a button
                variant="body2"
                onClick={() => setReferrralmodal(true)}
                sx={{
                  cursor: "pointer",
                  // backgroundColor: "red",
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
              >
                <Typography color="#FFFFFF" variant="h6" fontWeight="400">
                  Refer Friend
                </Typography>
              </Link>
            </Stack>
          )}
          {matchesXs && (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              marginLeft={matches ? "0.5rem" : ""}
            >
              <InputLabel
                style={{
                  cursor: "pointer",
                  marginTop: "0.5rem",
                  color: "#fff",
                }}
                htmlFor="vendorMode"
              >
                {!matchesXs
                  ? user.vendorMode
                    ? "Client Mode"
                    : user.empRole === "Admin" ||
                      user.empRole === "Manager" ||
                      user.is_vendor !== 1
                    ? "Vendor Mode"
                    : "Employee Mode"
                  : user.vendorMode
                  ? "Client"
                  : user.empRole === "Admin" ||
                    user.empRole === "Manager" ||
                    user.is_vendor !== 1
                  ? "Vendor"
                  : "Employee"}
              </InputLabel>
              <Switch
                id="vendorMode"
                color="primary"
                checked={user.vendorMode}
                onChange={() => {
                  handleVendorMode();
                  setOpen(false);
                }}
              />
            </Stack>
          )}

          {/* <ListItemButton
      disabled={item.disabled}
      onClick={() => {
        handleVendorMode();
      }}
    >
      <ListItemIcon style={{ color: "#fcf2e7" }}>
        {ToolOutlined}
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant="h6">Vendor Mode</Typography>}
      />
    </ListItemButton> */}
        </Stack>
      </List>
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Refer a friend"
        open={referralModal}
        onCancel={() => setReferrralmodal(false)}
        footer={false}
        centered
      >
        {referralModal && (
          <ReferralModal
            onCancel={() => setReferrralmodal(false)}
            displayReferSuccess={displayReferSuccess}
          />
        )}
      </Modal>
      {referAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Link has been sent. Thank you for referring.
        </Alert>
      )}
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
