import { createContext, useReducer, useContext, useState, useMemo } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function vendorMode(user) {
  const sessionJSON = Cookies.get("session");
  const session = JSON.parse(sessionJSON);

  user.vendorMode = session.type !== "Client";
  return user;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "initialize": {
      Cookies.set("user", JSON.stringify(action.state.user), { expires: 1 });
      const user = vendorMode(action.state.user);
      return { user: {...user} };
    }
    case "update": {
      Cookies.set("user", action.state.user, { expires: 1 });
      const user = vendorMode(action.state.user);
      return { user: {...user} };
    }
    case "sync": {
      let user = Cookies.get("user");
      if (user !== undefined) return { user: JSON.parse(user) };
      else return { user: undefined };
    }
    case "swapMode": {
      const vendorMode = !state.user.vendorMode;
      state.user.vendorMode = vendorMode;
      Cookies.set("user", JSON.stringify(state.user), { expires: 1 });
      const sessionJSON = Cookies.get("session");
      const session = JSON.parse(sessionJSON);
      session.type = vendorMode ? "Vendor" : "Client";
      Cookies.set("session", JSON.stringify(session));
      axios.post("/vendorMode/changeVendorMode", {
        vendorMode: state.user.vendorMode,
        user: state.user,
      });
      return { user: {...state.user} };
    }
    case "updateIsMarket": {
      // Update the `isMarket` value without mutating the rest of the state
      return {
        ...state,
        user: {
          ...state.user,
          isMarket: action.isMarket, // Update only isMarket
        },
      };
    }
    case "logout": {
      Cookies.remove("session");
      return { user: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a userProvider");
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a userProvider");
  }
  return context;
};

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  UserStateContext,
  UserDispatchContext,
};
