import { useCallback, useEffect, useState } from "react";

import "../commonComponents/CSS/styles.css";

import axios from "axios";
import JobDisplay from "./InboxComponents/JobDisplay";

import { useUserState } from "../../Context/UserContext";

import Messenger from "./InboxComponents/Messenger";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "antd";

import "./Inbox.css";
import ChatList from "./InboxComponents/ChatList";

import { useMediaQuery } from "@mui/material";
import { useChatContext } from "../../Context/ChatContext";
import { useJRContext } from "../../Context/JobRequestContext";

const { Sider, Content } = Layout;
const InboxPage = () => {
  let locationSplit = window.location.href.split("/");
  let selected_chat = locationSplit.length > 5 ? locationSplit[5] : null;
  const open_job = locationSplit.length > 6 ? locationSplit[6] : null;

  const { user } = useUserState();
  const {socket, onlineUsers, sendNewMessage:sendMessageOverSocket, updateChatToRead} = useChatContext()

  const [chatting, setChatting] = useState(false);
  const [jobView, setJobView] = useState(false);

  const [chatList, setChatList] = useState(null);
  const [chatID, setChatID] = useState(null);
  // list of jobs for current chat
  const [jrList, setJrList] = useState(null)
  // id of currently open job
  const [openJob, setOpenJob] = useState(open_job)
  const [jrConversation, setJrConversation] = useState(null)
  const {openJR, setOpenJR} = useJRContext();

  const navigate = useNavigate();


  const [currentConvo, setCurrentConvo] = useState(null)
  const [messages, setMessages] = useState([]);

  const [notifications, setNotifications] = useState([]);

  const getChatList = useCallback( () => {
    axios.get("/communication/getChatList").then((response) => {
      // console.log(response.data.chatList);
      setChatList(response.data.chatList);
    });
  }, [setChatList]);

  useEffect(() => {
    setChatID(locationSplit.length > 5 ? locationSplit[5] : null);
    // console.log(locationSplit);
    if (!window.location.href.includes(chatID)) {
      setChatID(selected_chat);
    }
  }, [selected_chat]);

  useEffect(() => {
    getChatList();
  }, []);

  /*
  useEffect(() => {
    axios
      .post("/communication/getUnreadMessage", {
        // receiverId: receipientId
      })
      .then((res) => {
        console.log(res.data.unreadMsgList);
        setUnreadMsg(res.data.unreadMsgList);
        setNotifications(res.data.unreadMsgList);
      });
  }, [newMessage]);
  */

  const sendNewMessage = async (newMessage)=>{
    console.log("send message ")
    await axios
      .post("/communication/sendMessage", {
        ConversationId: chatID,
        JrConversationId: jrConversation.jr_conversation_id,
        Message: newMessage,
        host: window.location.host
      })
      .then((response) => {
        if (
          response.data.newMessage.sender_user_id === user.id &&
          response.data.newMessage.conversation_id === parseInt(chatID)
        ) {
          setMessages((prev) => [...prev, response.data.newMessage]);
        }
      })
      .catch((err) => console.error(err));

    sendMessageOverSocket(newMessage)

    getChatList();
  }

  /*
  //get web socket connection
  useEffect(() => {
    const newSocket = io("http://localhost:5000");
    console.log(newSocket);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  // add online users
  useEffect(() => {
    if (socket === null) return;

    socket.emit("addNewUser", user.id);
    socket.on("getOnlineUsers", (res) => {
      setOnlineUsers(res);
    });

    return () => {
      socket.off("getOnlineUsers");
    };
  }, [socket]);

  //send message
  useEffect(() => {
    if (socket === null) return;
    console.log(newMessage);
    const receipientDetails = chatList?.filter(
      (list) => list.conversation_id === newMessage.conversation_id
    );

    const receipientUserDetails = receipientDetails.map((list) =>
      list.chattingPeople.find((people) => people.user_id !== user.id)
    );

    const receipientId = receipientUserDetails[0].user_id;

    console.log(receipientId);
    socket.emit("sendMessage", {
      ...newMessage,
      receipientId,
      receipientUserDetails,
      receipientDetails
    });
  }, [newMessage]);
  */

  const getConversation = useCallback(()=>{
    if (chatID == null){
      return;
    }
    /*
    axios.get(`/communication/getMessage/${chatID}`).then((res) => {
      updateChatToRead(chatID)
      getChatList();
      // console.log(res.data.messages);
      setMessages(res.data.messages);
      setCurrentConvo(res.data.conversation);
      console.log(res.data.conversation)
      // console.log(res.data.messages);
    });
    */

    const jobID = open_job != null? open_job: openJR != null? openJR.job_id: null;
    console.log("open job", open_job, jobID)
    axios.post(`/communication/conversation/${chatID}`, {jobId: jobID}).then((results)=>{
      updateChatToRead(chatID)
      setMessages(results.data.messages);
      setCurrentConvo(results.data.conversation);
      setJrConversation(results.data.jrConversation);
      console.log("jrConversation", results.data.jrConversation)
      if (openJR === null || results.data.jrConversation.job_id !== openJR.job_id){
        setOpenJR(results.data.jrConversation)
      }
    })
  }, [chatID, openJR, setOpenJR, getChatList, setMessages, setCurrentConvo]);

  useEffect(()=>{
    getConversation()
  }, [getConversation])

  // receive message
  useEffect(() => {
    if (socket === null) return;

    socket.on("getMessage", (res) => {

      if (parseInt(chatID) === parseInt(res.conversation_id)) {
        console.log(true)
        setMessages((prev) => [...prev, res]);
        updateChatToRead(chatID)
      }

      getChatList();
      getConversation();
    });
    /*
    socket.on("getNotification", (res) => {
      console.log(res);

      if (res.is_read === 0) setNotifications((prev) => [res, ...prev]);
    });
    */
    return () => {
      socket.off("getMessage");
      // socket.off("getNotification");
    };
  }, [socket, chatList, messages]);

  /*
  const updateMsgToRead = (msgId) => {
    axios
      .post("/communication/updateMsgToReadInMessenger", {
        MsgId: msgId
      })
  };
  */

  const mobileView = useMediaQuery("(max-width: 600px)");

  return !mobileView ? (
    <Layout className="commmunicationLayout communicationDesktop">
      <Sider width={300} id="commSiderLeft" style={{ overflow: "auto" }}>
        <ChatList
          socket={socket}
          setChatID={setChatID}
          chatList={chatList}
          getChatList={getChatList}
          chatId={chatID}
          onlineUsers={onlineUsers}
          notifications={notifications}
        />
      </Sider>
      <Content id="communicationContent">
        {selected_chat !== null ? (
          <Messenger
            setJobView={setJobView}
            socket={socket}
            chatId={selected_chat}
            getChatList={getChatList}
            messages={messages}
            setMessages={setMessages}
            getConversation={getConversation}
            jrConversation={jrConversation}
            conversation={currentConvo}
          />
        ) : (
          <NoMessage />
        )}
      </Content>
      <Content id="allJobRequest">
        <JobDisplay fetchJrData={getConversation} setJobView={setJobView} chatId={selected_chat} jrList={jrList} openJob={openJob} setOpenJob={setOpenJob} />
      </Content>
    </Layout>
  ) : (
    <>
      {selected_chat !== null ? (
        !jobView ? (
          <Messenger
            setJobView={setJobView}
            setChatting={setChatting}
            className="mr-2 m-auto"
            chatId={selected_chat}
            getChatList={getChatList}
            messages={messages}
            setMessages={setMessages}
            sendNewMessage={sendNewMessage}
            getConversation={getConversation}
            conversation={currentConvo}
          />
        ) : (
          <JobDisplay fetchJrData={getConversation} setJobView={setJobView} chatId={selected_chat} jrList={jrList} openJr={openJob} setOpenJob={setOpenJob} />
        )
      ) : (
        !chatting && (
          <ChatList
            setChatting={setChatting}
            setChatID={setChatID}
            chatList={chatList}
            getChatList={getChatList}
            chatId={chatID}
            onlineUsers={onlineUsers}
            notifications={notifications}
          />
        )
      )}
    </>
  );
};
/* Displays when no thread is specified in the main body. Only used in desktop view */
const NoMessage = () => {
  return (
    <div className="noSelectedImg text-center">
      <img
        src={require("../../images/emptyThread.png")}
        alt={"noMessageSelected"}
      />
      <div className="g-text-lg">
        Select a thread to the left to open the messenger.
      </div>
    </div>
  );
};

export default InboxPage;
