import styles from "./FullTransaction.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { Grid, Stack, Typography } from "@mui/material";
import { useUserState } from "../../../Context/UserContext";
moment.tz.setDefault("Canada/Newfoundland");

const formatDate = (d) => `${moment(d).format("MMM Do YYYY, hh:mm a")}`;

const FullTransaction = (props) => {
  const { user } = useUserState();
  const [financeItems, setFinanceItems] = useState([]);
  const [financeBid, setFinanceBid] = useState([]);

  const transaction = props.tr;
  console.log(props.tr, "UHFVYISTGVFYUGDB");
  console.log(transaction);

  const fetchFinanceItems = useCallback(() => {
    axios
      .post("/finance/getFinanceItems", {
        invId: user.vendorMode
          ? transaction.vendor_invoice_id
          : transaction.invoice_id,
      })
      .then((res) => {
        console.log(res.data.invoiceItems);
        const invoiceItems = res.data.invoiceItems;
        setFinanceItems(invoiceItems);
      });
  }, [transaction.invoice_id]);

  const fetchBidDetails = useCallback(() => {
    axios
      .post("/finance/getFinanceBidDetails", { jobId: transaction.job_id })
      .then((res) => {
        const financeBid = res.data.financeBid;
        setFinanceBid(financeBid);
      });
  }, [transaction.job_id]);

  useEffect(() => {
    fetchFinanceItems();
    fetchBidDetails();
  }, [fetchBidDetails, fetchFinanceItems]);

  return (
    <>
      <Grid container width={"100%"}>
        <Stack direction={"row"} width={"100%"}>
          <Grid item xs={12} border={"1px solid #dddddd"} padding={"1rem"}>
            <Typography variant="h4">Address</Typography>
            <Typography variant="h5">
              {transaction.street}, {transaction.city}
            </Typography>
          </Grid>
        </Stack>
        <Stack direction={"row"} width={"100%"}>
          <Grid item xs={6} border={"1px solid #dddddd"} padding={"1rem"}>
            <Typography variant="h4">Start Date</Typography>
            <Typography variant="h5">
              {formatDate(
                financeBid.length > 0
                  ? financeBid[0].bid_accepted_date
                  : transaction.date_started
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} border={"1px solid #dddddd"} padding={"1rem"}>
            <Typography variant="h4">End Date</Typography>
            <Typography variant="h5">
              {formatDate(transaction.date_finished)}
            </Typography>
          </Grid>
        </Stack>
        <Grid
          item
          xs={12}
          width={"100%"}
          border={"1px solid #dddddd"}
          padding={"1rem"}
        >
          <Typography variant="h5">Job Description</Typography>
          <Typography variant="p">{transaction.job_description}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          width={"100%"}
          border={"1px solid #dddddd"}
          padding={"1rem"}
        >
          <Typography variant="h5">Vendor Notes</Typography>
          <Typography variant="p">{transaction.invoice_notes}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          width={"100%"}
          border={"1px solid #dddddd"}
          padding={"1rem"}
        >
          <Typography variant="h5">Cost Breakdown</Typography>
        </Grid>
        <Stack direction="column" width="100%">
          {/* Column Headings */}
          <Stack
            direction="row"
            width="100%"
            border="1px solid #dddddd"
            padding="1rem"
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={600}>
                Description
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1" fontWeight={600}>
                Rate
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <Typography variant="body1" fontWeight={600}>
                Quantity/Hours
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Typography variant="body1" fontWeight={600}>
                Amount
              </Typography>
            </Grid>
          </Stack>

          {/* Data Rows */}
          {financeItems?.map((item, i) => (
            <Stack
              key={i}
              direction="row"
              width="100%"
              border="1px solid #dddddd"
              padding="1rem"
            >
              <Grid item xs={4}>
                <Typography fontWeight={600} variant="p">
                  <em>
                    {!user.vendorMode
                      ? item.invoice_description === "Collected Tax"
                        ? "HST"
                        : item.invoice_description
                      : item.description === "Collected Tax"
                      ? "HST Collected"
                      : item.description === "Service Fee"
                      ? "Service Fee(with tax)"
                      : item.description}
                  </em>

                  {item.work_description && (
                    <p style={{ margin: 0 }}>({item.work_description})</p>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="p">
                  $
                  {user.vendorMode
                    ? item.rate.toFixed(2)
                    : item.invoice_rate.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="center">
                <Typography variant="p">
                  {user.vendorMode ? item.quantity : item.invoice_quantity}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="p">
                  $
                  {user.vendorMode
                    ? item.description === "Service Fee"
                      ? (item.amount + item.tax).toFixed(2)
                      : item.amount.toFixed(2)
                    : item.invoice_amount.toFixed(2)}
                </Typography>
              </Grid>
            </Stack>
          ))}
        </Stack>

        {financeItems.length > 0 && (
          <>
            <Stack
              direction={"row"}
              width={"100%"}
              border={"1px solid #dddddd"}
              padding={"1rem"}
              borderTop={"none"}
            >
              <Grid item xs={12}>
                <Typography textAlign={"right"} alignContent={"flex-end"}>
                  <span style={{ fontWeight: "bold" }}>
                    {user.vendorMode ? "Total Payout:" : "Total Pay:"}
                  </span>{" "}
                  $
                  {parseFloat(
                    user.vendorMode
                      ? transaction.total_payout
                      : transaction.total_amount
                  ).toFixed(2)}
                </Typography>
              </Grid>
              {/* <Grid item xs={6} textAlign={"right"}>
                  <Typography variant="p">
                    ${parseFloat(transaction.total_amount).toFixed(2)}
                  </Typography>
                </Grid> */}
            </Stack>
          </>
        )}
      </Grid>
    </>
  );
};

export default FullTransaction;
