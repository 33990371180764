import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import DatePicker from "../../../Components/FormComponents/DatePicker";

const DeactivateEmployeeModal = (props) => {
  console.log(props);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="h5">
            Are you sure you want to deactivate{" "}
            <strong>
              <i>
                {props.employeeList[props.currentEmployee]?.first_name}{" "}
                {props.employeeList[props.currentEmployee]?.last_name}
              </i>
            </strong>
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={6}>
        <Stack spacing={1}>
          <InputLabel>Reason for deactivation:</InputLabel>
          <OutlinedInput
            fullWidth
            type="text"
            onChange={(e) => {
              props.setDeactivationReason(e.target.value);
              if (props.errorMessage.deactivationReason) {
                props.setErrorMessage((prevState) => ({
                  ...prevState,
                  deactivationReason: "",
                }));
              }
            }}
          />
          {props.errorMessage !== "" &&
            props.errorMessage?.deactivationReason && (
              <Typography color="error">
                {props.errorMessage?.deactivationReason}
              </Typography>
            )}
        </Stack>
      </Grid>

      <Grid item md={6}>
        <Stack spacing={1}>
          <InputLabel>Last Day Worked:</InputLabel>
          <DatePicker
            style={{ width: "100%" }}
            required
            type="date"
            value={props.lastDay}
            onChange={(e, dateString) => {
              props.setLastDay(e.target.valueAsDate);
              if (props.errorMessage.lastDay) {
                props.setErrorMessage((prevState) => ({
                  ...prevState,
                  lastDay: "",
                }));
              }
            }}
          />
          {props.errorMessage !== "" && props.errorMessage?.lastDay && (
            <Typography color="error">{props.errorMessage?.lastDay}</Typography>
          )}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack
          spacing={3}
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <AnimateButton>
            <Button
              size="sm"
              variant="outlined"
              color="secondary"
              onClick={() => props.setDeactivateEmployeeModal(false)}
            >
              Cancel
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              color="error"
              variant="contained"
              onClick={props.confirmDeactivation}
            >
              Deactivate
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DeactivateEmployeeModal;
